import React, { useCallback, useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoTriggerMarketFilterDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { FilterTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';

type SettingMarketFilterProps = {
  updateSettingsState: (data: OptionDtoTriggerMarketFilterDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
  marketFilter?: OptionDtoTriggerMarketFilterDto;
};

export const SettingMarketFilter: React.FC<SettingMarketFilterProps> = ({
  updateSettingsState,
  onFormValidityChange,
  marketFilter,
}) => {
  const [i18n] = useI18n();

  const { marketListData: availableMarkets } = useConfigurePromotions(
    marketFilter?.enabled ? PromotionFetchTypeEnum.MARKETS : undefined
  );

  const marketList = useMemo(
    () => availableMarkets?.map((market) => market.name),
    [availableMarkets]
  );

  const filterOptions = useMemo(
    () =>
      Object.values(FilterTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  const isError = useMemo(
    () =>
      marketFilter?.enabled &&
      (!marketFilter.value ||
        !marketFilter.value.marketIds?.length ||
        !marketFilter.value.marketIds),
    [marketFilter]
  );

  const selectedTags = useMemo(
    () =>
      availableMarkets
        ?.filter((market) =>
          marketFilter?.value?.marketIds?.includes(market.id as string)
        )
        ?.map((value) => value.name as string) || [],
    [availableMarkets, marketFilter?.value?.marketIds]
  );

  const handleUpdateTags = useCallback(
    (tags: string[]) =>
      updateSettingsState({
        ...marketFilter,
        value: {
          ...marketFilter?.value,
          marketIds: availableMarkets
            ?.filter((market) => tags.includes(market.name as string))
            .map((value) => value.id) as string[],
        },
      }),
    [marketFilter, availableMarkets]
  );

  useEffect(() => {
    onFormValidityChange(!isError);
  }, [isError]);

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack blockAlign='start' wrap={false} align='space-between'>
          <Box width='65%'>
            <BlockStack gap='100'>
              <InlineStack align='start' gap='200'>
                <Text fontWeight='semibold' as='h2'>
                  {i18n.translate('MarketTitle')}
                </Text>
                <Badge tone={marketFilter?.enabled ? 'success' : 'enabled'}>
                  {i18n.translate(marketFilter?.enabled ? 'On' : 'Off')}
                </Badge>
              </InlineStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('MarketSubtitle', {
                  followingArticle: (
                    <Link>{i18n.translate('followingArticle')}</Link>
                  ),
                })}
              </Text>
            </BlockStack>
          </Box>
          <Button
            onClick={() =>
              updateSettingsState({
                ...marketFilter,
                enabled: !marketFilter?.enabled,
              })
            }
          >
            {i18n.translate(marketFilter?.enabled ? 'TurnOff' : 'TurnOn')}
          </Button>
        </InlineStack>
        <Collapsible id='collapsible' open={!!marketFilter?.enabled}>
          <BlockStack gap='400'>
            <ChoiceList
              title=''
              selected={[marketFilter?.value?.type as string]}
              choices={filterOptions}
              onChange={(value: FilterTypeDtoEnum[]) => {
                updateSettingsState({
                  ...marketFilter,
                  value: {
                    ...marketFilter?.value,
                    type: value[0],
                  },
                });
              }}
            />
            <MultiselectTagCombobox
              id='SelectMarkets'
              tagsInside
              label={i18n.translate('SelectMarkets')}
              suggestions={marketList as string[]}
              placeholder={i18n.translate('SelectMarketsPlaceHolder')}
              requiredIndicator
              labelHidden={false}
              selectedTags={selectedTags}
              withAddFunc={false}
              setSelectedTags={handleUpdateTags}
            />
            <Bleed marginBlockEnd='400' marginInline='400'>
              <Box background='bg-surface-secondary' padding='400'>
                <Text as='p' tone='subdued'>
                  {i18n.translate('FilterIsNotApplied', {
                    readArticle: <Link>{i18n.translate('readArticle')}</Link>,
                  })}
                </Text>
              </Box>
            </Bleed>
          </BlockStack>
        </Collapsible>
      </BlockStack>
    </Card>
  );
};
