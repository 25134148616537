import {
  IntegrationsSettingsDto,
  useGetSettingsV6IntegrationsQuery,
  usePutSettingsV6IntegrationsMutation,
  useGetSettingsV6LookupTimezonesQuery,
  useGetSettingsV6LookupThemesQuery,
  useGetSettingsV6LookupLocalesQuery,
  useGetSettingsV6LookupReleaseVersionsQuery,
  usePostSettingsV6WidgetInstallationSupportRequestMutation,
  InstallationSupportRequestDto,
  useGetSettingsV6WidgetInstallationSupportRequestQuery,
  usePutSettingsV6WidgetInstallationSupportRequestMutation,
  useDeleteSettingsV6WidgetInstallationSupportRequestMutation,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsAnnouncementBarQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsAnnouncementBarMutation,
  AnnouncementBarShopSettingsDto,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsProductBannerQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsProductBannerMutation,
  ProductBannerShopSettingsDto,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsNotificationQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsNotificationMutation,
  NotificationShopSettingsDto,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsPromotionalBadgeQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsPromotionalBadgeMutation,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsPromotionCodeFieldQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsPromotionCodeFieldMutation,
  PromotionCodeFieldShopSettingsDto,
  PromotionalBadgeShopSettingsDto,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsPromotionSummaryMutation,
  PromotionSummaryShopSettingsDto,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsPromotionSummaryQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsOfferRulePopupMutation,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsOfferRulePopupQuery,
  OfferRulePopupShopSettingsDto,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsCountdownClockQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsCountdownClockMutation,
  CountdownClockShopSettingsDto,
  useGetSettingsV6ThemesQuery,
  usePutSettingsV6ThemesMutation,
  SaveThemeSlotsRequestDto,
  useGetSettingsV6ThemesByThemeTypeWidgetInstallationQuery,
  useGetSettingsV6LookupWidgetInstallationAssetsByThemeTypeQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetInstallationInstallMutation,
  InstallWidgetRequestDto,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingQuery,
  useGetSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingProductQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingMutation,
  StrikethroughPricingGeneralWidgetShopSettingsDto,
  usePutSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingProductMutation,
  ProductStrikethroughPricingShopSettingsDto,
  useGetSettingsV6TranslationsMainQuery,
  usePutSettingsV6TranslationsByLocaleMutation,
  TranslationsSettingsDto,
  usePutSettingsV6StyleMutation,
  StyleSettingsDto,
  useGetSettingsV6DiscountLinksQuery,
  usePutSettingsV6DiscountLinksMutation,
  DiscountLinksSettingsDto,
  useGetSettingsV6GiftsQuery,
  usePutSettingsV6GiftsMutation,
  GiftsSettingsDto,
  usePostSettingsV6ImportMutation,
  useGetSettingsV6CartBehaviorQuery,
  usePutSettingsV6CartBehaviorMutation,
  CartBehaviorSettingsDto,
  useGetSettingsV6TagsMetadataQuery,
  usePutSettingsV6TagsMetadataMutation,
  TagsMetadataSettingsDto,
  useGetSettingsV6TestingQuery,
  usePutSettingsV6TestingMutation,
  TestingSettingsDto,
  useGetSettingsV6AccountQuery,
  usePutSettingsV6AccountMutation,
  AccountSettingsDto,
  useGetSettingsV6PlansCurrentQuery,
  usePostSettingsV6PlansSelectSubscriptionMutation,
  SelectSubscriptionPlanRequestDto,
  usePostSettingsV6PlansBillsMutation,
  GetBillingsRequestDto,
  usePostSettingsV6PlansCancelSubscriptionMutation,
  CancelSubscriptionRequestDto,
  useGetSettingsV6PlansQuery,
  useGetSettingsV6CheckoutQuery,
  usePutSettingsV6CheckoutMutation,
  CheckoutSettingsDto,
  usePostSettingsV6PlansResumeSubscriptionMutation,
  usePostSettingsV6PlansPlanSupportingFeatureMutation,
  GetPlanSupportingFeatureRequestDto,
  usePostSettingsV6PlansRequestDevelopmentPlanMutation,
  useGetSettingsV6ShippingRatesQuery,
  usePostSettingsV6ShippingRatesMutation,
  CustomShippingRateDto,
  usePutSettingsV6ShippingRatesByIdMutation,
  useDeleteSettingsV6ShippingRatesByIdMutation,
} from 'core/api/adminSettings/adminSettingsApi';
import { PlanThresholdTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import {
  useGetWidgetsV6LookupIconsCustomQuery,
  useGetWidgetsV6LookupImagesCustomQuery,
  useIconsQuery,
  useImagesQuery,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { useAppSelector } from 'core/hooks';
import {
  setAvailableLocales,
  setIntegrations,
  setLocalesFetch,
  setIsUnsavedChanges,
} from 'core/store/settingsSlice';
import {
  setCustomIconLibary,
  setCustomImageLibary,
  setDefaultIconLibary,
  setDefaultImageLibary,
} from 'core/store/widgetsSlice';
import { isEmpty } from 'lodash';
import { useEffect, useCallback, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';

const APP_NAME = process.env.REACT_APP_APP_NAME || '';

export enum SettingsFetchTypeEnum {
  ANNOUNCEMENT_BAR = 'ANNOUNCEMENT_BAR',
  PRODUCT_BANNER = 'PRODUCT_BANNER',
  NOTIFICATION = 'NOTIFICATION',
  PROMOTIONAL_BADGE = 'PROMOTIONAL_BADGE',
  PROMOTION_CODE_FIELD = 'PROMOTION_CODE_FIELD',
  DISCOUNT_NINJA_PROMOTION_CODES = 'DISCOUNT_NINJA_PROMOTION_CODES',
  SHOPIFY_DISCOUNT_CODES = 'SHOPIFY_DISCOUNT_CODES',
  PROMOTION_SUMMARY = 'PROMOTION_SUMMARY',
  OFFER_RULE_POPUP = 'OFFER_RULE_POPUP',
  COUNTDOWN_CLOCK = 'COUNTDOWN_CLOCK',
  THEMES_SLOTS = 'THEMES_SLOTS',
  STRIKE_THROUGH_PRICING = 'STRIKE_THROUGH_PRICING',
  STRIKE_THROUGH_PRODUCT = 'STRIKE_THROUGH_PRODUCT',
  STRIKE_THROUGH_COLLECTION = 'STRIKE_THROUGH_COLLECTION',
  STRIKE_THROUGH_DRAWER_CART = 'STRIKE_THROUGH_DRAWER_CART',
  STRIKE_THROUGH_CART = 'STRIKE_THROUGH_CART',
  TOGGLE_WIDGET_MANUALLY = 'TOGGLE_WIDGET_MANUALLY',
  WIDGET_SUPPORT_REQUEST = 'WIDGET_SUPPORT_REQUEST',
  INTEGRATIONS = 'INTEGRATIONS',
  ACCOUNT = 'ACCOUNT',
  BRAND_COLORS = 'BRAND_COLORS',
  TRANSLATION_MAIN = 'TRANSLATION_MAIN',
  TRANSLATION_LOCALE = 'TRANSLATION_LOCALE',
  ORDER_TAGS = 'ORDER_TAGS',
  ADVANCED = 'ADVANCED',
  CHECKOUT_OPTIONS = 'CHECKOUT_OPTIONS',
  MANAGE_WIDGETS = 'MANAGE_WIDGETS',
  CART_BEHAVIOR = 'CART_BEHAVIOR',
  TAGS_METADATA = 'TAGS_METADATA',
  TESTING = 'TESTING',
  DISCOUNT_LINKS = 'DISCOUNT_LINKS',
  GIFTS = 'GIFTS',
  IMPORT_EXPORT = 'IMPORT_EXPORT',
  TIMEZONE = 'TIMEZONE',
  PLAN = 'PLAN',
  PLAN_PAUSE_DETAILS = 'PLAN_PAUSE_DETAILS',
  PLAN_DETAILS = 'PLAN_DETAILS',
  SYSTEM_CHECK = 'SYSTEM_CHECK',
  CUSTOM_SHIPPING_RATES = 'CUSTOM_SHIPPING_RATES',
}

export const useConfigureSettings = (
  fetchType?: SettingsFetchTypeEnum,
  skipUnnecessaryRequests?: boolean
) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const ENCODED_HOST = localStorage.getItem('encodedHost') || '';
  const dispatch = useDispatch();

  const {
    integrations,
    localesFetch,
    availableLocales,
    releaseVersionsFetch,
    widgetThemeType,
  } = useAppSelector((state) => state.settings);

  const {
    data: shippingRatesData,
    isFetching: shippingRatesIsFetching,
    refetch: refetchShippingRatesData,
  } = useGetSettingsV6ShippingRatesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.CUSTOM_SHIPPING_RATES,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    createShippingRateMutation,
    { isLoading: createShippingRateIsLoading, error: createShippingRateError },
  ] = usePostSettingsV6ShippingRatesMutation();

  const createShippingRate = useCallback(
    async (
      data: CustomShippingRateDto,
      onSuccess: (newRate: CustomShippingRateDto) => void
    ) => {
      await createShippingRateMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then((res) => onSuccess(res))
        .catch((err) => null);
    },
    [createShippingRateMutation]
  );

  const [
    updateShippingRateMutation,
    { isLoading: updateShippingRateIsLoading, error: updateShippingRateError },
  ] = usePutSettingsV6ShippingRatesByIdMutation();

  const updateShippingRate = useCallback(
    async (data: CustomShippingRateDto, onSuccess: () => void) => {
      await updateShippingRateMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
        id: data.id || '',
      })
        .unwrap()
        .then(() => onSuccess())
        .catch((err) => null);
    },
    [updateShippingRateMutation]
  );

  const [
    deleteShippingRateMutation,
    { isLoading: deleteShippingRateIsLoading, error: deleteShippingRateError },
  ] = useDeleteSettingsV6ShippingRatesByIdMutation();

  const deleteShippingRate = useCallback(
    async (id: string, onSuccess: () => void) => {
      await deleteShippingRateMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => onSuccess())
        .catch((err) => null);
    },
    [deleteShippingRateMutation]
  );

  const {
    data: checkoutOptionsData,
    isFetching: checkoutOptionsIsFetching,
    refetch: refetchCheckoutOptionsData,
  } = useGetSettingsV6CheckoutQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.CHECKOUT_OPTIONS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveCheckoutOptionsMutation,
    {
      isLoading: saveCheckoutOptionsIsLoading,
      error: saveCheckoutOptionsError,
    },
  ] = usePutSettingsV6CheckoutMutation();

  const saveCheckoutOptions = useCallback(
    async (data: CheckoutSettingsDto, onSuccess: () => void) => {
      await saveCheckoutOptionsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
    },
    [saveCheckoutOptionsMutation]
  );

  const {
    data: planDetailsData,
    isFetching: planDetailsIsFetching,
    refetch: refetchPlanDetailsData,
  } = useGetSettingsV6PlansQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      thresholdType: PlanThresholdTypeDtoEnum.ORDER,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PLAN_DETAILS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    requestDevelopmentPlanMutation,
    {
      isLoading: requestDevelopmentPlanIsLoading,
      error: requestDevelopmentPlanError,
      isSuccess: requestDevelopmentPlanSuccess,
    },
  ] = usePostSettingsV6PlansRequestDevelopmentPlanMutation();

  const requestDevelopmentPlan = useCallback(async () => {
    await requestDevelopmentPlanMutation({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    }).unwrap();
  }, [requestDevelopmentPlanMutation]);

  const [
    cancelSubscriptionMutation,
    { isLoading: cancelSubscriptionIsLoading, error: cancelSubscriptionError },
  ] = usePostSettingsV6PlansCancelSubscriptionMutation();

  const cancelSubscription = useCallback(
    async (data: CancelSubscriptionRequestDto, refetchData: () => void) => {
      await cancelSubscriptionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => refetchData())
        .catch((err) => null);
    },
    [cancelSubscriptionMutation]
  );

  const [
    getPlansSupportingFeatureMutation,
    {
      isLoading: plansSupportingFeatureIsLoading,
      data: plansSupportingFeatureData,
    },
  ] = usePostSettingsV6PlansPlanSupportingFeatureMutation();

  const getPlansSupportingFeature = useCallback(
    async (data: GetPlanSupportingFeatureRequestDto) => {
      await getPlansSupportingFeatureMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
    },
    [getPlansSupportingFeatureMutation]
  );

  const [
    resumeSubscriptionMutation,
    { isLoading: resumeSubscriptionIsLoading, error: resumeSubscriptionError },
  ] = usePostSettingsV6PlansResumeSubscriptionMutation();

  const resumeSubscription = useCallback(
    async (refetchData: () => void) => {
      await resumeSubscriptionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      })
        .unwrap()
        .then(() => refetchData());
    },
    [resumeSubscriptionMutation]
  );

  const [
    getBillsListMutation,
    {
      data: billsListData,
      isLoading: billsListIsLoading,
      error: billsListError,
    },
  ] = usePostSettingsV6PlansBillsMutation();

  const getBillsList = useCallback(
    async (data: GetBillingsRequestDto) => {
      await getBillsListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
    },
    [getBillsListMutation]
  );

  const [
    turnWidgetOnManuallyMutation,
    {
      isLoading: turnWidgetOnManuallyIsLoading,
      error: turnWidgetOnManuallyError,
    },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetInstallationInstallMutation();

  const turnWidgetOnManually = useCallback(
    async (data: InstallWidgetRequestDto) => {
      const response = await turnWidgetOnManuallyMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      }).unwrap();
      return response;
    },
    [turnWidgetOnManuallyMutation]
  );

  const {
    data: currentPlanData,
    isFetching: currentPlansIsLoading,
    error: currentPlanDataError,
    refetch: refetchCurrentPlanData,
  } = useGetSettingsV6PlansCurrentQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: fetchType !== SettingsFetchTypeEnum.PLAN,
    }
  );

  const [
    selectSubscriptionMutation,
    {
      data: selectSubscriptionData,
      isLoading: selectSubscriptionIsLoading,
      error: selectSubscriptionError,
    },
  ] = usePostSettingsV6PlansSelectSubscriptionMutation();

  const selectSubscription = useCallback(
    async (body: SelectSubscriptionPlanRequestDto) => {
      await selectSubscriptionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        'X-DiscountNinja-EncodedHost': ENCODED_HOST,
        body: body,
      })
        .unwrap()
        .catch((err) => null);
    },
    [selectSubscriptionMutation]
  );

  const {
    data: strikethroughPricingData,
    isFetching: strikethroughPricingIsFetching,
    refetch: refetchStrikethroughPricingData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.STRIKE_THROUGH_PRICING,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveStrikethroughPricingMutation,
    { isLoading: saveStrikethroughPricingIsLoading },
  ] =
    usePutSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingMutation();

  const saveStrikethroughPricing = useCallback(
    async (
      data: StrikethroughPricingGeneralWidgetShopSettingsDto,
      onSuccess: () => void
    ) => {
      await saveStrikethroughPricingMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
    },
    [saveStrikethroughPricingMutation]
  );

  const {
    data: strikethroughProductData,
    isFetching: strikethroughProductIsFetching,
    refetch: refetchStrikethroughProductData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingProductQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.STRIKE_THROUGH_PRODUCT,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveStrikethroughProductMutation,
    { isLoading: saveStrikethroughProductIsLoading },
  ] =
    usePutSettingsV6ThemesByThemeTypeWidgetSettingsStrikethroughPricingProductMutation();

  const saveStrikethroughProduct = useCallback(
    async (
      data: ProductStrikethroughPricingShopSettingsDto,
      onSuccess: () => void
    ) => {
      await saveStrikethroughProductMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
    },
    [saveStrikethroughProductMutation, dispatch]
  );

  const {
    data: widgetInstallationAssetsData,
    isFetching: widgetInstallationAssetsIsFetching,
    refetch: refetchWidgetInstallationAssetsData,
  } = useGetSettingsV6LookupWidgetInstallationAssetsByThemeTypeQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.TOGGLE_WIDGET_MANUALLY,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: widgetInstallationData,
    isFetching: widgetInstallationDataIsFetching,
    refetch: refetchWidgetInstallationData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetInstallationQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
      'Cache-Control': 'no-cache',
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.MANAGE_WIDGETS,
      refetchOnMountOrArgChange: true,
    }
  );

  // const [
  //   saveWidgetInstallationMutation,
  //   { isLoading: saveWidgetInstallationIsLoading },
  // ] = usePutSettingsV6ThemesByThemeTypeWidgetInstallationMutation();

  // const saveWidgetInstallation = useCallback(
  //   async (data: WidgetsInstallationStatusSettingsDto) => {
  //     await saveWidgetInstallationMutation({
  //       'X-LimoniApps-AppName': APP_NAME,
  //       'X-LimoniApps-AppSecret': APP_PASSWORD,
  //       themeType: widgetThemeType,
  //       body: data,
  //     })
  //       .unwrap()
  //       .then(() => {
  //         dispatch(setIsUnsavedChanges(false));
  //       });
  //   },
  //   [saveWidgetInstallationMutation, dispatch]
  // );

  const {
    data: themeSlotsData,
    isFetching: themeSlotsDataIsFetching,
    refetch: refetchThemeSlotsData,
  } = useGetSettingsV6ThemesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.MANAGE_WIDGETS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveThemeSlotsMutation, { isLoading: saveThemeSlotsIsLoading }] =
    usePutSettingsV6ThemesMutation();

  const saveThemeSlots = useCallback(
    async (data: SaveThemeSlotsRequestDto) => {
      await saveThemeSlotsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveThemeSlotsMutation, dispatch]
  );

  const {
    data: countdownClockData,
    isFetching: countdownClockDataIsFetching,
    refetch: refetchCountdownClockData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsCountdownClockQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.COUNTDOWN_CLOCK,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveCountdownClockMutation,
    { isLoading: saveCountdownClockIsLoading },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetSettingsCountdownClockMutation();

  const saveCountdownClock = useCallback(
    async (data: CountdownClockShopSettingsDto) => {
      await saveCountdownClockMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveCountdownClockMutation, dispatch]
  );

  const {
    data: offerRulePopupData,
    isFetching: offerRulePopupDataIsFetching,
    refetch: refetchOfferRulePopupData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsOfferRulePopupQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.OFFER_RULE_POPUP,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveOfferRulePopupMutation,
    { isLoading: saveOfferRulePopupIsLoading },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetSettingsOfferRulePopupMutation();

  const saveOfferRulePopup = useCallback(
    async (data: OfferRulePopupShopSettingsDto) => {
      await saveOfferRulePopupMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveOfferRulePopupMutation, dispatch]
  );

  const {
    data: promotionSummaryData,
    isFetching: promotionSummaryDataIsFetching,
    refetch: refetchPromotionSummaryData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsPromotionSummaryQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PROMOTION_SUMMARY,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    savePromotionSummaryMutation,
    { isLoading: savePromotionSummaryIsLoading },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetSettingsPromotionSummaryMutation();

  const savePromotionSummary = useCallback(
    async (data: PromotionSummaryShopSettingsDto) => {
      await savePromotionSummaryMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [savePromotionSummaryMutation, dispatch]
  );

  const {
    data: promotionCodeData,
    isFetching: promotionCodeDataIsFetching,
    refetch: refetchPromotionCodeData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsPromotionCodeFieldQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PROMOTION_CODE_FIELD,
      refetchOnMountOrArgChange: true,
    }
  );

  const [savePromotionCodeMutation, { isLoading: savePromotionCodeIsLoading }] =
    usePutSettingsV6ThemesByThemeTypeWidgetSettingsPromotionCodeFieldMutation();

  const savePromotionCode = useCallback(
    async (data: PromotionCodeFieldShopSettingsDto) => {
      await savePromotionCodeMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [savePromotionCodeIsLoading, dispatch]
  );

  const {
    data: promotionalBadgeData,
    isFetching: promotionalBadgeDataIsFetching,
    refetch: refetchPromotionalBadgeData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsPromotionalBadgeQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PROMOTIONAL_BADGE,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    savePromotionalBadgeMutation,
    { isLoading: savePromotionalBadgeIsLoading },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetSettingsPromotionalBadgeMutation();

  const savePromotionalBadge = useCallback(
    async (data: PromotionalBadgeShopSettingsDto) => {
      await savePromotionalBadgeMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [savePromotionalBadgeMutation, dispatch]
  );

  const {
    data: notificationData,
    isFetching: notificationDataIsFetching,
    refetch: refetchNotificationData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsNotificationQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.NOTIFICATION,
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveNotificationMutation, { isLoading: saveNotificationIsLoading }] =
    usePutSettingsV6ThemesByThemeTypeWidgetSettingsNotificationMutation();

  const saveNotification = useCallback(
    async (data: NotificationShopSettingsDto) => {
      await saveNotificationMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveNotificationMutation, dispatch]
  );

  const {
    data: productBannerData,
    isFetching: productBannerDataIsFetching,
    refetch: refetchProductBannerData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsProductBannerQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PRODUCT_BANNER,
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveProductBannerMutation, { isLoading: saveProductBannerIsLoading }] =
    usePutSettingsV6ThemesByThemeTypeWidgetSettingsProductBannerMutation();

  const saveProductBanner = useCallback(
    async (data: ProductBannerShopSettingsDto) => {
      await saveProductBannerMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveProductBannerMutation, dispatch]
  );

  const {
    data: announcementBarData,
    isFetching: announcementBarDataIsFetching,
    refetch: refetchAnnouncementBarData,
  } = useGetSettingsV6ThemesByThemeTypeWidgetSettingsAnnouncementBarQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.ANNOUNCEMENT_BAR,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveAnnouncementBarMutation,
    { isLoading: saveAnnouncementBarIsLoading },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetSettingsAnnouncementBarMutation();

  const saveAnnouncementBar = useCallback(
    async (data: AnnouncementBarShopSettingsDto) => {
      await saveAnnouncementBarMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: widgetThemeType,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveAnnouncementBarMutation, dispatch]
  );

  const { data: defaultImageLibary, isFetching: isDefaultImageLibaryFetching } =
    useImagesQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: skipUnnecessaryRequests }
    );

  const {
    data: customImageLibary,
    isFetching: isCustomImageLibaryFetching,
    refetch: refetchCustomImageLibary,
  } = useGetWidgetsV6LookupImagesCustomQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: skipUnnecessaryRequests }
  );

  useEffect(() => {
    if (defaultImageLibary) {
      dispatch(setDefaultImageLibary(defaultImageLibary));
    }
  }, [defaultImageLibary]);

  useEffect(() => {
    if (customImageLibary) {
      dispatch(setCustomImageLibary(customImageLibary));
    }
  }, [customImageLibary]);

  const { data: defaultIconLibary, isFetching: isDefaultIconLibaryFetching } =
    useIconsQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: skipUnnecessaryRequests }
    );
  const {
    data: customIconLibary,
    isFetching: isCustomIconLibaryFetching,
    refetch: refetchCustomIconLibary,
  } = useGetWidgetsV6LookupIconsCustomQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: skipUnnecessaryRequests }
  );

  useEffect(() => {
    if (defaultIconLibary) {
      dispatch(setDefaultIconLibary(defaultIconLibary));
    }
  }, [defaultIconLibary]);

  useEffect(() => {
    if (customIconLibary) {
      dispatch(setCustomIconLibary(customIconLibary));
    }
  }, [customIconLibary]);

  const {
    data: localesData,
    isFetching: localesIsFetching,
    error: localesError,
    refetch: refetchLocales,
  } = useGetSettingsV6LookupLocalesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: !localesFetch, refetchOnMountOrArgChange: false }
  );

  useEffect(() => {
    if (localesData && !availableLocales.length) {
      dispatch(setAvailableLocales(localesData));
      dispatch(setLocalesFetch(false));
    }
  }, [localesData]);

  const {
    data: themesData,
    isFetching: themesIsFetching,
    error: themesError,
    refetch: refetchThemesData,
  } = useGetSettingsV6LookupThemesQuery({
    'X-LimoniApps-AppName': APP_NAME,
    'X-LimoniApps-AppSecret': APP_PASSWORD,
    'Cache-Control': 'no-cache',
  });

  const {
    data: integrationsData,
    isFetching: integrationsDataIsFetching,
    error: integrationsDataError,
  } = useGetSettingsV6IntegrationsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: fetchType !== SettingsFetchTypeEnum.INTEGRATIONS }
  );

  useEffect(() => {
    if (integrationsData && isEmpty(integrations)) {
      dispatch(setIntegrations(integrationsData));
    }
  }, [integrationsData, dispatch]);

  const {
    data: timezoneData,
    isFetching: timezoneIsFetching,
    error: timezoneError,
  } = useGetSettingsV6LookupTimezonesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
    },
    { skip: fetchType !== SettingsFetchTypeEnum.TIMEZONE }
  );

  const {
    data: releaseVersionsData,
    isFetching: releaseVersionsIsFetching,
    error: releaseVersionsError,
    refetch: refetchReleaseVersions,
  } = useGetSettingsV6LookupReleaseVersionsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
    },
    { skip: !releaseVersionsFetch }
  );

  const {
    data: requestSupportData,
    isFetching: requestSupportIsFetching,
    error: requestSupportError,
    refetch: refetchRequestSupportData,
  } = useGetSettingsV6WidgetInstallationSupportRequestQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.WIDGET_SUPPORT_REQUEST,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveIntegrationsMutation,
    { isLoading: saveIntegrationsSettingsLoading },
  ] = usePutSettingsV6IntegrationsMutation();

  const saveIntegrationsSettings = useCallback(
    async (data: IntegrationsSettingsDto, onSuccess: () => void) => {
      await saveIntegrationsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
    },
    [saveIntegrationsMutation, dispatch]
  );

  const [
    createSupportRequestMutation,
    {
      isLoading: createSupportRequestIsLoading,
      error: createSupportRequestError,
    },
  ] = usePostSettingsV6WidgetInstallationSupportRequestMutation();

  const createSupportRequest = useCallback(
    async (data: InstallationSupportRequestDto, onSuccess: () => void) => {
      const response = await createSupportRequestMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => onSuccess())
        .catch(() => null);
      return response;
    },
    [createSupportRequestMutation]
  );

  const [updateSupportRequestMutation, { isLoading: updateSupportIsLoading }] =
    usePutSettingsV6WidgetInstallationSupportRequestMutation();

  const updateSupportRequest = useCallback(
    async (data: InstallationSupportRequestDto) => {
      const response = await updateSupportRequestMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
      return response;
    },
    [updateSupportRequestMutation]
  );

  const [deleteSupportRequestMutation, { isLoading: deleteSupportIsLoading }] =
    useDeleteSettingsV6WidgetInstallationSupportRequestMutation();

  const deleteSupportRequest = useCallback(async () => {
    const response = await deleteSupportRequestMutation({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    }).unwrap();
    return response;
  }, [deleteSupportRequestMutation]);

  const {
    data: translationMainData,
    isFetching: translationMainIsFetching,
    refetch: refetchTranslationMainData,
  } = useGetSettingsV6TranslationsMainQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.TRANSLATION_MAIN,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    translationLocaleMutation,
    { isLoading: translationLocaleDataIsLoading },
  ] = usePutSettingsV6TranslationsByLocaleMutation();

  const translationLocaleDataChange = useCallback(
    async (
      data: TranslationsSettingsDto,
      locale: string,
      onSuccess: () => void
    ) => {
      const response = await translationLocaleMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        locale: locale,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
      return response;
    },
    [translationLocaleMutation]
  );

  const [brandColorsMutation, { isLoading: brandColorsDataIsLoading }] =
    usePutSettingsV6StyleMutation();

  const brandColorsDataChange = useCallback(
    async (
      data: StyleSettingsDto,
      setRefetchBrandColors?: Dispatch<SetStateAction<boolean>>
    ) => {
      try {
        const response = await brandColorsMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        }).unwrap();

        if (setRefetchBrandColors) {
          setRefetchBrandColors(true);
        }

        return response;
      } catch (error) {
        console.error('Error in brandColorsDataChange:', error);
        // Optionally handle error here
      }
    },
    [brandColorsMutation]
  );

  const {
    data: cartBehaviorData,
    isFetching: cartBehaviorDataIsFetching,
    refetch: refetchCartBehaviorData,
  } = useGetSettingsV6CartBehaviorQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.CART_BEHAVIOR,
      refetchOnMountOrArgChange: true,
    }
  );

  const [cartBehaviorMutation, { isLoading: cartBehaviorDataIsLoading }] =
    usePutSettingsV6CartBehaviorMutation();

  const cartBehaviorDataChange = useCallback(
    async (data: CartBehaviorSettingsDto, onSuccess: () => void) => {
      const response = await cartBehaviorMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess);
      return response;
    },
    [cartBehaviorMutation]
  );

  const {
    data: tagsMetadataData,
    isFetching: tagsMetadataDataIsFetching,
    refetch: refetchTagsMetadataData,
  } = useGetSettingsV6TagsMetadataQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.TAGS_METADATA,
      refetchOnMountOrArgChange: true,
    }
  );

  const [tagsMetadataMutation, { isLoading: tagsMetadataDataIsLoading }] =
    usePutSettingsV6TagsMetadataMutation();

  const tagsMetadataDataChange = useCallback(
    async (data: TagsMetadataSettingsDto, onSuccess: () => void) => {
      const response = await tagsMetadataMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
      return response;
    },
    [tagsMetadataMutation]
  );

  const {
    data: testingData,
    isFetching: testingIsFetching,
    refetch: refetchTestingData,
  } = useGetSettingsV6TestingQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.TESTING,
      refetchOnMountOrArgChange: true,
    }
  );

  const [testingMutation, { isLoading: testingDataIsLoading }] =
    usePutSettingsV6TestingMutation();

  const testingDataChange = useCallback(
    async (data: TestingSettingsDto) => {
      const response = await testingMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
      return response;
    },
    [testingMutation]
  );

  const {
    data: discountLinksData,
    isFetching: discountLinksIsFetching,
    refetch: refetchDiscountLinksData,
  } = useGetSettingsV6DiscountLinksQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.DISCOUNT_LINKS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [discountLinksMutation, { isLoading: discountLinksDataIsLoading }] =
    usePutSettingsV6DiscountLinksMutation();

  const discountLinksDataChange = useCallback(
    async (data: DiscountLinksSettingsDto, onSuccess: () => void) => {
      const response = await discountLinksMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
      return response;
    },
    [discountLinksMutation]
  );

  const {
    data: giftsData,
    isFetching: giftsDataIsFetching,
    refetch: refetchGiftsData,
  } = useGetSettingsV6GiftsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.GIFTS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [giftsMutation, { isLoading: giftsDataIsLoading }] =
    usePutSettingsV6GiftsMutation();

  const giftsDataChange = useCallback(
    async (data: GiftsSettingsDto, onSuccess: () => void) => {
      const response = await giftsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess);
      return response;
    },
    [giftsMutation]
  );

  const [importSettingsMutation, { isLoading: importSettingsIsLoading }] =
    usePostSettingsV6ImportMutation();

  const importSettings = useCallback(
    async (data: any) => {
      const response = await importSettingsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
      return response;
    },
    [importSettingsMutation]
  );

  const {
    data: accountData,
    isFetching: accountDataIsFetching,
    refetch: refetchAccountData,
  } = useGetSettingsV6AccountQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.ACCOUNT,
      refetchOnMountOrArgChange: true,
    }
  );

  const [accountMutation, { isLoading: accountDataIsLoading }] =
    usePutSettingsV6AccountMutation();

  const accountDataChange = useCallback(
    async (data: AccountSettingsDto, onSuccess: () => void) => {
      const response = await accountMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess);
      return response;
    },
    [accountMutation]
  );

  return {
    integrationsData,
    integrationsDataIsFetching,
    integrationsDataError,
    saveIntegrationsSettingsLoading,
    saveIntegrationsSettings,
    timezoneData,
    timezoneIsFetching,
    timezoneError,
    themesData,
    themesIsFetching,
    themesError,
    refetchThemesData,
    localesData,
    localesIsFetching,
    localesError,
    refetchLocales,
    releaseVersionsData,
    releaseVersionsIsFetching,
    releaseVersionsError,
    refetchReleaseVersions,
    createSupportRequest,
    createSupportRequestIsLoading,
    requestSupportData,
    requestSupportIsFetching,
    requestSupportError,
    refetchRequestSupportData,
    updateSupportIsLoading,
    updateSupportRequest,
    deleteSupportIsLoading,
    deleteSupportRequest,
    announcementBarData,
    announcementBarDataIsFetching,
    refetchAnnouncementBarData,
    saveAnnouncementBarIsLoading,
    saveAnnouncementBar,
    defaultImageLibary,
    isDefaultImageLibaryFetching,
    customImageLibary,
    isCustomImageLibaryFetching,
    isCustomIconLibaryFetching,
    refetchCustomIconLibary,
    productBannerData,
    productBannerDataIsFetching,
    refetchProductBannerData,
    saveProductBannerIsLoading,
    saveProductBanner,
    notificationData,
    notificationDataIsFetching,
    refetchNotificationData,
    saveNotificationIsLoading,
    saveNotification,
    promotionalBadgeData,
    promotionalBadgeDataIsFetching,
    refetchPromotionalBadgeData,
    savePromotionalBadgeIsLoading,
    savePromotionalBadge,
    promotionCodeData,
    promotionCodeDataIsFetching,
    refetchPromotionCodeData,
    savePromotionCodeIsLoading,
    savePromotionCode,
    promotionSummaryData,
    promotionSummaryDataIsFetching,
    refetchPromotionSummaryData,
    savePromotionSummaryIsLoading,
    savePromotionSummary,
    offerRulePopupData,
    offerRulePopupDataIsFetching,
    refetchOfferRulePopupData,
    saveOfferRulePopupIsLoading,
    saveOfferRulePopup,
    countdownClockData,
    countdownClockDataIsFetching,
    refetchCountdownClockData,
    saveCountdownClockIsLoading,
    saveCountdownClock,
    themeSlotsData,
    themeSlotsDataIsFetching,
    refetchThemeSlotsData,
    saveThemeSlotsIsLoading,
    saveThemeSlots,
    widgetInstallationData,
    widgetInstallationDataIsFetching,
    refetchWidgetInstallationData,
    // saveWidgetInstallationIsLoading,
    // saveWidgetInstallation,
    widgetInstallationAssetsData,
    widgetInstallationAssetsIsFetching,
    refetchWidgetInstallationAssetsData,
    turnWidgetOnManuallyIsLoading,
    turnWidgetOnManuallyError,
    turnWidgetOnManually,
    strikethroughPricingData,
    strikethroughPricingIsFetching,
    refetchStrikethroughPricingData,
    strikethroughProductData,
    strikethroughProductIsFetching,
    refetchStrikethroughProductData,
    saveStrikethroughPricingIsLoading,
    saveStrikethroughPricing,
    saveStrikethroughProductIsLoading,
    saveStrikethroughProduct,
    translationMainData,
    translationMainIsFetching,
    refetchTranslationMainData,
    translationLocaleDataIsLoading,
    translationLocaleDataChange,
    brandColorsDataIsLoading,
    brandColorsDataChange,
    cartBehaviorData,
    cartBehaviorDataIsFetching,
    refetchCartBehaviorData,
    cartBehaviorDataIsLoading,
    cartBehaviorDataChange,
    tagsMetadataData,
    tagsMetadataDataIsFetching,
    refetchTagsMetadataData,
    tagsMetadataDataIsLoading,
    tagsMetadataDataChange,
    testingData,
    testingIsFetching,
    refetchTestingData,
    testingDataIsLoading,
    testingDataChange,
    discountLinksData,
    discountLinksIsFetching,
    refetchDiscountLinksData,
    discountLinksDataIsLoading,
    discountLinksDataChange,
    giftsData,
    giftsDataIsFetching,
    refetchGiftsData,
    giftsDataIsLoading,
    giftsDataChange,
    importSettingsIsLoading,
    importSettings,
    accountData,
    accountDataIsFetching,
    refetchAccountData,
    accountDataIsLoading,
    accountDataChange,
    currentPlanData,
    currentPlansIsLoading,
    currentPlanDataError,
    refetchCurrentPlanData,
    selectSubscriptionData,
    selectSubscriptionIsLoading,
    selectSubscriptionError,
    selectSubscription,
    billsListData,
    billsListIsLoading,
    billsListError,
    getBillsList,
    cancelSubscriptionIsLoading,
    cancelSubscriptionError,
    cancelSubscription,
    planDetailsData,
    planDetailsIsFetching,
    refetchPlanDetailsData,
    checkoutOptionsData,
    checkoutOptionsIsFetching,
    refetchCheckoutOptionsData,
    saveCheckoutOptionsIsLoading,
    saveCheckoutOptionsError,
    saveCheckoutOptions,
    resumeSubscriptionIsLoading,
    resumeSubscriptionError,
    resumeSubscription,
    plansSupportingFeatureIsLoading,
    plansSupportingFeatureData,
    getPlansSupportingFeature,
    requestDevelopmentPlanIsLoading,
    requestDevelopmentPlanError,
    requestDevelopmentPlanSuccess,
    requestDevelopmentPlan,
    shippingRatesData,
    shippingRatesIsFetching,
    refetchShippingRatesData,
    updateShippingRateIsLoading,
    updateShippingRateError,
    updateShippingRate,
    deleteShippingRateIsLoading,
    deleteShippingRateError,
    deleteShippingRate,
    createShippingRateIsLoading,
    createShippingRateError,
    createShippingRate,
  };
};
