import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionSetupGuide.scss';
import {
  ActionList,
  BlockStack,
  Button,
  Card,
  InlineStack,
  Popover,
  Text,
  Icon,
  Divider,
  Box,
  Banner,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  MenuHorizontalIcon,
  XIcon,
  DiscountIcon,
  ColorIcon,
  AdjustIcon,
  CalendarTimeIcon,
  SendIcon,
  CheckCircleIcon,
} from '@shopify/polaris-icons';
import { PromotionOverviewDtoRead } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionStatusDtoEnum,
  PromotionTypeDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  dismissComponent,
  DismissedEnum,
  getDismissedComponents,
} from 'features/dashboard/utils/utils';
import classNames from 'classnames';
import {
  SelectOfferModalTypeEnum,
  SelectOfferPage,
} from 'pages/SelectOfferPage';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { useNavigate } from 'react-router-dom';
import { PromotionPageSubPathsEnums } from 'pages/enums/PagesEnums';
import { ScheduleModalEnum } from '../SchedulePromotionModal/SchedulePromotionModal';

type PromotionSetupGuideProps = {
  hasOfferAdded: boolean;
  promotionOverviewData?: PromotionOverviewDtoRead;
  promotionId: string;
  isPromotionEditable: boolean;
  onComponentDismiss: () => void;
  handleFinishConfigurationModal: () => Promise<void>;
  setScheduleModalType: React.Dispatch<
    React.SetStateAction<ScheduleModalEnum | null>
  >;
};

export const PromotionSetupGuide: React.FC<PromotionSetupGuideProps> = (
  props
) => {
  const {
    hasOfferAdded,
    promotionOverviewData,
    promotionId,
    isPromotionEditable,
    onComponentDismiss,
    handleFinishConfigurationModal,
    setScheduleModalType,
  } = props;

  const [i18n] = useI18n();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<string | undefined>('addOffers');
  const [noSchedules, setNoSchedules] = useState<string[]>(
    getDismissedComponents(
      DismissedEnum.PROMOTION_OVERVIEW_SCHEDULED_PROMOTIONS
    )
  );
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [addOfferModal, setAddOfferModal] = useState<boolean>(false);

  const promotionType = useMemo(
    () =>
      promotionOverviewData?.settings?.triggerType === TriggerTypeDtoEnum.PUBLIC
        ? 'Automatic'
        : promotionOverviewData?.settings?.triggerType ===
            TriggerTypeDtoEnum.PRIVATE &&
          promotionOverviewData?.settings?.promotionType ===
            PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE
        ? 'SDC'
        : 'Secret',
    [
      promotionOverviewData?.settings?.triggerType,
      promotionOverviewData?.settings?.promotionType,
    ]
  );

  const hasBeenPublished = useMemo(
    () =>
      promotionOverviewData?.status &&
      [
        PromotionStatusDtoEnum.PUBLISHED,
        PromotionStatusDtoEnum.PUBLISHED_EDITED,
      ].includes(promotionOverviewData?.status as PromotionStatusDtoEnum),
    [promotionOverviewData?.status]
  );

  const hasFiltersAdded = useMemo(
    () =>
      Object.values(promotionOverviewData?.settings?.filters || {}).some(
        (filter) => filter?.enabled
      ),
    [promotionOverviewData?.settings?.filters]
  );
  const hasScheduleConfigured = useMemo(
    () =>
      promotionId &&
      (promotionOverviewData?.schedule?.endsAt ||
        promotionOverviewData?.schedule?.startsAt ||
        noSchedules.includes(promotionId)),
    [promotionOverviewData?.schedule, promotionId, noSchedules]
  );
  const hasWidgetsManaged = useMemo(
    () =>
      promotionId &&
      getDismissedComponents(
        DismissedEnum.PROMOTION_OVERVIEW_MANAGED_WIDGETS_PROMOTIONS
      ).includes(promotionId),
    [promotionId]
  );

  const data = useMemo(() => {
    return [
      {
        id: 'addOffers',
        step: i18n.translate('addOffers'),
        title: i18n.translate('addOffers'),
        icon: DiscountIcon,
        completed: hasOfferAdded,
        isActive: activeStep === 'addOffers',
        content:
          promotionType === 'SDC'
            ? i18n.translate('addOffersContentSDC')
            : i18n.translate('addOffersContent'),
        btns: [
          {
            isPrimary: true,
            content: i18n.translate('addOfferBtn'),
            action: () => setAddOfferModal((prev) => !prev),
            disabled: !isPromotionEditable,
          },
        ],
      },
      {
        id: 'manageWidgets',
        step: i18n.translate('manageWidgets'),
        title: i18n.translate('manageWidgets'),
        icon: ColorIcon,
        completed: hasWidgetsManaged,
        isActive: activeStep === 'manageWidgets',
        content: i18n.translate('manageWidgetsContent'),
        btns: [
          {
            isPrimary: true,
            content: i18n.translate('manageWidgets'),
            action: () => {
              navigate(NavPaths.ConfigureWidgets);
              dismissComponent(
                DismissedEnum.PROMOTION_OVERVIEW_MANAGED_WIDGETS_PROMOTIONS,
                promotionId
              );
            },
            disabled: !isPromotionEditable,
          },
        ],
      },
      ...(promotionType !== 'SDC'
        ? [
            {
              id: 'setFilters',
              step: i18n.translate('setFilters'),
              title: i18n.translate('setFiltersAndBudget'),
              icon: AdjustIcon,
              completed: hasFiltersAdded,
              isActive: activeStep === 'setFilters',
              content: i18n.translate('setFiltersContent'),
              btns: [
                {
                  isPrimary: true,
                  content: i18n.translate('configureSettingsBtn'),
                  action: () => {
                    navigate(PromotionPageSubPathsEnums.Settings);
                  },
                  disabled: false,
                },
              ],
            },
          ]
        : []),
      ...(promotionType !== 'SDC'
        ? [
            {
              id: 'setSchedule',
              step: i18n.translate('setSchedule'),
              title: i18n.translate('setSchedule'),
              icon: CalendarTimeIcon,
              completed: hasScheduleConfigured,
              isActive: activeStep === 'setSchedule',
              content: i18n.translate('setScheduleContent'),
              btns: [
                {
                  isPrimary: true,
                  content: i18n.translate('setSchedule'),
                  action: () =>
                    setScheduleModalType(ScheduleModalEnum.START_DATE),
                  disabled: false,
                },
                {
                  isPrimary: false,
                  content: i18n.translate('noSchedule'),
                  action: () => {
                    dismissComponent(
                      DismissedEnum.PROMOTION_OVERVIEW_SCHEDULED_PROMOTIONS,
                      promotionId
                    );
                    setNoSchedules((prev) => [...prev, promotionId]);
                  },
                  disabled: false,
                },
              ],
            },
          ]
        : []),
      {
        id: 'publish',
        step: i18n.translate('publish'),
        title: i18n.translate('publishPromotion'),
        icon: SendIcon,
        completed: hasBeenPublished,
        isActive: activeStep === 'publish',
        content:
          promotionType === 'Secret'
            ? i18n.translate('publishPromotionContentSecret')
            : i18n.translate('publishPromotionContent'),
        btns: [
          {
            isPrimary: true,
            content: i18n.translate('publishPromotion'),
            action: handleFinishConfigurationModal,
            disabled: false,
          },
        ],
      },
    ];
  }, [
    promotionType,
    i18n,
    hasOfferAdded,
    hasWidgetsManaged,
    hasFiltersAdded,
    hasScheduleConfigured,
    hasBeenPublished,
    activeStep,
    isPromotionEditable,
    promotionId,
    dismissComponent,
    handleFinishConfigurationModal,
  ]);

  const currentStep = useMemo(
    () => (activeStep ? data.find((option) => option.id === activeStep) : null),
    [data, activeStep]
  );

  const handleSetActiveStep = useCallback(
    (index: string) => {
      if (
        hasOfferAdded &&
        !data.find((option) => option.id === index)?.completed
      ) {
        setActiveStep(index);
      }
    },
    [hasOfferAdded, data]
  );

  useEffect(() => {
    if (hasOfferAdded) {
      const firstIncompleteIndex = [
        hasOfferAdded,
        hasWidgetsManaged,
        promotionType !== 'SDC' && hasFiltersAdded,
        promotionType !== 'SDC' && hasScheduleConfigured,
        hasBeenPublished,
      ].findIndex((completed) => !completed);
      const activeStepName =
        firstIncompleteIndex >= 0
          ? data.find((_, idx) => idx === firstIncompleteIndex)?.id
          : undefined;
      setActiveStep(activeStepName);
    } else {
      setActiveStep('addOffers');
    }
  }, [
    hasOfferAdded,
    hasWidgetsManaged,
    hasFiltersAdded,
    hasScheduleConfigured,
    hasBeenPublished,
  ]);

  return (
    <div className='PromotionSetupGuide'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <InlineStack align='space-between' blockAlign='center'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('title')}
            </Text>
            <Popover
              active={popoverActive}
              onClose={() => setPopoverActive((prev) => !prev)}
              activator={
                <Button
                  onClick={() => setPopoverActive((prev) => !prev)}
                  variant='tertiary'
                  icon={MenuHorizontalIcon}
                />
              }
            >
              <ActionList
                actionRole='menuitem'
                items={[
                  {
                    content: i18n.translate('dismiss'),
                    onAction: onComponentDismiss,
                    prefix: (
                      <div
                        style={{
                          height: '1rem',
                          width: '1rem',
                          paddingTop: '.05rem',
                        }}
                      >
                        <Icon tone='subdued' source={XIcon} />
                      </div>
                    ),
                  },
                ]}
              />
            </Popover>
          </InlineStack>
          <div className='stepsWrapper'>
            <InlineStack align='space-between' blockAlign='center'>
              {data.map((step, index) => (
                <BlockStack key={index} inlineAlign='center' gap='300'>
                  <div
                    className={classNames('icon', {
                      isActive: step.isActive && !step.completed,
                      pointerCursor:
                        !step.isActive && !step.completed && hasOfferAdded,
                    })}
                    onClick={() => handleSetActiveStep(step.id)}
                  >
                    <Icon
                      source={step.completed ? CheckCircleIcon : step.icon}
                      tone={
                        step.completed
                          ? 'primary'
                          : step.isActive && !step.completed
                          ? 'primary'
                          : 'subdued'
                      }
                    />
                  </div>
                  <Text tone='subdued' alignment='center' as='p'>
                    {step.step}
                  </Text>
                </BlockStack>
              ))}
            </InlineStack>
            <Divider borderColor='border-brand' />
          </div>
          {currentStep ? (
            <Box
              background='bg-surface-active'
              padding='300'
              borderRadius='200'
            >
              <BlockStack gap='300'>
                <BlockStack gap='100'>
                  <Text as='h2' variant='headingSm'>
                    {currentStep.title}
                  </Text>
                  <Text as='p' tone='subdued'>
                    {currentStep.content}
                  </Text>
                </BlockStack>
                <InlineStack gap='200'>
                  {currentStep.btns.map((btn: any, idx: number) => (
                    <Button
                      key={idx}
                      variant={btn.isPrimary ? 'primary' : 'secondary'}
                      onClick={btn.action}
                      disabled={btn.disabled}
                    >
                      {btn.content}
                    </Button>
                  ))}
                </InlineStack>
              </BlockStack>
            </Box>
          ) : (
            <Banner tone='success'>
              {i18n.translate(`banner${promotionType}`)}
            </Banner>
          )}
        </BlockStack>
      </Card>
      {addOfferModal && (
        <SelectOfferPage
          isOpen={addOfferModal}
          onClose={() => setAddOfferModal((prev) => !prev)}
          modalType={SelectOfferModalTypeEnum.CREATE_NEW}
        />
      )}
    </div>
  );
};
