import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import './DiscountLimits.scss';
import {
  Banner,
  BlockStack,
  Box,
  Card,
  ChoiceList,
  InlineStack,
  Link,
  List,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferDiscountLimitDto,
  OptionDtoInt32,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { formatCurrencyNumber, getCurrencySymbol } from 'core/utils';

type DiscountLimits = {
  type: 'Discount' | 'Gifts';
  isSetBasedDiscount?: boolean;
  limits?: OptionDtoInt32 | undefined;
  discountLimit?: OfferDiscountLimitDto | undefined;
  maxAmount?: number;
  maxQuantity?: number;
  limitUpdate?: (limit: OptionDtoInt32) => void;
  discountLimitUpdate?: (limit: OfferDiscountLimitDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
};

export const DiscountLimits: FC<DiscountLimits> = ({
  type,
  limits,
  discountLimit,
  maxAmount,
  maxQuantity,
  isSetBasedDiscount,
  limitUpdate,
  discountLimitUpdate,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const [currentDiscountCapValue, setCurrentDiscountCapValue] = useState(
    discountLimit?.discountCap?.value?.toFixed(2)
  );

  const selected = useMemo(() => {
    if (type === 'Discount') {
      if (!discountLimit) return [];

      return Object.keys(discountLimit).filter(
        (key) => discountLimit[key as keyof typeof discountLimit]?.enabled
      );
    } else {
      return limits?.enabled ? ['value'] : [];
    }
  }, [discountLimit, limits, type]);

  const giftError = useMemo(
    () =>
      limits?.enabled &&
      ((limits?.value || 0) < (maxQuantity || 1) || limits?.value === 0),
    [limits, maxQuantity]
  );

  const discountErrors = useMemo(() => {
    const errorMessages: string[] = [];

    if (
      discountLimit?.discountedItemsLimit?.enabled &&
      !discountLimit?.discountedItemsLimit?.value
    ) {
      errorMessages.push(i18n.translate('Discount.MaximumNumberError'));
    }

    if (
      discountLimit?.discountedItemsLimit?.enabled &&
      discountLimit?.discountedItemsLimit?.value &&
      maxQuantity
    ) {
      discountLimit?.discountedItemsLimit?.value < maxQuantity &&
        errorMessages.push(
          i18n.translate('Discount.MaximumNumberLimitError', {
            quantity: maxQuantity,
          })
        );
      isSetBasedDiscount &&
        discountLimit?.discountedItemsLimit?.value % 2 !== 0 &&
        errorMessages.push(i18n.translate('Discount.MultipleOfTwoError'));
    }

    if (
      discountLimit?.discountCap?.enabled &&
      !discountLimit?.discountCap?.value
    ) {
      errorMessages.push(i18n.translate('Discount.DiscountCapError'));
    }

    if (
      discountLimit?.discountCap?.enabled &&
      discountLimit?.discountCap?.value &&
      maxAmount &&
      discountLimit?.discountCap?.value <= maxAmount
    ) {
      errorMessages.push(
        i18n.translate('Discount.DiscountCapLimitError', {
          amount: formatCurrencyNumber(maxAmount),
        })
      );
    }

    return errorMessages;
  }, [discountLimit, maxAmount, maxQuantity, isSetBasedDiscount, i18n]);

  const hasValidationError = useMemo(
    () =>
      type === 'Gifts'
        ? !!giftError
        : type === 'Discount'
        ? !!discountErrors.length
        : false,
    [type, discountErrors.length, giftError]
  );

  const handleChangeChoice = useCallback(
    (value: string[]) => {
      const updatedValue = { ...discountLimit };
      Object.keys(updatedValue).forEach((key) => {
        updatedValue[key as keyof OfferDiscountLimitDto] = {
          ...updatedValue[key as keyof OfferDiscountLimitDto],
          enabled: value.includes(key),
        };
      });
      discountLimitUpdate?.(updatedValue);
    },
    [discountLimitUpdate, discountLimit]
  );

  const handleChangeTextField = useCallback(
    (value: number | null, field?: string) => {
      if (type === 'Gifts' && limitUpdate) {
        limitUpdate({ ...limits, value: value } as OptionDtoInt32);
      } else if (discountLimitUpdate && field) {
        discountLimitUpdate({
          ...discountLimit,
          [field]: {
            ...discountLimit?.[field as keyof typeof discountLimit],
            value,
          },
        });
      }
    },
    [type, limitUpdate, discountLimitUpdate, limits, discountLimit]
  );

  const handleDiscountCapChange = useCallback(
    (value: string) => {
      // Allow only numbers and one dot, and ensure up to two decimal places
      const validatedValue = value.replace(/[^0-9.]/g, '');

      // Limit to only two decimal places
      const decimalIndex = validatedValue.indexOf('.');
      if (decimalIndex !== -1 && validatedValue.length - decimalIndex > 3) {
        return;
      }

      setCurrentDiscountCapValue(validatedValue);
      handleChangeTextField(Number(value), 'discountCap');
    },
    [setCurrentDiscountCapValue, handleChangeTextField]
  );

  useEffect(() => {
    onFormValidityChange(!hasValidationError);
  }, [hasValidationError]);

  return (
    <div className='DiscountLimits'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='80%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text as='h2' variant='headingSm'>
                    {i18n.translate(`${type}.Title`)}
                  </Text>
                </InlineStack>
                <Text as='span' tone='subdued'>
                  {i18n.translate(`${type}.Subtitle`, {
                    link: (
                      <Link url='/' target='_blank'>
                        {i18n.translate('FollowingArticle')}
                      </Link>
                    ),
                  })}
                </Text>
              </BlockStack>
            </Box>
          </InlineStack>
          <BlockStack gap='400'>
            <ChoiceList
              allowMultiple
              titleHidden
              title=''
              choices={
                type === 'Gifts'
                  ? [
                      {
                        label: i18n.translate(`${type}.Label`),
                        value: 'value',
                        helpText: i18n.translate(`${type}.HelperText`),
                        id: 'GiftLimitCheckbox',
                        renderChildren: (isSelected) =>
                          isSelected && (
                            <Box width='160px'>
                              <TextField
                                id='GiftLimitInput'
                                type='number'
                                label=''
                                labelHidden
                                suffix={i18n.translate(`${type}.Items`)}
                                error={giftError}
                                onChange={(value) =>
                                  handleChangeTextField(
                                    value === '' ? null : Number(value)
                                  )
                                }
                                value={limits?.value?.toString() || ''}
                                autoComplete='off'
                              />
                            </Box>
                          ),
                      },
                    ]
                  : [
                      {
                        label: i18n.translate(`${type}.DiscountCapLabel`),
                        value: 'discountCap',
                        helpText: i18n.translate(
                          `${type}.DiscountCapHelperText`
                        ),
                        id: 'DiscountLimitCapCheckbox',
                        renderChildren: (isSelected) =>
                          isSelected && (
                            <Box width='160px'>
                              <TextField
                                id='DiscountLimitCapInput'
                                type='number'
                                label=''
                                labelHidden
                                suffix={getCurrencySymbol()}
                                error={
                                  discountLimit?.discountCap?.enabled &&
                                  !discountLimit?.discountCap?.value
                                }
                                onChange={handleDiscountCapChange}
                                value={currentDiscountCapValue}
                                autoComplete='off'
                              />
                            </Box>
                          ),
                      },
                      {
                        label: i18n.translate(`${type}.MaximumNumberLabel`),
                        value: 'discountedItemsLimit',
                        id: 'DiscountLimitMaxNumberCheckbox',
                        helpText: i18n.translate(
                          `${type}.MaximumNumberHelperText`
                        ),
                        renderChildren: (isSelected) =>
                          isSelected && (
                            <Box width='160px'>
                              <TextField
                                type='number'
                                label=''
                                id='DiscountLimitMaxNumberInput'
                                labelHidden
                                suffix={i18n.translate(`${type}.Items`)}
                                error={
                                  discountLimit?.discountedItemsLimit
                                    ?.enabled &&
                                  !discountLimit?.discountedItemsLimit?.value
                                }
                                onChange={(value) =>
                                  handleChangeTextField(
                                    value === '' ? null : Number(value),
                                    'discountedItemsLimit'
                                  )
                                }
                                value={
                                  discountLimit?.discountedItemsLimit?.value?.toString() ||
                                  ''
                                }
                                autoComplete='off'
                              />
                            </Box>
                          ),
                      },
                    ]
              }
              selected={selected}
              onChange={
                type === 'Gifts'
                  ? (value) =>
                      limitUpdate?.({ ...limits, enabled: !!value.length })
                  : handleChangeChoice
              }
            />
            {type === 'Gifts' && giftError && (
              <Banner tone='critical'>
                {i18n.translate(`${type}.Error`, { quantity: maxQuantity })}
              </Banner>
            )}
            {type === 'Discount' && !!discountErrors.length && (
              <Banner tone='critical'>
                <List>
                  {discountErrors.map((error, idx) => (
                    <List.Item key={idx}>{error}</List.Item>
                  ))}
                </List>
              </Banner>
            )}
          </BlockStack>
        </BlockStack>
      </Card>
    </div>
  );
};
