import React, { useCallback, useEffect, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Banner,
  BlockStack,
  Button,
  Card,
  InlineStack,
  List,
  Page,
  Text,
} from '@shopify/polaris';
import {
  OfferLevelDto,
  OfferTierDto,
  OfferTieredDiscountTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import './Tiers.scss';
import { ConfigGoalsAndDiscounts } from './components/ConfigGoalsAndDiscounts/ConfigGoalsAndDiscounts';
import { OfferTierUpdateDto } from '../Offers/types/OfferTierUpdateDto';
import {
  OfferTieredDiscountTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { setCurrentConfigPage } from 'core/store/offersWizardSlice';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';

export type TiersProps = {
  tiers: OfferTierDto[];
  level?: OfferLevelDto;
  offerType: OfferTypeDtoEnum;
  discountType?: OfferTieredDiscountTypeDto;
  configureComponent: boolean;
  onFormValidityChange(formIsValid: boolean): void;
  setCurrentTiers: (data: OfferTierDto[]) => void;
  setConfigureComponent: (data: boolean) => void;
  onTierUpdate(data: OfferTierUpdateDto): void;
  onTierDelete(index: number): void;
};

export const Tiers: React.FC<TiersProps> = (props) => {
  const {
    tiers,
    offerType,
    discountType,
    level,
    configureComponent,
    onFormValidityChange,
    setCurrentTiers,
    setConfigureComponent,
    onTierUpdate,
    onTierDelete,
  } = props;

  const [i18n] = useI18n();

  const dispatch = useAppDispatch();

  const isDebugOrLocal = useIsDebugOrLocal();

  const { formatValue } = useNumericFormatter();

  const [configuredTiers, setConfiguredTiers] = useState<any[]>(tiers || []);

  useEffect(() => {
    setCurrentTiers(tiers || []);
  }, [tiers, discountType]);

  useEffect(() => {
    setConfiguredTiers(tiers?.map((tier, index) => handleTierSet(tier, index)));
  }, [tiers, discountType]);

  const handleTierSet = useCallback(
    (data: OfferTierDto, index: number) => {
      const buySpend = ![
        OfferTypeDtoEnum.TIERED_BOGO,
        OfferTypeDtoEnum.VOLUME_DISCOUNT,
      ].includes(offerType)
        ? i18n.translate('Spends')
        : i18n.translate('Buys');

      const productsAmount = () => {
        if (
          offerType === OfferTypeDtoEnum.TIERED_BOGO ||
          offerType === OfferTypeDtoEnum.VOLUME_DISCOUNT
        ) {
          if (
            (tiers[index + 1]?.requirementWholeValue || 1) -
              (data?.requirementWholeValue || 1) ===
              1 ||
            (tiers[index + 1]?.requirementPreciseValue || 1) -
              (data?.requirementPreciseValue || 1) ===
              1
          ) {
            return i18n.translate('Items', {
              amount:
                data?.requirementWholeValue ||
                data?.requirementPreciseValue ||
                1,
            });
          }
          if (index === 0 && tiers) {
            return i18n.translate('BetweenItems', {
              start:
                data?.requirementWholeValue ||
                data?.requirementPreciseValue ||
                1,
              end:
                (+(
                  tiers[index + 1]?.requirementWholeValue as unknown as number
                )?.toFixed(2) ||
                  +(
                    tiers[index + 1]
                      ?.requirementPreciseValue as unknown as number
                  )?.toFixed(2) ||
                  1) - 1,
            });
          }
          if (index === tiers.length - 1 && tiers) {
            return i18n.translate('BetweenItemsMore', {
              start:
                data?.requirementWholeValue ||
                data?.requirementPreciseValue ||
                1,
            });
          }
          if (
            (tiers[index + 1]?.requirementWholeValue || 1) -
              (data?.requirementWholeValue || 1) >
              1 ||
            (tiers[index + 1]?.requirementPreciseValue || 1) -
              (data?.requirementPreciseValue || 1) >
              1
          ) {
            return i18n.translate('BetweenItems', {
              start:
                data?.requirementWholeValue ||
                data?.requirementPreciseValue ||
                1,
              end:
                (+(
                  tiers[index + 1]?.requirementWholeValue as unknown as number
                )?.toFixed(2) ||
                  +(
                    tiers[index + 1]
                      ?.requirementPreciseValue as unknown as number
                  )?.toFixed(2) ||
                  1) - 1,
            });
          }
        } else if (offerType === OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y) {
          if (index === 0 && tiers) {
            return i18n.translate('BetweenAmount', {
              start: formatValue(
                data?.requirementPreciseValue ||
                  data?.requirementWholeValue ||
                  1,
                ChartuUnitOfMeasurementEnum.CURRENCY
              ),
              end: formatValue(
                (+(
                  tiers[index + 1]?.requirementPreciseValue as number
                )?.toFixed(2) || 1) - 0.01,
                ChartuUnitOfMeasurementEnum.CURRENCY
              ),
            });
          }
          if (index === tiers.length - 1 && tiers) {
            return i18n.translate('BetweenAmountMore', {
              start: formatValue(
                data?.requirementPreciseValue ||
                  data?.requirementWholeValue ||
                  1,
                ChartuUnitOfMeasurementEnum.CURRENCY
              ),
            });
          }
          if (
            (tiers[index + 1]?.requirementPreciseValue || 1) -
              (data?.requirementPreciseValue || 1) >
              1 ||
            (tiers[index + 1]?.requirementWholeValue || 1) -
              (data?.requirementWholeValue || 1) >
              1
          ) {
            return i18n.translate('BetweenAmount', {
              start:
                data?.requirementPreciseValue ||
                data?.requirementWholeValue ||
                1,
              end:
                (+(
                  tiers[index + 1]?.requirementPreciseValue as number
                )?.toFixed(2) || 1) - 0.01,
            });
          }
        }
      };

      return i18n.translate('TierItem', {
        number: index + 1,
        percentage: formatValue(
          data.entitledWholeValue || data.entitledPreciseValue,
          discountType === OfferTieredDiscountTypeDtoEnum.PERCENTAGE
            ? ChartuUnitOfMeasurementEnum.PERCENTAGE
            : ChartuUnitOfMeasurementEnum.CURRENCY
        ),
        buySpend: buySpend,
        value: productsAmount(),
        end: i18n.translate(
          [
            OfferTypeDtoEnum.TIERED_BOGO,
            OfferTypeDtoEnum.VOLUME_DISCOUNT,
          ].includes(offerType)
            ? 'SelectedProducts'
            : 'TierEnd'
        ),
      });
    },
    [tiers, discountType, offerType]
  );

  useEffect(() => {
    configureComponent && dispatch(setCurrentConfigPage('tiers'));
    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent]);

  return (
    <>
      {!configureComponent ? (
        <Card roundedAbove='sm' padding='400'>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('Tier.Title')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('Tier.Subtitle')}
              </Text>
            </BlockStack>
            {tiers.length >= 5 && (
              <Banner tone='warning'>
                {i18n.translate('Tier.WarningBanner')}
              </Banner>
            )}
            <Card roundedAbove='sm' padding='400'>
              <InlineStack align='space-between' wrap={false}>
                <BlockStack>
                  {!configuredTiers.length ? (
                    <Text as='p' tone='subdued'>
                      {i18n.translate('NoTiersConfigured')}
                    </Text>
                  ) : (
                    <List type='bullet'>
                      {configuredTiers.map((tier, index) => (
                        <List.Item key={index}>
                          <span
                            dangerouslySetInnerHTML={{ __html: tier }}
                          ></span>
                        </List.Item>
                      ))}
                    </List>
                  )}
                </BlockStack>
                <BlockStack align='center'>
                  <Button
                    variant='plain'
                    onClick={async () => {
                      !isDebugOrLocal &&
                        (await shopify.saveBar.leaveConfirmation());
                      setConfigureComponent(true);
                    }}
                  >
                    {i18n.translate('Configure')}
                  </Button>
                </BlockStack>
              </InlineStack>
            </Card>
          </BlockStack>
        </Card>
      ) : (
        <Page
          backAction={{
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              setConfigureComponent(false);
            },
          }}
          title='Configure tiers'
        >
          <ConfigGoalsAndDiscounts
            tiers={tiers}
            level={level}
            offerType={offerType}
            discountType={discountType}
            onTierUpdate={onTierUpdate}
            onTierDelete={onTierDelete}
            onFormValidityChange={onFormValidityChange}
          />
        </Page>
      )}
    </>
  );
};
