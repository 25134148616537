import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferFreeGiftRuleDto,
  OfferGiftSpecificationDto,
  OfferGiftTypeDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Button,
  BlockStack,
  InlineStack,
  Page,
  Text,
  Box,
  Card,
  Bleed,
  Icon,
  ChoiceList,
  List,
} from '@shopify/polaris';
import { GiftCardIcon, AlertTriangleIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import './FreeGiftSection.scss';
import { FreeGiftFirstLineContent } from './components/FreeGiftFirstLineContent/FreeGiftFirstLineContent';
import {
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
  SearchFieldWithGrayBox,
} from 'core/components/SearchFieldWithGrayBoxOfferWizard';

import {
  OfferDefaultGiftRuleDtoEnum,
  OfferGiftTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { setCurrentConfigPage } from 'core/store/offersWizardSlice';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import {
  boldMessageParts,
  generateMessage,
  SectionTypeEnum,
} from '../CartRules/utils/utils';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';

export type FreeGiftSectionProps = {
  specification?: OfferGiftSpecificationDto;
  onFormValidityChange(formIsValid: boolean): void;
  onSpecificationUpdate: (specification: OfferGiftSpecificationDto) => void;
  setConfigureComponent: (data: boolean) => void;
  configureComponent: boolean;
};

type FormFields = {
  maximumFreeGiftsEnable: boolean;
  maximumFreeGiftsValue: number;
};

const initialSpecificationValues: OfferGiftSpecificationDto = {
  advancedOptions: {
    addGiftAutomaticallyWhenCriteriaMet: true,
    removeExcessGiftsAutomatically: false,
    removeGiftAutomaticallyWhenCriteriaNotMet: true,
  },
  rule: {
    amount: 1,
    collections: null,
    products: null,
    productVariants: null,
    type: OfferGiftTypeDtoEnum.COLLECTIONS,
    defaultGiftRule: OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER,
  },

  limit: {
    enabled: true,
    value: 1,
  },
};

export type FreeGiftSectionValidityBlocksProps = {
  isTypeSwitcherBlockValid: boolean;
  isSearchFieldBlockValid: boolean;
  isMaximumGiftFieldBlockValid: boolean;
};

export type ComboBoxOptionsProps = {
  value: string;
  label: string;
};

export const FreeGiftSection: React.FC<FreeGiftSectionProps> = (props) => {
  const {
    specification,
    onFormValidityChange,
    onSpecificationUpdate,
    configureComponent,
    setConfigureComponent,
  } = props;

  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const isDebugOrLocal = useIsDebugOrLocal();

  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();

  const [currentSpecification, setCurrentSpecification] =
    useState<OfferGiftSpecificationDto>(
      specification || initialSpecificationValues
    );
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [validityBlocks, setValidityBlocks] =
    useState<FreeGiftSectionValidityBlocksProps>({
      isTypeSwitcherBlockValid: false,
      isSearchFieldBlockValid: true,
      isMaximumGiftFieldBlockValid: false,
    });
  const [currentSelections, setCurrentSelections] = useState<string>('');

  useEffect(() => {
    if (configureComponent && !currentSpecification.rule) {
      updateCurrentSpecification('amount', 1);
      updateCurrentSpecification('type', OfferGiftTypeDtoEnum.PRODUCT_VARIANT);
    }
  }, [configureComponent, currentSpecification.rule]);

  const form = yup.object({
    maximumFreeGiftsEnable: yup.boolean(),
    maximumFreeGiftsValue: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when('maximumFreeGiftsEnable', {
        is: (value: boolean) => value,
        then: yup
          .number()
          .typeError(i18n.translate('FreeGift.RequiredError'))
          .min(1, i18n.translate('FreeGift.MaximumError'))
          .integer()
          .required(),
      }),
  });

  const { formState, watch, trigger } = useForm<FormFields>({
    defaultValues: {
      maximumFreeGiftsEnable: currentSpecification.limit?.enabled,
      maximumFreeGiftsValue: currentSpecification.limit?.value,
    },
    mode: 'onChange',
    resolver: yupResolver(form),
  });

  const resourceType = useMemo(() => {
    switch (currentSpecification.rule?.type) {
      case OfferGiftTypeDtoEnum.COLLECTIONS:
        return ShopifyObjectTypeDtoEnum.COLLECTION;
      case OfferGiftTypeDtoEnum.PRODUCT:
        return ShopifyObjectTypeDtoEnum.PRODUCT;
      case OfferGiftTypeDtoEnum.PRODUCT_VARIANT:
        return ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT;
      default:
        return null;
    }
  }, [currentSpecification.rule?.type]);

  const currentRuleType = useMemo(() => {
    switch (currentSpecification.rule?.type) {
      case OfferGiftTypeDtoEnum.COLLECTIONS:
        return 'collections';
      case OfferGiftTypeDtoEnum.PRODUCT:
        return 'products';
      case OfferGiftTypeDtoEnum.PRODUCT_VARIANT:
        return 'productVariants';
      default:
        return null;
    }
  }, [currentSpecification.rule?.type]);

  const productCurrentList = useMemo(
    () =>
      currentRuleType
        ? ((currentSpecification.rule?.[
            currentRuleType as keyof typeof currentSpecification.rule
          ] || []) as ShopifyObjectDto[])
        : null,
    [currentSpecification.rule]
  );

  const selectList: ShopifyObjectDto[] = useMemo(() => {
    if (
      currentSpecification?.rule?.type === OfferGiftTypeDtoEnum.PRODUCT_VARIANT
    ) {
      return currentSpecification?.rule?.productVariants?.length
        ? currentSpecification.rule.productVariants
        : [];
    } else if (
      currentSpecification?.rule?.type === OfferGiftTypeDtoEnum.COLLECTIONS
    ) {
      return currentSpecification?.rule?.collections?.length
        ? currentSpecification.rule.collections
        : [];
    } else {
      return currentSpecification?.rule?.products?.length
        ? currentSpecification.rule.products
        : [];
    }
  }, [currentSpecification]);

  const isValid = useMemo(
    () => Object.values(validityBlocks).every((validity: boolean) => validity),
    [validityBlocks]
  );

  const updateValidityBlock = useCallback(
    (field: keyof FreeGiftSectionValidityBlocksProps, value: boolean) => {
      setValidityBlocks((prevState: FreeGiftSectionValidityBlocksProps) => ({
        ...prevState,
        [field]: value,
      }));
    },
    [setValidityBlocks]
  );

  const updateCurrentSpecification = useCallback(
    (
      field: keyof OfferFreeGiftRuleDto | keyof OfferGiftSpecificationDto,
      data:
        | OfferGiftSpecificationDto[keyof OfferGiftSpecificationDto]
        | number
        | ShopifyObjectDto[]
        | OfferGiftTypeDto
        | null
        | OfferDefaultGiftRuleDtoEnum
    ) => {
      if ((field as string) === 'limit') {
        setCurrentSpecification((prevState: OfferGiftSpecificationDto) => ({
          ...prevState,
          [field]: data,
        }));
      } else {
        setCurrentSpecification((prevState: OfferGiftSpecificationDto) => ({
          ...prevState,
          rule: {
            ...prevState.rule,
            [field]: data,
          },
        }));
      }
      setIsChanged(true);
    },
    []
  );

  const handleSelectChange = useCallback(
    (list: ResourceSelectionProps[]) => {
      const field =
        currentSpecification?.rule?.type ===
        OfferGiftTypeDtoEnum.PRODUCT_VARIANT
          ? 'productVariants'
          : currentSpecification?.rule?.type ===
            OfferGiftTypeDtoEnum.COLLECTIONS
          ? 'collections'
          : 'products';
      if (list.length) {
        const data: any =
          currentSpecification?.rule?.type ===
          OfferGiftTypeDtoEnum.PRODUCT_VARIANT
            ? formateList(list, 'VARIANTS')
            : formateList(list);

        updateCurrentSpecification(field, data);
      }
    },
    [currentSpecification, updateCurrentSpecification, formateList]
  );

  const onSearchFieldValidityChange = useCallback((formIsInvalid: boolean) => {
    updateValidityBlock('isSearchFieldBlockValid', !formIsInvalid);
  }, []);

  useEffect(() => {
    if (isChanged) {
      onSpecificationUpdate(currentSpecification);
      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    onFormValidityChange(isValid);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        updateCurrentSpecification(
          'amount',
          value.maximumFreeGiftsValue as number
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, updateCurrentSpecification]);

  useEffect(() => {
    updateValidityBlock('isMaximumGiftFieldBlockValid', formState.isValid);
  }, [formState.isValid]);

  useEffect(() => {
    trigger('maximumFreeGiftsValue');
  }, [currentSpecification.limit?.enabled, trigger]);

  useEffect(() => {
    configureComponent && dispatch(setCurrentConfigPage('specification'));
    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent]);

  useEffect(() => {
    if (!configureComponent && resourceType && productCurrentList?.length) {
      getLookupShopifyDetailedObjectsDetails({
        type: resourceType,
        objects: productCurrentList,
      }).then((res) => {
        setCurrentSelections(
          headerTitle(res as ResourceSelectionProps[], resourceType, i18n)
        );
      });
    }
  }, [resourceType, productCurrentList]);

  useEffect(() => {
    const condition =
      currentSpecification.rule?.type ===
        OfferGiftTypeDtoEnum.PRODUCT_VARIANT &&
      currentSpecification.rule.productVariants?.length === 1;
    if (condition) {
      updateCurrentSpecification(
        'defaultGiftRule',
        OfferDefaultGiftRuleDtoEnum.AUTOMATIC_SELECTED_VARIANT
      );
    }
    if (
      !condition &&
      currentSpecification.rule?.defaultGiftRule ===
        OfferDefaultGiftRuleDtoEnum.AUTOMATIC_SELECTED_VARIANT
    ) {
      updateCurrentSpecification(
        'defaultGiftRule',
        OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER
      );
    }
  }, [currentSpecification.rule]);

  return (
    <>
      {!configureComponent ? (
        <Card roundedAbove='sm' padding='400'>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('FreeGift.Title')}
              </Text>
              <Text as='span' tone='subdued'>
                {i18n.translate('FreeGift.Subtitle')}
              </Text>
            </BlockStack>
            <Card roundedAbove='sm' padding='400'>
              <InlineStack
                align='space-between'
                wrap={false}
                blockAlign='center'
              >
                <InlineStack wrap={false} gap='200' blockAlign='center'>
                  {currentSpecification.rule?.type ? (
                    <>
                      <div style={{ width: 20, height: 20 }}>
                        <Icon source={GiftCardIcon} tone='base' />
                      </div>
                      {boldMessageParts(
                        generateMessage(
                          SectionTypeEnum.GIFT_SECTION,
                          currentSpecification,
                          0,
                          currentSelections,
                          i18n
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <div style={{ width: 20, height: 20 }}>
                        <Icon source={AlertTriangleIcon} tone='warning' />
                      </div>
                      <Text as='p' tone='caution'>
                        {i18n.translate('FreeGift.NoGiftConfigured')}
                      </Text>
                    </>
                  )}
                </InlineStack>
                <BlockStack align='center'>
                  <Button
                    variant='plain'
                    onClick={async () => {
                      !isDebugOrLocal &&
                        (await shopify.saveBar.leaveConfirmation());
                      setConfigureComponent(true);
                    }}
                  >
                    {currentSpecification.rule?.type
                      ? i18n.translate('FreeGift.Configure')
                      : i18n.translate('FreeGift.Select')}
                  </Button>
                </BlockStack>
              </InlineStack>
              {currentSpecification.rule?.defaultGiftRule && (
                <div className='bullet'>
                  <List>
                    <List.Item>
                      {i18n.translate(
                        `FreeGift.DefaultGiftSection.${currentSpecification.rule?.defaultGiftRule}`
                      )}
                    </List.Item>
                  </List>
                </div>
              )}
            </Card>
          </BlockStack>
        </Card>
      ) : (
        <Page
          backAction={{
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              setConfigureComponent(false);
            },
          }}
          title={i18n.translate('FreeGift.Modal.Title')}
          subtitle={i18n.translate('FreeGift.Modal.Subtitle')}
        >
          <BlockStack gap='600'>
            <Card>
              <BlockStack gap='400'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('FreeGift.Modal.FreeGift')}
                </Text>
                <FreeGiftFirstLineContent
                  updateCurrentSpecification={updateCurrentSpecification}
                  updateValidityBlock={updateValidityBlock}
                  type={
                    currentSpecification?.rule?.type ||
                    OfferGiftTypeDtoEnum.PRODUCT_VARIANT
                  }
                  amount={currentSpecification?.rule?.amount}
                />
                <SearchFieldWithGrayBox
                  selectList={(selectList as ResourceSelectionProps[]) || []}
                  resourceType={
                    currentSpecification?.rule?.type ===
                    OfferGiftTypeDtoEnum.COLLECTIONS
                      ? GrayBoxResourceTypeEnum.Collection
                      : GrayBoxResourceTypeEnum.Product
                  }
                  productVariantId={
                    currentSpecification.rule?.productVariants?.length
                      ? (currentSpecification.rule?.productVariants[0]
                          .productId as string)
                      : ''
                  }
                  showVariants={
                    currentSpecification?.rule?.type ===
                    OfferGiftTypeDtoEnum.PRODUCT_VARIANT
                  }
                  onSelectedChange={handleSelectChange}
                  onFormValidityChange={onSearchFieldValidityChange}
                  // preventFromOpening={
                  //   currentSpecification?.rule?.type ===
                  //     OfferGiftTypeDtoEnum.PRODUCT_VARIANT && preventFromOpening
                  // }
                  isFreeGiftSearchField
                />
                <Bleed marginInline='400' marginBlockEnd='400'>
                  <Box padding='400' background='bg-surface-secondary'>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('FreeGift.Modal.Footer')}
                    </Text>
                  </Box>
                </Bleed>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap='400'>
                <BlockStack gap='100'>
                  <Text as='h2' variant='headingSm'>
                    {i18n.translate('FreeGift.DefaultGiftSection.Title')}
                  </Text>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('FreeGift.DefaultGiftSection.Description')}
                  </Text>
                </BlockStack>
                <ChoiceList
                  title=''
                  titleHidden
                  choices={[
                    {
                      label: i18n.translate(
                        `FreeGift.DefaultGiftSection.${OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER}`
                      ),
                      value: OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER,
                      disabled:
                        currentSpecification.rule?.type ===
                          OfferGiftTypeDtoEnum.PRODUCT_VARIANT &&
                        currentSpecification.rule.productVariants?.length === 1,
                    },
                    {
                      label: i18n.translate(
                        `FreeGift.DefaultGiftSection.${OfferDefaultGiftRuleDtoEnum.AUTOMATIC_FIRST_VARIANT}`
                      ),
                      value:
                        OfferDefaultGiftRuleDtoEnum.AUTOMATIC_FIRST_VARIANT,
                      disabled:
                        currentSpecification.rule?.type ===
                          OfferGiftTypeDtoEnum.PRODUCT_VARIANT &&
                        currentSpecification.rule.productVariants?.length === 1,
                    },
                    {
                      label: i18n.translate(
                        `FreeGift.DefaultGiftSection.${OfferDefaultGiftRuleDtoEnum.AUTOMATIC_SELECTED_VARIANT}`
                      ),
                      value:
                        OfferDefaultGiftRuleDtoEnum.AUTOMATIC_SELECTED_VARIANT,
                      disabled:
                        currentSpecification.rule?.type !==
                          OfferGiftTypeDtoEnum.PRODUCT_VARIANT ||
                        (currentSpecification?.rule?.productVariants?.length ||
                          0) > 1,
                    },
                  ]}
                  selected={
                    currentSpecification.rule?.defaultGiftRule
                      ? [currentSpecification.rule?.defaultGiftRule]
                      : []
                  }
                  onChange={(selected) =>
                    updateCurrentSpecification(
                      'defaultGiftRule',
                      selected[0] as OfferDefaultGiftRuleDtoEnum
                    )
                  }
                />
              </BlockStack>
            </Card>
          </BlockStack>
        </Page>
      )}
    </>
  );
};
