import React, { useEffect } from 'react';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { useAppSelector, usePageName } from 'core/hooks';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Promotions } from '../features/promotions';
import { PromotionOverview } from 'features/promotions/components';
import { SelectedOfferTypeSettings } from 'features/promotions/components/SelectedOfferTypeSettings/SelectedOfferTypeSettings';
import { PromotionSettings } from 'features/promotions/components/PromotionSettings/PromotionSettings';
import { EditPresetTypeEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { WidgetEditor } from './WidgetEditor';
import {
  SettingsDiscountLinks,
  SettingsPromotionCodes,
} from 'features/promotions/components/PromotionSettings/components';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { PromotionPageSubPathsEnums } from './enums/PagesEnums';

export const PromotionsPage = () => {
  usePageName(NavTabs.Promotions);
  const location = useLocation();
  const navigate = useNavigate();

  const { lastSelectedOffer, selectedOfferType, resetTemplate } =
    useAppSelector((state) => state.offersWizard);

  useEffect(() => {
    if (
      location.pathname.includes('change-offer-template') &&
      !selectedOfferType?.type &&
      !lastSelectedOffer.type
    ) {
      const newUrl = location.pathname.replace(
        'change-offer-template',
        'create-offer-template'
      );
      navigate(newUrl);
    }
  }, [location.pathname, selectedOfferType?.type, lastSelectedOffer.type]);

  return (
    <div
      style={{
        width: '100%',
        height: location.pathname.includes(NavPaths.ConfigureWidgets)
          ? '100vh'
          : 'auto',
        overflow: location.pathname.includes('create-offer-template')
          ? 'unset'
          : 'hidden',
      }}
    >
      <Routes>
        <Route index element={<Promotions />} />
        <Route
          path='/:promotionId'
          key='promotion-overview'
          element={<PromotionOverview />}
        />
        <Route path='/:promotionId'>
          <Route
            path={PromotionPageSubPathsEnums.Settings}
            element={
              <PromotionSettings key={PromotionPageSubPathsEnums.Settings} />
            }
          />
          <Route
            path={PromotionPageSubPathsEnums.SettingsFilters}
            element={
              <PromotionSettings
                key={PromotionPageSubPathsEnums.SettingsFilters}
                onlyFilters
              />
            }
          />
          <Route
            path={PromotionPageSubPathsEnums.SettingsDiscountLinks}
            element={<SettingsDiscountLinks />}
          />
          <Route
            path={PromotionPageSubPathsEnums.SettingsPromotionCodes}
            element={<SettingsPromotionCodes />}
          />

          <Route
            path={NavPaths.ConfigureWidgets}
            element={
              <WidgetEditor
                skeletonType={EditPresetTypeEnum.PROMOTION_EDITOR}
              />
            }
          />
          <Route
            path={`${NavPaths.ConfigureWidgets}/:presetId`}
            element={
              <WidgetEditor skeletonType={EditPresetTypeEnum.PRESET_EDITOR} />
            }
          />
          <Route
            key='select-offer-for-existing-promotion'
            path={`${PromotionPageSubPathsEnums.CreateOfferTemplate}/:id`}
            element={<SelectedOfferTypeSettings key={resetTemplate} />}
          />
        </Route>
      </Routes>
    </div>
  );
};
