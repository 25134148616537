import React, { useCallback, useMemo } from 'react';
import { CheckIcon, InfoIcon } from '@shopify/polaris-icons';
import {
  Bleed,
  Box,
  Card,
  DataTable,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PlanDtoRead } from 'core/api/adminSettings/adminSettingsApi';
import { Tooltip } from 'core/components';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
type PlanFeaturesListProps = {
  comparisonPlans: PlanDtoRead[];
};
export const PlanFeaturesList: React.FC<PlanFeaturesListProps> = ({
  comparisonPlans,
}) => {
  const [i18n] = useI18n();
  const { formatValue } = useNumericFormatter();

  const showCheckIcon = useCallback(
    (value?: boolean) => (value ? <Icon source={CheckIcon} /> : '-'),
    []
  );

  const pricingRows = useMemo(() => {
    const getRowData = (row: 1 | 2 | 3) =>
      comparisonPlans?.map((plan) => {
        return (
          <Text key={plan.level} alignment='center' as='p'>
            {row === 1 &&
              `${i18n.translate('From')} $${
                plan.pricing?.intervalPrices?.regular?.monthlyPlanPerMonthPrice
              } USD/mo`}{' '}
            {row === 2 &&
              `${i18n.translate('From')} $${
                plan.pricing?.intervalPrices?.regular?.annualPlanPerMonthPrice
              } USD/mo`}{' '}
            {row === 3 &&
              `$${plan.pricing?.intervalPrices?.regular?.annualOverageUnitPrice}`}
          </Text>
        );
      }) || [];

    return [
      [i18n.translate('BilledMonthly'), ...getRowData(1)],
      [i18n.translate('BilledAnnuallySave'), ...getRowData(2)],
      [i18n.translate('AdditionalOrderFee'), ...getRowData(3)],
    ];
  }, [comparisonPlans, i18n]);

  const supportRows = useMemo(() => {
    const getRowData = (row: 1 | 2 | 3) =>
      comparisonPlans?.map((plan) => {
        const { prioritySupport, roundTheClockSupport, call, chat, email } =
          plan.comparisonDetails?.support || {};
        return (
          <Text key={plan.level} alignment='center' as='p'>
            {row === 1 &&
              i18n.translate(prioritySupport ? 'Priority' : 'Standart')}
            {row === 2 && (roundTheClockSupport ? '24/7' : '8/7')}
            {row === 3 &&
              `${chat ? i18n.translate('Chat') : ''}  ${
                email ? i18n.translate('Email') : ''
              }  ${call ? i18n.translate('Phone') : ''}`}
          </Text>
        );
      }) || [];

    return [
      [i18n.translate('Support'), ...getRowData(1)],
      [i18n.translate('Timezone'), ...getRowData(2)],
      [i18n.translate('Channel'), ...getRowData(3)],
    ];
  }, [comparisonPlans, i18n]);

  const accountRows = useMemo(() => {
    const rowData =
      comparisonPlans?.map((plan) => {
        return (
          <Text key={plan.level} alignment='center' as='p'>
            {plan.comparisonDetails?.account?.expansionStoresSupported
              ? i18n.translate('PercentageDiscount', {
                  percentage: formatValue(
                    plan.comparisonDetails?.account?.expansionStoresDiscount,
                    ChartuUnitOfMeasurementEnum.PERCENTAGE
                  ),
                })
              : '-'}
          </Text>
        );
      }) || [];

    return [[i18n.translate('ExpansionStores'), ...rowData]];
  }, [comparisonPlans, i18n]);

  const getBooleanPlanRows = useCallback((planArray: any, field: any) => {
    const keyList = Object.keys(
      planArray?.[0]?.comparisonDetails?.[field] || {}
    );
    return keyList.map((key) => [
      i18n.translate(`${key}`),
      ...(planArray?.map((plan: any) => {
        const value = plan?.comparisonDetails?.[field]?.[key];
        return (
          <Text key={plan.level} alignment='center' as='p'>
            {showCheckIcon(value)}
          </Text>
        );
      }) || []),
    ]);
  }, []);

  const subheaderRow = useCallback(
    (title: string, tooltip?: string) => [
      <Bleed marginBlock='200' marginInline='300' key='efefe'>
        <Box
          paddingBlock='200'
          paddingInline='300'
          background='bg-surface-tertiary'
        >
          <InlineStack gap='100'>
            <Text as='p' fontWeight='semibold'>
              {title}
            </Text>
            {tooltip && (
              <Tooltip content={tooltip}>
                <Icon tone='subdued' source={InfoIcon} />
              </Tooltip>
            )}
          </InlineStack>
        </Box>
      </Bleed>,
    ],
    []
  );

  const allRows = useMemo(
    () => [
      subheaderRow(i18n.translate('Pricing')),
      ...pricingRows,
      subheaderRow(i18n.translate('Reporting')),
      ...getBooleanPlanRows(comparisonPlans, 'reporting'),
      subheaderRow(i18n.translate('NinjaOffersAndSpecialDeals')),
      ...getBooleanPlanRows(comparisonPlans, 'specialOffers'),
      subheaderRow(i18n.translate('NinjaGift')),
      ...getBooleanPlanRows(comparisonPlans, 'gift'),
      subheaderRow(i18n.translate('NinjaCart')),
      ...getBooleanPlanRows(comparisonPlans, 'ninjaCart'),
      subheaderRow(i18n.translate('NinjaCartPro')),
      ...getBooleanPlanRows(comparisonPlans, 'ninjaCartPro'),
      subheaderRow(
        i18n.translate('NinjaXUpsell'),
        i18n.translate('NinjaXUpsellTooltip')
      ),
      ...getBooleanPlanRows(comparisonPlans, 'xUpsell'),
      subheaderRow(i18n.translate('NinjaWidgets')),
      ...getBooleanPlanRows(comparisonPlans, 'widgets'),
      subheaderRow(i18n.translate('Platform')),
      ...getBooleanPlanRows(comparisonPlans, 'platform'),
      subheaderRow(i18n.translate('Account')),
      ...accountRows,
      subheaderRow(i18n.translate('Support')),
      ...supportRows,
    ],
    [pricingRows, comparisonPlans]
  );

  const headings = useMemo(
    () => [
      i18n.translate('Feature'),
      ...(comparisonPlans
        ?.filter((plan) => plan.name !== 'Enterprise')
        .map((plan) => (
          <Text fontWeight='medium' key={plan.level} as='p' alignment='center'>
            {plan.name}
          </Text>
        )) || []),
    ],
    [i18n]
  );
  return (
    <div className='PlanFeaturesList'>
      <Card>
        <Bleed marginInline='400' marginBlock='400'>
          <DataTable
            columnContentTypes={['text', 'text', 'text', 'text', 'text']}
            headings={headings}
            rows={allRows}
          />
        </Bleed>
      </Card>
    </div>
  );
};
