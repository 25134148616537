import {
  OfferCombinationsDto,
  OfferFreeShippingDto,
  OfferShippingDiscountSpecificationDto,
  OptionDtoOfferMinimumSpendDto,
  OfferCartRulesDto,
  OptionDtoShippingCountryDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FreeShippingForSomeZonesOfferTabsEnum,
  PerProductShippingFeeOfferTabsEnum,
} from '../../../enums/OfferTypeTabs';
import { Combinations } from '../../Combinations/Combinations';
import { ShippingDeliveryMethods } from '../../ShippingDeliveryMethods/ShippingDeliveryMethods';
import { ShippingDiscount } from '../../ShippingDiscount/ShippingDiscount';
import { DiscountValueTypeEnum } from '../../../enums/DiscountValueType';
import { NumberFormatValues } from 'react-number-format';
import { BlockStack, Page } from '@shopify/polaris';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';
import { parseCurrencyString } from 'core/utils';

export const FreeShippingPercentage: React.FC<OfferParentComponentProps> = (
  props
) => {
  const { offerTemplate, handleOfferDataUpdate, savedData, offerType } = props;

  const { validityChangeHandler } = useOfferFormValidity();
  const [i18n] = useI18n();

  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const { currentCombination } = useAppSelector((state) => state.offersWizard);

  const [offerData, setOfferData] = useState<OfferFreeShippingDto | null>(
    offerTemplate?.template as OfferFreeShippingDto
  );
  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const discountDataUpdate = useCallback(
    (data: NumberFormatValues) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        discountSpecification: {
          percentageDiscount: parseCurrencyString(data.value),
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const shippingCountriesDataUpdate = useCallback(
    (data: OptionDtoShippingCountryDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        discountSpecification: {
          ...prevState?.discountSpecification,
          shippingCountries: data,
        },
      }));
    },
    [setOfferData]
  );

  const applyToDataUpdate = useCallback(
    (data: OfferShippingDiscountSpecificationDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        discountSpecification: {
          ...prevState?.discountSpecification,
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    setOfferData((prevState: OfferFreeShippingDto | null) => ({
      ...prevState,
      combinationType: currentCombination.currentCombinationType,
    }));
  }, [currentCombination.currentCombinationType]);

  return (
    <>
      {configureCartRules && (
        <CartRules
          savedData={(savedData?.template as OfferFreeShippingDto)?.cartRules}
          onFormValidityChange={validityChangeHandler(
            PerProductShippingFeeOfferTabsEnum.CartRules
          )}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          configureComponent={configureCartRules}
          groupIndex={groupIndex}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {!configureCartRules && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={
                (offerTemplate?.template as OfferFreeShippingDto)
                  .combinationType
              }
            />
            <ShippingDiscount
              suffix='%'
              activeDiscountValueType={DiscountValueTypeEnum.Percentage}
              offerType={offerType}
              setTextFieldValue={discountDataUpdate}
              textFieldValue={{
                value: `${
                  offerData?.discountSpecification?.percentageDiscount || 0
                }`,
                formattedValue: `${
                  offerData?.discountSpecification?.percentageDiscount || 0
                }`,
                floatValue:
                  offerData?.discountSpecification?.percentageDiscount || 0,
              }}
            />
            <ShippingDeliveryMethods
              onValidityStateChange={validityChangeHandler(
                FreeShippingForSomeZonesOfferTabsEnum.Shipping
              )}
              specification={
                (offerTemplate?.template as OfferFreeShippingDto)
                  ?.discountSpecification
              }
              onApplyToDataUpdate={applyToDataUpdate}
              onShippingCountriesDataUpdate={shippingCountriesDataUpdate}
            />
            <CartRules
              savedData={
                (savedData?.template as OfferFreeShippingDto)?.cartRules
              }
              onFormValidityChange={validityChangeHandler(
                PerProductShippingFeeOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferFreeShippingDto)
                    ?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
