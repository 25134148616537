import React, { useCallback, useMemo, useState } from 'react';
import './OverviewPromotionSettings.scss';
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Text,
  List,
  TextField,
  Bleed,
  Box,
  Icon,
} from '@shopify/polaris';
import {
  PromotionOverviewSettingsDto,
  TriggerMaximumUsesFilterDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionTypeDtoEnum,
  TriggerCountdownClockEndModeDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useNavigate } from 'react-router-dom';
import { EditIcon, InfoIcon } from '@shopify/polaris-icons';
import { CopyIcon, Tooltip } from 'core/components';
import { PromotionPageSubPathsEnums } from 'pages/enums/PagesEnums';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import { FilterSettingsSummary } from 'features/promotions/components/PromotionSettings/components/SettingsFilters/components/FilterSettingsSummary/FilterSettingsSummary';
import ChangeTriggerModal from 'features/promotions/components/PromotionSettings/components/SettingTrigger/components/ChangeTriggerModal/ChangeTriggerModal';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import { useAppDispatch } from 'core/hooks';
import {
  setOverviewFilters,
  setSettingsAnchor,
} from 'core/store/promotionsSlice';
import BudgetModal from 'features/promotions/components/PromotionSettings/components/SettingBudget/components/BudgetModal/BudgetModal';

type OverviewPromotionSettingsProps = {
  settings: PromotionOverviewSettingsDto;
  isPromotionEditable: boolean;
  shopifyCodeOfferMissing: boolean;
  refetchPromotionOverview: () => void;
};
export const OverviewPromotionSettings: React.FC<
  OverviewPromotionSettingsProps
> = ({
  settings,
  isPromotionEditable,
  shopifyCodeOfferMissing,
  refetchPromotionOverview,
}) => {
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatValue } = useNumericFormatter();
  const {
    changePromotionTriggerType,
    changePromotionTriggerTypeIsLoading,
    changePromotionBudgetIsLoading,
    changePromotionBudget,
  } = useConfigurePromotions();
  const [triggerModal, setTriggerModal] = useState<boolean>(false);
  const [budgetModal, setBudgetModal] = useState<boolean>(false);

  const shopifyDiscountCode = useMemo(() => {
    if (settings.shopifyDiscountCode) return settings.shopifyDiscountCode;
    if (settings.shopifyDiscountApp) return settings.shopifyDiscountApp;
    if (settings.shopifyDiscountGroup) return settings.shopifyDiscountGroup;
    return null;
  }, [
    settings.shopifyDiscountCode,
    settings.shopifyDiscountGroup,
    settings.shopifyDiscountApp,
  ]);

  const isPromitonSDC = useMemo(
    () => settings.promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE,
    [settings.promotionType]
  );

  const isBudgetConfigured = useMemo(
    () =>
      settings.maximumUses?.limitedUseByAll?.enabled ||
      settings.maximumUses?.orderCap?.enabled ||
      settings.maximumUses?.limitedUsePerCustomerEnabled,
    [settings.maximumUses]
  );

  const truncateLink = useCallback((link: string): string => {
    const tokenPosition = link.indexOf('?token=');
    if (tokenPosition === -1) {
      return link;
    }
    const baseUrl = link.substring(0, 20);
    const tokenPart = link.substring(tokenPosition);
    return `${baseUrl}...${tokenPart}`;
  }, []);

  const toggleTriggerModal = useCallback(
    () => setTriggerModal((prev) => !prev),
    []
  );
  const toggleBudgetModal = useCallback(
    () => setBudgetModal((prev) => !prev),
    []
  );

  const handleSaveTriggerType = useCallback(
    (value: TriggerTypeDtoEnum) => {
      const onSuccess = () => {
        refetchPromotionOverview();
        toggleTriggerModal();
      };
      changePromotionTriggerType(value, onSuccess);
    },
    [toggleTriggerModal]
  );
  const handleSaveBudget = useCallback(
    (value: TriggerMaximumUsesFilterDto) => {
      const onSuccess = () => {
        refetchPromotionOverview();
        toggleBudgetModal();
      };
      changePromotionBudget(value, onSuccess);
    },
    [toggleBudgetModal]
  );

  const getFixedAmountTime = useCallback(() => {
    const { days, hours, minutes } =
      settings.countdownClock?.fixedClockTime || {};
    switch (true) {
      case !!days:
        return days + i18n.translate(days === 1 ? 'Day' : 'Days');
      case !!hours:
        return hours + i18n.translate(hours === 1 ? 'Hour' : 'Hours');
      case !!minutes:
        return minutes + i18n.translate(minutes === 1 ? 'Minute' : 'Minutes');
    }
  }, [settings.countdownClock?.fixedClockTime, i18n]);

  const handleNavigateToSettings = useCallback(
    (element?: string) => {
      navigate(PromotionPageSubPathsEnums.Settings);
      element && dispatch(setSettingsAnchor(element));
    },
    [dispatch]
  );

  const handleNavigateToFilters = useCallback(() => {
    navigate(PromotionPageSubPathsEnums.SettingsFilters);
    dispatch(setOverviewFilters(true));
  }, [dispatch]);

  return (
    <div className='OverviewPromotionSettings'>
      <Card padding='200'>
        <BlockStack>
          <Box padding='200'>
            <InlineStack align='space-between' blockAlign='center'>
              <Text as='h2' fontWeight='semibold'>
                {i18n.translate('Settings')}
              </Text>
              {!isPromitonSDC ? (
                <Button
                  variant='plain'
                  onClick={() => handleNavigateToSettings()}
                  disabled={!isPromotionEditable}
                  id='EditPromotionSettingsButton'
                >
                  {i18n.translate('Manage')}
                </Button>
              ) : (
                <Tooltip content={i18n.translate('NoAddtitionalSettings')}>
                  <Box>
                    <Icon tone='subdued' source={InfoIcon} />
                  </Box>
                </Tooltip>
              )}
            </InlineStack>
          </Box>
          {/* Trigger settings */}
          <div className='SettingHoverableBox'>
            <BlockStack gap='050'>
              <Text as='h2' fontWeight='medium'>
                {i18n.translate('Trigger')}
              </Text>
              <Text as='p'>{i18n.translate(`${settings.triggerType}`)}</Text>
            </BlockStack>
            <div className='EditIcon'>
              <Button
                onClick={toggleTriggerModal}
                variant='tertiary'
                icon={EditIcon}
              />
            </div>
          </div>

          {!shopifyCodeOfferMissing && (
            <>
              {/* Shopify discount code settings */}
              {isPromitonSDC && (
                <Box padding='200'>
                  <BlockStack gap='050'>
                    <Text as='h2' fontWeight='medium'>
                      {i18n.translate('ShopifyDiscountCode')}
                    </Text>
                    {(settings.shopifyDiscountApp || !shopifyDiscountCode) && (
                      <List type='bullet'>
                        <List.Item>
                          {settings.shopifyDiscountApp &&
                            i18n.translate('AnyCodeGeneratedBy', {
                              code: (
                                <Text as='span' fontWeight='medium'>
                                  {shopifyDiscountCode}
                                </Text>
                              ),
                            })}
                          {!shopifyDiscountCode &&
                            i18n.translate('NoDiscountCode')}
                        </List.Item>
                      </List>
                    )}
                    {(settings.shopifyDiscountCode ||
                      settings.shopifyDiscountGroup) && (
                      <TextField
                        readOnly
                        value={shopifyDiscountCode || ''}
                        autoComplete=''
                        label=''
                        suffix={
                          <CopyIcon
                            copyText={shopifyDiscountCode}
                            tooltip={i18n.translate('CopyCode')}
                          />
                        }
                      />
                    )}
                  </BlockStack>
                </Box>
              )}

              {/* Discount link settings */}
              {!isPromitonSDC && settings.discountLink && (
                <Box padding='200'>
                  <BlockStack gap='050'>
                    <Text as='p' fontWeight='medium'>
                      {i18n.translate('DiscountLink')}
                    </Text>
                    <TextField
                      readOnly
                      value={truncateLink(
                        settings.discountLink.display as string
                      )}
                      autoComplete=''
                      label=''
                      suffix={
                        <CopyIcon
                          copyText={settings.discountLink?.copy}
                          tooltip={i18n.translate('CopyLink')}
                        />
                      }
                    />
                  </BlockStack>
                </Box>
              )}

              {/* Promotion codes settings */}
              {!!settings.promotionCodes?.count && (
                <Box padding='200'>
                  <BlockStack gap='050'>
                    <Text as='p' fontWeight='medium'>
                      {i18n.translate('PromotionCodes')}
                    </Text>
                    {settings.promotionCodes?.count === 1 ? (
                      <TextField
                        readOnly
                        value={settings.promotionCodes.singleCode || ''}
                        autoComplete=''
                        label=''
                        suffix={
                          <CopyIcon
                            copyText={settings?.promotionCodes?.singleCode}
                            tooltip={i18n.translate('CopyLink')}
                          />
                        }
                      />
                    ) : (
                      <List type='bullet'>
                        <List.Item>
                          {i18n.translate('CodesGenerated', {
                            count: settings?.promotionCodes?.count,
                          })}
                        </List.Item>
                      </List>
                    )}
                  </BlockStack>
                </Box>
              )}

              {/* Filters settings */}
              {!isPromitonSDC && (
                <div className='SettingHoverableBox'>
                  <BlockStack gap='050'>
                    <Text as='h2' fontWeight='medium'>
                      {i18n.translate('Filters')}
                    </Text>
                    <FilterSettingsSummary
                      filters={settings.filters}
                      triggerType={settings.triggerType}
                    />
                  </BlockStack>
                  <div className='EditIcon'>
                    <Button
                      onClick={handleNavigateToFilters}
                      variant='tertiary'
                      icon={EditIcon}
                    />
                  </div>
                </div>
              )}

              {/* Budget settings */}
              <Tooltip
                content={
                  isPromitonSDC ? i18n.translate('SDCBudgetTooltip') : ''
                }
              >
                <div className='SettingHoverableBox'>
                  <BlockStack gap='050'>
                    <Text as='h2' fontWeight='medium'>
                      {i18n.translate('Budget')}
                    </Text>
                    {!isBudgetConfigured ? (
                      <Text as='p' tone='subdued'>
                        {i18n.translate('NoLimits')}
                      </Text>
                    ) : (
                      <List type='bullet'>
                        {settings.maximumUses?.limitedUseByAll?.enabled && (
                          <List.Item>
                            {i18n.translate('MaximumOrders', {
                              ordersCount:
                                settings.maximumUses?.limitedUseByAll.value,
                            })}
                          </List.Item>
                        )}
                        {settings.maximumUses?.orderCap?.enabled && (
                          <List.Item>
                            {i18n.translate('CapCount', {
                              capCount: formatValue(
                                settings.maximumUses?.orderCap.value,
                                ChartuUnitOfMeasurementEnum.CURRENCY
                              ),
                            })}
                          </List.Item>
                        )}
                        {settings.maximumUses?.limitedUsePerCustomerEnabled && (
                          <List.Item>{i18n.translate('LimitToOne')}</List.Item>
                        )}
                      </List>
                    )}
                  </BlockStack>
                  {settings.promotionType !==
                    PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE && (
                    <div className='EditIcon'>
                      <Button
                        onClick={toggleBudgetModal}
                        variant='tertiary'
                        icon={EditIcon}
                      />
                    </div>
                  )}
                </div>
              </Tooltip>

              {/* Exclusivity filter settings */}
              {settings.exclusivityFilter && (
                <div className='SettingHoverableBox'>
                  <BlockStack gap='050'>
                    <Text as='h2' fontWeight='medium'>
                      {i18n.translate('ExclusivityFilter')}
                    </Text>
                    <List type='bullet'>
                      <List.Item>
                        {i18n.translate('Priority', {
                          priority: settings.exclusivityFilter,
                        })}
                      </List.Item>
                    </List>
                  </BlockStack>
                  <div
                    onClick={() =>
                      handleNavigateToSettings('SettingExclusivity')
                    }
                    className='EditIcon'
                  >
                    <Button variant='tertiary' icon={EditIcon} />
                  </div>
                </div>
              )}

              {/* Countdown clock settings */}
              {settings.countdownClock?.mode && (
                <div className='SettingHoverableBox'>
                  <BlockStack gap='050'>
                    <Text as='h2' fontWeight='medium'>
                      {i18n.translate('Urgency')}
                    </Text>
                    <List type='bullet'>
                      <List.Item>
                        {settings.countdownClock?.mode ===
                        TriggerCountdownClockEndModeDtoEnum.PROMOTION_END
                          ? i18n.translate(`RunUntilEnds`)
                          : i18n.translate('FixedAmount', {
                              time: getFixedAmountTime(),
                            })}
                      </List.Item>
                    </List>
                  </BlockStack>
                  <div
                    onClick={() =>
                      handleNavigateToSettings('SettingCountdownClock')
                    }
                    className='EditIcon'
                  >
                    <Button variant='tertiary' icon={EditIcon} />
                  </div>
                </div>
              )}
            </>
          )}
          {/* Sales channel settings */}
          <Box paddingBlockStart='200'>
            <Bleed marginBlockEnd='200' marginInline='200'>
              <Box
                background='bg-surface-secondary'
                paddingInline='400'
                paddingBlock='300'
              >
                <Text tone='subdued' as='p'>
                  {i18n.translate('SalesChannel', {
                    type: i18n.translate(`${settings.salesChannel}`),
                  })}
                </Text>
              </Box>
            </Bleed>
          </Box>
        </BlockStack>
      </Card>
      <ChangeTriggerModal
        selectedTrigger={settings.triggerType as TriggerTypeDtoEnum}
        isOpen={triggerModal}
        isLoading={changePromotionTriggerTypeIsLoading}
        onClose={toggleTriggerModal}
        onSave={handleSaveTriggerType}
      />
      <BudgetModal
        isOpen={budgetModal}
        budget={settings.maximumUses}
        isLoading={changePromotionBudgetIsLoading}
        onClose={toggleBudgetModal}
        onSave={handleSaveBudget}
      />
    </div>
  );
};
