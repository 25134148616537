import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector, useIsDebugOrLocal, useLazyEffect } from 'core/hooks';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { SalesOffer } from '../components/Offers/SalesOffer/SalesOffer';
import { FreeShipping } from '../components/Offers/FreeShipping/FreeShipping';
import { ShopifyDiscountCodeOffer } from '../components/Offers/ShopifyDiscountCode/ShopifyDiscountCodeOffer';
import {
  DiscountOfferTabsEnum,
  PerProductShippingFeeOfferTabsEnum,
  FreeShippingToAnyCountryOfferTabsEnum,
  ShopifyDiscountCodeOfferTabsEnum,
  SpendXGetYTieredOfferTabsEnum,
  FreeShippingForSomeZonesOfferTabsEnum,
  GiftOfferTabsEnum,
  RegularBogoOfferTabsEnum,
  CrossSellOfferTabsEnum,
  VolumeOfferTabsEnum,
  FrequentlyBoughtTogetherTabsEnum,
} from '../enums/OfferTypeTabs';
import { OfferTypesFromCatalogData } from '../consts/OfferTypesFromCatalogData';
import { OfferTypesFromCatalogDataType } from '../types/OfferTypesFromCatalogTypes';
import { useDispatch } from 'react-redux';
import {
  setCurrentOfferData,
  setIsUpdateOfferDraftError,
  setIsUpdatePromotionOfferDraftIsFetching,
  setOfferHasChanges,
  setPromotionDraftDataFetchingIndicator,
  setSelectedOfferType,
  setToastMessages,
} from 'core/store/offersWizardSlice';
import { BogoOffer } from '../components/Offers/BogoOffer/BogoOffer';
import { FreeGiftOffer } from '../components/Offers/FreeGift/FreeGift';
import { CrossSellOffer } from '../components/Offers/CrossSell/CrossSell';
import { VolumeOffer } from '../components/Offers/Volume/VolumeOffer';
import { FrequentlyBoughtTogetherOffer } from '../components/Offers/FrequentlyBoughtTogether/FrequentlyBoughtTogether';
import { TabDescriptor } from '@shopify/polaris/build/ts/src/components/LegacyTabs/types';
import { debounce, isEqual, omit, some } from 'lodash';
import {
  OfferSalesDiscountTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  GetPromotionOfferResponseDto,
  OfferCartRulesDto,
  OfferCombinationTypeDto,
  OfferSalesDiscountSpecificationDto,
  OfferSalesDiscountTypeDto,
  OfferTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useParams } from 'react-router-dom';
import { FreeShippingFixedAmout } from '../components/Offers/FreeShippingFixedAmout/FreeShippingFixedAmout';
import { FreeShippingPercentage } from '../components/Offers/FreeShippingPercentage/FreeShippingPercentage';
import { BundleOffer } from '../components/Offers/BundleOffer/BundleOffer';
import { SpendAmountOffer } from '../components/Offers/SpendAmountOffer/SpendAmountOffer';

type OfferTabs =
  | typeof DiscountOfferTabsEnum
  | typeof ShopifyDiscountCodeOfferTabsEnum
  | typeof SpendXGetYTieredOfferTabsEnum
  | typeof FreeShippingToAnyCountryOfferTabsEnum
  | typeof PerProductShippingFeeOfferTabsEnum
  | typeof FreeShippingForSomeZonesOfferTabsEnum
  | typeof GiftOfferTabsEnum
  | typeof RegularBogoOfferTabsEnum
  | typeof CrossSellOfferTabsEnum
  | typeof VolumeOfferTabsEnum
  | typeof FrequentlyBoughtTogetherTabsEnum;

export const useCreatePromotionOfferTemplate = () => {
  const params = useParams();
  const { selectedOfferType, currentOfferData, currentConfigPage } =
    useAppSelector((stores) => stores.offersWizard);
  const { offerData, updatePromotionOfferDraft, fetchOffer } =
    useConfigureOffers(selectedOfferType?.type);

  const [savedData, setSavedData] = useState<any>(null);
  const [shouldRefreshOfferType, setShouldRefreshOfferType] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();

  useEffect(() => {
    params.id && fetchOffer(params.id);
  }, [params.id]);

  useEffect(() => {
    if (offerData) {
      const data = {
        minimumSpendSupported: offerData.minimumSpendSupported,
        eligibleForSubscription: offerData.eligibleForSubscription,
        template: (offerData as GetPromotionOfferResponseDto).offer,
      };
      dispatch(setCurrentOfferData(data));
      setSavedData(data);
    }
  }, [offerData]);

  useLazyEffect(() => {
    if (!offerData) {
      return;
    }

    Object.entries(OfferTypesFromCatalogData).forEach(
      ([_key, value]: [string, OfferTypesFromCatalogDataType[]]) => {
        const payload = value.find(
          (element: OfferTypesFromCatalogDataType) =>
            element.type === offerData.type
        );
        if (payload) {
          dispatch(setSelectedOfferType(payload));
          setShouldRefreshOfferType(false);
        }
      }
    );
  }, [offerData, dispatch, shouldRefreshOfferType]);

  const handleOfferDataUpdate = useCallback(
    (data: any) => {
      dispatch(
        setCurrentOfferData({
          ...currentOfferData,
          template: {
            ...currentOfferData?.template,
            ...data,
          },
        })
      );
    },
    [dispatch, currentOfferData?.template]
  );

  const mainPageIgnoreList = useMemo(() => {
    if (selectedOfferType?.type) {
      switch (selectedOfferType.type) {
        case OfferTypeDtoEnum.CROSS_SELL:
        case OfferTypeDtoEnum.BOGO_REGULAR:
        case OfferTypeDtoEnum.BOGO_STRICT_MATCH:
          return ['exceptions', 'specification', 'products'];
        case OfferTypeDtoEnum.GIFT_AUTOMATIC:
        case OfferTypeDtoEnum.GIFT_MANUAL:
          return ['exceptions', 'specification'];
        case OfferTypeDtoEnum.SALES_PERCENTAGE:
        case OfferTypeDtoEnum.SALES_FIXED_AMOUNT:
        case OfferTypeDtoEnum.SALES_FIXED_PRICE:
          return ['exceptions', 'products'];
        case OfferTypeDtoEnum.TIERED_BOGO:
        case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y:
        case OfferTypeDtoEnum.VOLUME_DISCOUNT:
          return ['tiers', 'exceptions', 'products'];
        case OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT:
        case OfferTypeDtoEnum.FREE_SHIPPING:
        case OfferTypeDtoEnum.SHIPPING_PERCENTAGE:
        case OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT:
          return ['exceptions'];
        default:
          return null;
      }
    }
  }, [selectedOfferType?.type]);

  const offerTemplateHTML = useMemo(() => {
    if (
      !selectedOfferType ||
      !selectedOfferType.type ||
      !currentOfferData?.template
    ) {
      return;
    }

    switch (selectedOfferType.type) {
      case OfferTypeDtoEnum.GIFT_AUTOMATIC:
      case OfferTypeDtoEnum.GIFT_MANUAL:
        return (
          <FreeGiftOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.SALES_PERCENTAGE:
      case OfferTypeDtoEnum.SALES_FIXED_AMOUNT:
      case OfferTypeDtoEnum.SALES_FIXED_PRICE:
        return (
          <SalesOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.BOGO_REGULAR:
      case OfferTypeDtoEnum.BOGO_STRICT_MATCH:
        return (
          <BogoOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.CROSS_SELL:
        return (
          <CrossSellOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.TIERED_BOGO:
      case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y:
        return (
          <SpendAmountOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.VOLUME_DISCOUNT:
        return (
          <VolumeOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT:
        return (
          <BundleOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER:
      case OfferTypeDtoEnum.ORDER_BUMP:
        return (
          <FrequentlyBoughtTogetherOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.FREE_SHIPPING:
        return (
          <FreeShipping
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.SHIPPING_PERCENTAGE:
        return (
          <FreeShippingPercentage
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT:
        return (
          <FreeShippingFixedAmout
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
      case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR:
      case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP:
      case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP:
        return (
          <ShopifyDiscountCodeOffer
            offerTemplate={currentOfferData}
            offerType={selectedOfferType.type}
            handleOfferDataUpdate={handleOfferDataUpdate}
            savedData={savedData}
          />
        );
    }
  }, [selectedOfferType, currentOfferData, savedData]);

  const offerTabs: TabDescriptor[] = useMemo(() => {
    if (!selectedOfferType || !selectedOfferType.type) {
      return [];
    }

    let tabs: OfferTabs;

    switch (selectedOfferType.type) {
      case OfferTypeDtoEnum.SALES_PERCENTAGE:
      case OfferTypeDtoEnum.SALES_FIXED_AMOUNT:
      case OfferTypeDtoEnum.SALES_FIXED_PRICE:
        tabs = DiscountOfferTabsEnum;
        break;
      // case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y_FIXED_AMOUNT:
      // case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y_PERCENTAGE:
      case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y:
      case OfferTypeDtoEnum.TIERED_BOGO:
        tabs = SpendXGetYTieredOfferTabsEnum;
        break;
      // case OfferTypeDtoEnum.FREE_SHIPPING_PER_PRODUCT:
      //   tabs = PerProductShippingFeeOfferTabsEnum;
      //   break;
      // case OfferTypeDtoEnum.FREE_SHIPPING_REGULAR:
      //   tabs = FreeShippingToAnyCountryOfferTabsEnum;
      //   break;
      // case OfferTypeDtoEnum.FREE_SHIPPING_DISCOUNT_CODE:
      //   tabs = FreeShippingForSomeZonesOfferTabsEnum;
      //   break;
      case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR:
      case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP:
      case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP:
        tabs = ShopifyDiscountCodeOfferTabsEnum;
        break;
      case OfferTypeDtoEnum.GIFT_AUTOMATIC:
      case OfferTypeDtoEnum.GIFT_MANUAL:
        tabs = GiftOfferTabsEnum;
        break;
      case OfferTypeDtoEnum.BOGO_REGULAR:
      case OfferTypeDtoEnum.BOGO_STRICT_MATCH:
        tabs = RegularBogoOfferTabsEnum;
        break;
      case OfferTypeDtoEnum.CROSS_SELL:
        tabs = CrossSellOfferTabsEnum;
        break;
      case OfferTypeDtoEnum.VOLUME_DISCOUNT:
        tabs = VolumeOfferTabsEnum;
        break;
      case OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER:
      case OfferTypeDtoEnum.ORDER_BUMP:
        tabs = FrequentlyBoughtTogetherTabsEnum;
        break;
      default:
        tabs = DiscountOfferTabsEnum;
        break;
    }

    return Object.entries(tabs).map(([key, value]: [string, string]) => ({
      id: key,
      content: value,
    }));
  }, [selectedOfferType]);

  const handleDataSave = useCallback(() => {
    setSavedData(currentOfferData);
    dispatch(setOfferHasChanges(false));
    dispatch(setIsUpdateOfferDraftError(false));
    dispatch(setIsUpdatePromotionOfferDraftIsFetching(false));
    dispatch(setPromotionDraftDataFetchingIndicator(true));
    dispatch(
      setToastMessages({
        error: false,
        message: 'OfferUpdated',
      })
    );
  }, [setSavedData, currentOfferData, dispatch]);

  const removeProperties = useCallback((obj: any, properties: string[]) => {
    const newObj: any = {};

    for (const key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        !properties.includes(key)
      ) {
        if (
          typeof obj[key] === 'object' &&
          obj[key] !== null &&
          !Array.isArray(obj[key])
        ) {
          newObj[key] = removeProperties(obj[key], properties);
        } else {
          newObj[key] = obj[key];
        }
      }
    }

    return newObj;
  }, []);

  const compareObjectLengths = useCallback(
    (obj1: OfferCartRulesDto, obj2: OfferCartRulesDto): boolean => {
      if (!obj1 && !obj2) {
        return true;
      }

      // Compare the length of the groups arrays
      if (obj1?.groups?.length !== obj2?.groups?.length) {
        return false;
      }

      if (!isEqual(obj1?.connectors, obj2?.connectors)) {
        return false;
      }

      // Compare the length of the rules arrays within each group
      const isDifferent = some(obj1.groups, (group, index) => {
        return group?.rules?.length !== obj2?.groups?.[index]?.rules?.length;
      });

      return !isDifferent;
    },
    []
  );

  const updateRequestOfferData = useCallback(
    debounce((offerId: string, salesType = false) => {
      if (!currentOfferData?.template) return;

      const areObjectsSame = compareObjectLengths(
        currentOfferData?.template?.cartRules,
        savedData?.template?.cartRules
      );

      const updatedMainPageIgnoreList = areObjectsSame
        ? [...(mainPageIgnoreList ?? []), 'cartRules']
        : mainPageIgnoreList;

      const getProperSalesOfferType = (
        type: OfferSalesDiscountTypeDto
      ): OfferTypeDto => {
        switch (type) {
          case OfferSalesDiscountTypeDtoEnum.PERCENTAGE:
            return OfferTypeDtoEnum.SALES_PERCENTAGE;
          case OfferSalesDiscountTypeDtoEnum.FIXED_AMOUNT:
            return OfferTypeDtoEnum.SALES_FIXED_AMOUNT;
          default:
            return OfferTypeDtoEnum.SALES_FIXED_PRICE;
        }
      };

      const offerObjNeeded =
        updatedMainPageIgnoreList && !isDebugOrLocal
          ? removeProperties(
              currentOfferData?.template,
              updatedMainPageIgnoreList
            )
          : currentOfferData?.template;

      const requestData = {
        type: salesType
          ? getProperSalesOfferType(
              (
                currentOfferData?.template
                  .specification as OfferSalesDiscountSpecificationDto
              ).type as OfferSalesDiscountTypeDto
            )
          : selectedOfferType?.type,
        offer:
          currentConfigPage && !isDebugOrLocal
            ? {
                [currentConfigPage]: {
                  ...currentOfferData?.template[currentConfigPage],
                },
              }
            : offerObjNeeded,
      };

      updatePromotionOfferDraft(offerId, requestData, handleDataSave);
    }, 300),
    [
      updatePromotionOfferDraft,
      selectedOfferType?.type,
      currentOfferData?.template,
      savedData?.template?.cartRules,
      handleDataSave,
      currentConfigPage,
      removeProperties,
      isDebugOrLocal,
      mainPageIgnoreList,
    ]
  );

  useEffect(() => {
    if (currentOfferData?.template && savedData?.template) {
      const savedTemplateWithoutCombinations = omit(savedData.template, [
        'combinations',
      ]);
      const currentTemplateWithoutCombinations = omit(
        currentOfferData.template,
        ['combinations']
      );

      const combinationsChangedFromNull =
        savedData?.template?.combinations === null &&
        currentOfferData?.template?.combinations !== null;

      const isTemplateEqualWithoutCombinations = isEqual(
        savedTemplateWithoutCombinations,
        currentTemplateWithoutCombinations
      );

      if (isTemplateEqualWithoutCombinations && combinationsChangedFromNull) {
        dispatch(setOfferHasChanges(false));
        setSavedData(currentOfferData);
      } else {
        isEqual(savedData?.template, currentOfferData?.template)
          ? dispatch(setOfferHasChanges(false))
          : dispatch(setOfferHasChanges(true));
      }
    }
  }, [savedData?.template, currentOfferData?.template]);

  const handleDiscard = useCallback(
    (forceReInit: () => void) => {
      dispatch(setCurrentOfferData(savedData));
      dispatch(setOfferHasChanges(false));
      forceReInit();
      setShouldRefreshOfferType(true);
    },
    [savedData, dispatch, setShouldRefreshOfferType]
  );

  return {
    offerTemplateHTML,
    offerTabs,
    updateRequestOfferData,
    type: selectedOfferType?.type,
    handleDiscard,
  };
};
