import React from 'react';
import { BlockStack, Box, Modal, Text, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ClipboardIcon } from '@shopify/polaris-icons';
import { TriggerDiscountLinkPreviewDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { CopyIcon } from 'core/components';

type SharePromotionModalProps = {
  isOpen: boolean;
  discountLink?: TriggerDiscountLinkPreviewDto;
  promocode?: string | null;
  onClose: () => void;
};
export const SharePromotionModal: React.FC<SharePromotionModalProps> = ({
  isOpen,
  discountLink,
  promocode,
  onClose,
}) => {
  const [i18n] = useI18n();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate(`SharePromotion`)}
      secondaryActions={[
        {
          content: i18n.translate('Close'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <Text as='p'>{i18n.translate('Congratulations')}</Text>
          <TextField
            value={discountLink?.display}
            label={i18n.translate('DefaultLink')}
            autoComplete=''
            readOnly
            connectedRight={
              <Box paddingInlineStart='100'>
                <CopyIcon
                  icon={ClipboardIcon}
                  variant='tertiary'
                  copyText={discountLink?.copy}
                  tooltip={i18n.translate('CopyLink')}
                />
              </Box>
            }
          />
          {promocode && (
            <TextField
              value={promocode}
              label={i18n.translate('DefaultLink')}
              autoComplete=''
              readOnly
              connectedRight={
                <Box paddingInlineStart='100'>
                  <CopyIcon
                    icon={ClipboardIcon}
                    variant='tertiary'
                    copyText={promocode}
                    tooltip={i18n.translate('CopyCode')}
                  />
                </Box>
              }
            />
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
