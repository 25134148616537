import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './ShippingCountries.scss';
import { useI18n } from '@shopify/react-i18n';
import { GlobeFilledIcon, EditIcon, DeleteIcon } from '@shopify/polaris-icons';
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  Modal,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import {
  ShippingCountryDto,
  ShippingCountryLookupDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { ActionListInPopover } from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import { useShippingCountries } from 'core/hooks/useShippingCountries';
import ReactCountryFlag from 'react-country-flag';
import { ResourceListCountry } from './components/ResourceListCountry/ResourceListCountry';
import { Loader } from 'core/components';
import { countryRegionMap, RegionType } from './ShippingCountriesEnums';

type ShippingCountriesProps = {
  selectedCountries: ShippingCountryDto[];
  setSelectedCountries: Dispatch<SetStateAction<ShippingCountryDto[]>>;
};

export type CheckedProvince = {
  id: string;
  checked: boolean;
};

export type CheckedResourceProvinceItem = {
  parent: CheckedProvince;
  provinces?: CheckedProvince[];
};

export const ShippingCountries: React.FC<ShippingCountriesProps> = (props) => {
  const { selectedCountries, setSelectedCountries } = props;

  const [i18n] = useI18n();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<
    CheckedResourceProvinceItem[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const { shippingCountriesListData, shippingCountriesListIsLoading } =
    useShippingCountries();

  const [filteredResults, setFilteredResults] = useState<
    ShippingCountryLookupDto[]
  >([]);

  const getRegionType = useCallback(
    (countryCode: string): RegionType => {
      return countryRegionMap[countryCode] || RegionType.PROVINCE;
    },
    [countryRegionMap]
  );

  const getRegionTypeTranslation = useCallback(
    (regionType: RegionType): string => {
      return i18n.translate(`regionTypes.${regionType.toLowerCase()}`);
    },
    [i18n]
  );

  const getOverview = useCallback(() => {
    const overview = selectedCountries.map((selectedCountry) => {
      const countryData = shippingCountriesListData?.find(
        (country) => country.id === selectedCountry.id
      );

      if (countryData) {
        const regionType = getRegionTypeTranslation(
          getRegionType(countryData.code!)
        );
        const provinces = countryData.provinces?.filter((province) =>
          selectedCountry.provinces?.includes(province.id!)
        );

        if (!provinces || provinces.length === 0) {
          return countryData.name;
        } else if (provinces.length === 1) {
          return `${countryData.name} (${provinces[0].name})`;
        } else {
          return `${countryData.name} (${provinces.length} ${i18n.translate(
            'of'
          )} ${countryData.provinces!.length} ${regionType})`;
        }
      }

      return null;
    });

    const validOverview = overview.filter(Boolean);
    if (validOverview.length > 5) {
      const firstFive = validOverview.slice(0, 5);
      const remaining = validOverview.slice(5);

      return (
        <span>
          {i18n.translate('AppliesTo')} {firstFive.join(', ')},{' '}
          <Tooltip content={remaining.join(', ')} hasUnderline>
            <Text as='span'>
              {i18n.translate('moreCount', { count: remaining.length })}
            </Text>
          </Tooltip>
        </span>
      );
    } else {
      return `${i18n.translate('AppliesTo')} ${validOverview.join(', ')}`;
    }
  }, [selectedCountries, shippingCountriesListData, i18n]);

  const parsedSelectedItems = useMemo(
    () =>
      selectedItems
        .filter((item) => item.parent.checked)
        .map((item) => ({
          id: item.parent.id,
          provinces: item.provinces
            ?.filter((province) => province.checked)
            .map((province) => province.id),
        })),
    [selectedItems]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);

      const lowercasedValue = value.toLowerCase();
      const filtered = shippingCountriesListData?.filter(
        (country) =>
          country.name?.toLowerCase().includes(lowercasedValue) ||
          country.code?.toLowerCase().includes(lowercasedValue) ||
          country.provinces?.some(
            (province) =>
              province.name?.toLowerCase().includes(lowercasedValue) ||
              province.code?.toLowerCase().includes(lowercasedValue)
          )
      );

      setFilteredResults(filtered || []);
    },
    [shippingCountriesListData]
  );

  const getSelectedVariantsByParentId = useCallback(
    (id: string) => {
      return (
        selectedCountries
          ?.find((country) => country.id === id)
          ?.provinces?.map((province) => province) || []
      );
    },
    [selectedCountries]
  );

  const getSelectedParentById = useCallback(
    (id: string) => {
      return selectedCountries?.some((country) => country.id === id) || false;
    },
    [selectedCountries]
  );

  const handleSelectionChange = useCallback(
    (checkedResourceListItem: CheckedResourceProvinceItem) => {
      setSelectedItems((prev) => {
        const selectedItemIdx = prev.findIndex(
          (item) => item.parent.id === checkedResourceListItem.parent.id
        );

        return selectedItemIdx === -1
          ? [...prev, checkedResourceListItem]
          : prev.map((item, idx) =>
              idx === selectedItemIdx ? checkedResourceListItem : item
            );
      });
    },
    [setSelectedItems]
  );

  const handleAdd = useCallback(() => {
    setSelectedCountries(parsedSelectedItems);
    setOpenModal(false);
  }, [parsedSelectedItems]);

  useEffect(() => {
    !openModal && !!selectedItems.length && setSelectedItems([]);
  }, [openModal]);

  useEffect(() => {
    if (shippingCountriesListData && !filteredResults.length) {
      setFilteredResults(shippingCountriesListData);
    }
  }, [shippingCountriesListData, filteredResults]);

  return (
    <>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Title')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          {!!selectedCountries.length && (
            <Banner>{i18n.translate('InfoBanner')}</Banner>
          )}
          <Card roundedAbove='sm' padding='400'>
            <InlineStack align='space-between' wrap={false} blockAlign='center'>
              {selectedCountries.length ? (
                <InlineStack wrap={false} gap='200' blockAlign='center'>
                  <div style={{ width: 20, height: 20 }}>
                    <Icon source={GlobeFilledIcon} tone='base' />
                  </div>
                  {getOverview()}
                </InlineStack>
              ) : (
                <Text as='p' tone='subdued'>
                  {i18n.translate('NoSelection')}
                </Text>
              )}
              {selectedCountries.length ? (
                <ActionListInPopover
                  btnContent=''
                  actionList={[
                    {
                      content: i18n.translate('Edit'),
                      onAction: () => setOpenModal(true),
                      icon: EditIcon,
                    },
                    {
                      content: i18n.translate('Delete'),
                      destructive: true,
                      icon: DeleteIcon,
                      onAction: () => setSelectedCountries([]),
                    },
                  ]}
                />
              ) : (
                <BlockStack align='center'>
                  <Button variant='plain' onClick={() => setOpenModal(true)}>
                    {i18n.translate('Configure')}
                  </Button>
                </BlockStack>
              )}
            </InlineStack>
          </Card>
        </BlockStack>
      </Card>
      <Modal
        title={i18n.translate('ModalTitle')}
        open={openModal}
        onClose={() => setOpenModal(false)}
        primaryAction={{
          content: i18n.translate('Add'),
          onAction: handleAdd,
        }}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
            onAction: () => setOpenModal(false),
          },
        ]}
      >
        <Box padding='400' borderBlockEndWidth='0165'>
          <TextField
            label=''
            labelHidden
            placeholder={i18n.translate('Search')}
            value={searchValue}
            onChange={handleSearchChange}
            clearButton
            onClearButtonClick={() => handleSearchChange('')}
            autoComplete='off'
          />
        </Box>
        <div className='shippingCountriesScrollable'>
          {shippingCountriesListIsLoading || !shippingCountriesListData ? (
            <Loader size='large' fullWidth />
          ) : (
            filteredResults?.map((country: ShippingCountryLookupDto) => (
              <ResourceListCountry
                countryName={country.name || ''}
                id={country.id}
                key={country.id}
                countryCode={country.code || ''}
                source={
                  <ReactCountryFlag
                    countryCode={country.code || ''}
                    svg
                    style={{
                      width: '2rem',
                      height: '1.5rem',
                      borderRadius: 'var(--p-border-radius-100)',
                      outlineColor: 'var(--p-color-bg-surface-disabled)',
                      outlineOffset: -1,
                      outlineStyle: 'solid',
                      outlineWidth: 1,
                    }}
                  />
                }
                provinces={country.provinces}
                onChecked={handleSelectionChange}
                initialChecked={getSelectedParentById(country.id || '')}
                initialCheckedProvinces={getSelectedVariantsByParentId(
                  country.id || ''
                )}
              />
            ))
          )}
        </div>
      </Modal>
    </>
  );
};
