import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SaveBar } from '@shopify/app-bridge-react';
import { useNavigate, useParams } from 'react-router-dom';
import './SelectedOfferTypeSettings.scss';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import {
  setPromotionOfferValidityState,
  setSelectedOfferType,
  // setLastSelectedOffer,
  setCombinedWithList,
  setLastCombinedWithTypeFetched,
  setCurrentCombinationIsLoaded,
  setCurrentOfferData,
  setNotCombinedWithList,
  setCurrentCombination,
  setLastSelectedOffer,
  setCurrentCombinationIsFetching,
} from 'core/store/offersWizardSlice';
import { useCreatePromotionOfferTemplate } from './hooks/useCreatePromotionOfferTemplate';
import { Loader } from 'core/components';
// import { AutoSaveInfoTextIndicator } from './components/AutoSaveInfoTextIndicator/AutoSaveInfoTextIndicator';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEqual } from 'lodash';
import { Button, ContextualSaveBar, Frame } from '@shopify/polaris';
import { AutoSaveInfoTextIndicator } from './components/AutoSaveInfoTextIndicator/AutoSaveInfoTextIndicator';
import { useI18n } from '@shopify/react-i18n';
import { NavPaths } from 'core/enums/NavPathsEnum';

export const SelectedOfferTypeSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [i18n] = useI18n();
  const params = useParams();
  const store = useAppSelector((stores) => stores.offersWizard);
  const { offerTemplateHTML, updateRequestOfferData, type, handleDiscard } =
    useCreatePromotionOfferTemplate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const {
    updateOfferMutationIsLoading,
    getCurrentCombinationOfferDraft,
    getCurrentCombinationsOffersListData,
    getCurrentTypeOfList,
  } = useConfigureOffers();

  const timer = 500;

  const [keyInit, setKeyInit] = useState<number>(0);

  const [delay, setDelay] = useState<number | null>(null);
  const [validityError, setValidityError] = useState<boolean>(false);
  const [shouldRefetchCombinations, setShouldRefetchCombinations] =
    useState<boolean>(false);

  const abortControllerRef = useRef(new AbortController());

  const closeThePage = useCallback(() => {
    dispatch(setSelectedOfferType(null));
    navigate(`/${NavPaths.Promotions}/${params.promotionId}`);
  }, [navigate, dispatch, params.promotionId]);

  const prevLevel = usePrevious(store.currentOfferData?.template?.level);
  const prevExcludedOffersIds = usePrevious(
    store.currentOfferData?.template?.combinations?.excludedOffersIds
  );
  const prevExcludedShopifyDiscountCodesIds = usePrevious(
    store.currentOfferData?.template?.combinations
      ?.excludedShopifyDiscountCodesIds
  );

  useEffect(() => {
    const levelHasBeenUpdated =
      store.currentOfferData?.template?.level &&
      prevLevel &&
      store.currentOfferData?.template?.level !== prevLevel;
    if (
      levelHasBeenUpdated ||
      (store.currentOfferData?.template?.combinations &&
        (!isEqual(
          prevExcludedOffersIds,
          store.currentOfferData?.template?.combinations?.excludedOffersIds
        ) ||
          !isEqual(
            store.currentOfferData?.template?.combinations
              ?.excludedShopifyDiscountCodesIds,
            prevExcludedShopifyDiscountCodesIds
          )))
    ) {
      if (delay) {
        clearTimeout(delay);
      }
      dispatch(setCurrentCombinationIsFetching(true));
      const newTimerId = setTimeout(
        () => {
          abortControllerRef.current.abort();
          abortControllerRef.current = new AbortController();
          getCurrentCombinationOfferDraft(abortControllerRef.current.signal);
          setDelay(null);
        },
        levelHasBeenUpdated ? 0 : timer
      );
      setDelay(newTimerId as unknown as number);
    }
  }, [
    prevLevel,
    prevExcludedOffersIds,
    prevExcludedShopifyDiscountCodesIds,
    store.currentOfferData?.template?.level,
    store.currentOfferData?.template?.combinations?.excludedOffersIds,
    store.currentOfferData?.template?.combinations
      ?.excludedShopifyDiscountCodesIds,
  ]);

  // useEffect(() => {
  //   if (
  //     params.id &&
  //     params.promotionId &&
  //     store.lastNotCombinedWithTypeData.shopifyDiscountCodes.shouldFetch
  //   ) {
  //     getCurrentShopifyDiscountCodesData();
  //   }
  // }, [store.lastNotCombinedWithTypeData.shopifyDiscountCodes]);

  useEffect(() => {
    if (
      params.id &&
      params.promotionId &&
      store.lastNotCombinedWithTypeData.offers.shouldFetch
    ) {
      getCurrentCombinationsOffersListData();
    }
  }, [store.lastNotCombinedWithTypeData.offers]);

  useEffect(() => {
    if (params.id && params.promotionId && store.lastCombinedWithTypeFetched) {
      getCurrentTypeOfList();
    }
  }, [store.lastCombinedWithTypeFetched, store.lastCombinedWithTypePage]);

  useEffect(() => {
    if (params.id && store.selectedOfferType?.type) {
      dispatch(
        setLastSelectedOffer({
          id: params.id,
          type: store.selectedOfferType?.type,
        })
      );
      if (delay) {
        clearTimeout(delay);
      }
      dispatch(setCurrentCombinationIsFetching(true));
      const newTimerId = setTimeout(() => {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        getCurrentCombinationOfferDraft(abortControllerRef.current.signal);
        setDelay(null);
      }, timer);
      setDelay(newTimerId as unknown as number);
    }
  }, [params.id, store.selectedOfferType?.type]);

  useEffect(() => {
    if (shouldRefetchCombinations) {
      if (delay) {
        clearTimeout(delay);
      }
      dispatch(setCurrentCombinationIsFetching(true));
      const newTimerId = setTimeout(() => {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        getCurrentCombinationOfferDraft(abortControllerRef.current.signal);
        setDelay(null);
        setShouldRefetchCombinations(false);
      }, timer);
      setDelay(newTimerId as unknown as number);
    }
  }, [shouldRefetchCombinations]);

  useEffect(() => {
    return () => {
      dispatch(setCombinedWithList({}));
      dispatch(setNotCombinedWithList({}));
      dispatch(setCurrentOfferData(null));
      dispatch(setLastCombinedWithTypeFetched(null));
      dispatch(setCurrentCombinationIsLoaded(false));
      dispatch(setPromotionOfferValidityState([]));
      abortControllerRef.current.abort();
      delay !== null && clearTimeout(delay);
    };
  }, []);

  const hasErrors = useMemo(
    () => store.formValidity.some((form) => form.isValid === false),
    [store.formValidity]
  );

  useEffect(() => {
    setValidityError(hasErrors);
  }, [hasErrors]);

  useEffect(() => {
    if (keyInit) {
      dispatch(setCombinedWithList({}));
      dispatch(setNotCombinedWithList({}));
      dispatch(
        setCurrentOfferData({
          ...store.currentOfferData,
          template: {
            ...store.currentOfferData?.template,
            combinations: null,
          },
        })
      );
      dispatch(setCurrentCombination({}));
      dispatch(setLastCombinedWithTypeFetched(null));
      dispatch(setCurrentCombinationIsLoaded(false));

      setShouldRefetchCombinations(true);
    }
  }, [keyInit]);

  useEffect(() => window.scrollTo(0, 0), []);

  const isSalesType = useMemo(
    () =>
      type &&
      [
        OfferTypeDtoEnum.SALES_FIXED_AMOUNT,
        OfferTypeDtoEnum.SALES_FIXED_PRICE,
        OfferTypeDtoEnum.SALES_PERCENTAGE,
      ].indexOf(type) !== -1,
    [type]
  );

  const templateReset = useCallback(() => {
    setKeyInit((prevKey) => prevKey + 1);
  }, []);

  return (
    <>
      {isDebugOrLocal ? (
        <>
          {store.offerHasChanges ? (
            <div className='contextualSaveBarWrapper'>
              <Frame>
                <ContextualSaveBar
                  alignContentFlush
                  message={i18n.translate('ConfigureOffer')}
                  saveAction={{
                    onAction: () => {
                      if (!params.promotionId || !params.id) return;
                      updateRequestOfferData(params.id, isSalesType);
                    },
                    disabled:
                      validityError ||
                      updateOfferMutationIsLoading ||
                      store.isUpdatePromotionOfferDraftIsFetching ||
                      store.currentCombinationIsFetching,
                  }}
                  discardAction={{
                    onAction: () => handleDiscard(templateReset),
                    disabled:
                      updateOfferMutationIsLoading ||
                      store.isUpdatePromotionOfferDraftIsFetching ||
                      store.currentCombinationIsFetching,
                  }}
                  secondaryMenu={
                    <AutoSaveInfoTextIndicator
                      validationError={validityError}
                      isLoading={
                        updateOfferMutationIsLoading ||
                        store.isUpdatePromotionOfferDraftIsFetching
                      }
                    />
                  }
                />
              </Frame>
            </div>
          ) : (
            <div className='TopBarContainer'>
              <p className='TopBarTitle'>
                <span className='SelectedOfferTitle'>
                  <strong>{i18n.translate('ConfigureOffer')}</strong>
                </span>
              </p>
              <Button onClick={closeThePage} disabled={validityError}>
                {i18n.translate('Close')}
              </Button>
            </div>
          )}
        </>
      ) : (
        <SaveBar id='offer-type-settings-bar' open={store.offerHasChanges}>
          {/* <AutoSaveInfoTextIndicator
          validationError={validityError}
          isLoading={
            updateOfferMutationIsLoading ||
            store.isUpdatePromotionOfferDraftIsFetching
          }
        /> */}
          <button
            // eslint-disable-next-line react/no-unknown-property
            variant='primary'
            onClick={() => {
              if (!params.promotionId || !params.id) return;
              updateRequestOfferData(params.id, isSalesType);
            }}
            disabled={
              validityError ||
              updateOfferMutationIsLoading ||
              store.isUpdatePromotionOfferDraftIsFetching ||
              store.currentCombinationIsFetching
            }
          />
          <button
            onClick={() => handleDiscard(templateReset)}
            disabled={
              updateOfferMutationIsLoading ||
              store.isUpdatePromotionOfferDraftIsFetching ||
              store.currentCombinationIsFetching
            }
          />
        </SaveBar>
      )}
      {offerTemplateHTML?.props?.offerTemplate?.template ? (
        <>
          <section className='OfferContentContainer'>
            <div key={keyInit} style={{ height: '100%' }}>
              {offerTemplateHTML}
            </div>
          </section>
        </>
      ) : (
        <Loader size='large' fullWidth />
      )}
    </>
  );
};
