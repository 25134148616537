import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionOverview.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  InlineStack,
  Layout,
  Page,
  Text,
} from '@shopify/polaris';
import {
  DuplicateIcon,
  ArchiveIcon,
  DeleteIcon,
  StopCircleIcon,
  PlayIcon,
  ReturnIcon,
} from '@shopify/polaris-icons';
import {
  PromotionStatus,
  PromotionDetails,
  OverviewPromotionSettings,
  DeletePromotionModal,
  SchedulePromotionModal,
  DeactivatePromotionModal,
  PromotionOverviewSkeleton,
  OverviewOfferList,
  FinishConfigurationModal,
  UnpublishedBanner,
  ExpiredBanner,
  PreviewPromotionModal,
  OverviewWidgetList,
  PublishOrScheduleModal,
  SharePromotionModal,
} from './components';
import { Loader } from 'core/components';
import {
  PromotionOverviewDetailsDto,
  PromotionOverviewSettingsDto,
  PromotionTypeDto,
  SchedulePromotionRequestDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import {
  PlanLevelDtoEnum,
  PromotionOperationTypeDtoEnum,
  PromotionStatusDtoEnum,
  PromotionTypeDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { getPromotionStatusColor } from '../PromotionList/utils';
import { ScheduleModalEnum } from './components/SchedulePromotionModal/SchedulePromotionModal';
import useFetchEngineAndPlanCheck from './utils/useFetchEngineAndPlanCheck/useFetchEngineAndPlanCheck';
import { scroller } from 'react-scroll';
import MomentAgo from 'core/components/MomentAgo/MomentAgo';
import { DowngradePlanBanner } from '../PromotionList/components/DowngradePlanBanner/DowngradePlanBanner';
import { ReachedLimitBanner } from '../PromotionList/components/ReachedLimitBanner/ReachedLimitBanner';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from 'features/settings/components/GeneralSettings/Plan/components/ChangePlanModal/ChangePlanModal';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { useConfigureOfferList } from './utils/useConfigureOfferList/useConfigureOfferList';
import { PromotionSetupGuide } from './components/PromotionSetupGuide/PromotionSetupGuide';
import {
  dismissComponent,
  DismissedEnum,
  getDismissedComponents,
} from 'features/dashboard/utils/utils';

export enum PromotionOverviewModalsEnum {
  DELETE_PROMOTION_MODAL = 'DELETE_PROMOTION_MODAL',
  FINISH_CONFIGURATION_MODAL = 'FINISH_CONFIGURATION_MODAL',
  UNPUBLISH_PROMOTION_MODAL = 'UNPUBLISH_PROMOTION_MODAL',
  PROMOTION_TEST_MODAL = 'PROMOTION_TEST_MODAL',
  PUBLISH_OR_SCHEDULE_MODAL = 'PUBLISH_OR_SCHEDULE_MODAL',
  SHARE_PROMOTION_MODAL = 'SHARE_PROMOTION_MODAL',
}

export const PromotionOverview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [i18n] = useI18n();
  const {
    promotionOverviewData,
    duplicatePromotionIsLoading,
    archivePromotionIsLoading,
    deactivatePromotionIsLoading,
    schedulePromotionIsLoading,
    restorePromotionIsLoading,
    publishPromotionIsLoading,
    discardPromotionIsLoading,
    promotionOverviewIsFetching,
    refetchPromotionOverviewData,
    archivePromotion,
    duplicatePromotion,
    deletePromotion,
    deactivatePromotion,
    schedulePromotion,
    restorePromotion,
    publishPromotion,
    discardPromotion,
  } = useConfigurePromotions(PromotionFetchTypeEnum.PROMOTION_OVERVIEW);

  const {
    promotionWidgetsData,
    promotionWidgetsIsFetching,
    refetchPromotionWidgets,
  } = useConfigureWidgets(WidgetsFetchTypeEnum.PROMOTION_OVERVIEW, true);

  const {
    engineAndPlanCheckData,
    engineAndPlanCheckIsFetching,
    fetchEngineAndPlanCheck,
  } = useFetchEngineAndPlanCheck();

  const [offerPage, setOfferPage] = useState<number>(1);
  const { offerListData, offerListIsFetching, refetchOfferList } =
    useConfigureOfferList(params.promotionId || '', offerPage);

  const [overviewModal, setOverviewModal] =
    useState<PromotionOverviewModalsEnum | null>(null);
  const [planToChangeModal, setPlanToChangeModal] =
    useState<PlanLevelDtoEnum>();
  const [scheduleModalType, setScheduleModalType] =
    useState<ScheduleModalEnum | null>(null);

  const [isUsedBanner, setIsUsedBanner] = useState<boolean>(true);
  const [checkConfiguration, setCheckConfiguration] = useState<boolean>(false);

  const [dismissedComponents, setDismissedComponents] = useState<string[]>(
    getDismissedComponents(
      DismissedEnum.PROMOTION_OVERVIEW_PAGE_DISMISSED_COMPONENTS
    )
  );

  const isDataLoading = useMemo(
    () =>
      duplicatePromotionIsLoading ||
      deactivatePromotionIsLoading ||
      schedulePromotionIsLoading ||
      promotionWidgetsIsFetching ||
      promotionOverviewIsFetching ||
      offerListIsFetching,
    [
      duplicatePromotionIsLoading,
      deactivatePromotionIsLoading,
      schedulePromotionIsLoading,
      promotionWidgetsIsFetching,
      promotionOverviewIsFetching,
      offerListIsFetching,
    ]
  );

  const isPromotionEditable = useMemo(
    () =>
      promotionOverviewData?.status !== PromotionStatusDtoEnum.ARCHIVED &&
      promotionOverviewData?.status !== PromotionStatusDtoEnum.USED &&
      promotionOverviewData?.status !== PromotionStatusDtoEnum.EXPIRED,
    [promotionOverviewData?.status]
  );

  const isDraftScheduled = useMemo(
    () =>
      promotionOverviewData?.status === PromotionStatusDtoEnum.DRAFT &&
      (promotionOverviewData.schedule?.startsAt || 0) > Date.now(),
    [promotionOverviewData?.status, promotionOverviewData?.schedule?.startsAt]
  );

  const disablePromotionActions = useMemo(
    () =>
      [
        discardPromotionIsLoading,
        deactivatePromotionIsLoading,
        duplicatePromotionIsLoading,
        archivePromotionIsLoading,
        discardPromotionIsLoading,
        restorePromotionIsLoading,
        publishPromotionIsLoading,
        promotionOverviewIsFetching,
        schedulePromotionIsLoading,
      ].includes(true),
    [
      discardPromotionIsLoading,
      deactivatePromotionIsLoading,
      duplicatePromotionIsLoading,
      archivePromotionIsLoading,
      discardPromotionIsLoading,
      restorePromotionIsLoading,
      promotionOverviewIsFetching,
      publishPromotionIsLoading,
      schedulePromotionIsLoading,
    ]
  );

  const isSDCType =
    promotionOverviewData?.settings?.promotionType ===
    PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE;

  const shopifyCodeOfferMissing = useMemo(
    () => isSDCType && !offerListData?.items?.length,
    [isSDCType, offerListData?.items]
  );

  const shopifyDiscountCodeMissing = useMemo(
    () =>
      promotionOverviewData?.settings?.promotionType ===
        PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      !promotionOverviewData.settings.shopifyDiscountApp &&
      !promotionOverviewData.settings.shopifyDiscountGroup &&
      !promotionOverviewData.settings.shopifyDiscountCode &&
      !!offerListData?.items?.length,
    [promotionOverviewData?.settings, offerListData?.items]
  );

  const handleRefetchAllOverviewData = useCallback(() => {
    refetchOfferList();
    refetchPromotionOverviewData();
    refetchPromotionWidgets();
  }, []);

  const onArchivePromotion = useCallback(() => {
    archivePromotion(
      params.promotionId as string,
      refetchPromotionOverviewData
    );
  }, [params]);

  const onRestorePromotion = useCallback(
    () =>
      restorePromotion(
        params.promotionId as string,
        handleRefetchAllOverviewData
      ),
    [handleRefetchAllOverviewData]
  );

  const onDuplicatePromotion = useCallback(() => {
    duplicatePromotion(params.promotionId as string);
  }, [params]);

  const onDeletePromotion = useCallback(() => {
    deletePromotion(params.promotionId as string, () =>
      navigate(`/${NavPaths.Promotions}`)
    );
  }, [params]);

  const onDeactivatePromotion = useCallback(() => {
    deactivatePromotion(
      params.promotionId as string,
      refetchPromotionOverviewData
    );
  }, [params]);

  const onPublishPromotion = useCallback(
    () =>
      publishPromotion(() => {
        handleRefetchAllOverviewData();
        promotionOverviewData?.settings?.triggerType ===
          TriggerTypeDtoEnum.PRIVATE &&
          setOverviewModal(PromotionOverviewModalsEnum.SHARE_PROMOTION_MODAL);
      }),
    [handleRefetchAllOverviewData, promotionOverviewData?.settings?.triggerType]
  );

  const onDiscardPromotion = useCallback(
    () => discardPromotion(handleRefetchAllOverviewData),
    [handleRefetchAllOverviewData]
  );

  const onSchedulePromotion = useCallback(
    (data: SchedulePromotionRequestDto) => {
      schedulePromotion(
        data,
        params.promotionId as string,
        refetchPromotionOverviewData
      );
    },
    [params]
  );

  const handleFinishConfigurationModal = useCallback(async () => {
    if (
      engineAndPlanCheckData?.isActiveSubscriptionInPlace &&
      engineAndPlanCheckData.promotionEngine?.enabled
    ) {
      !promotionOverviewData?.schedule?.startsAt
        ? setOverviewModal(
            PromotionOverviewModalsEnum.PUBLISH_OR_SCHEDULE_MODAL
          )
        : onPublishPromotion();
    } else {
      await fetchEngineAndPlanCheck().then((data) => {
        if (
          data?.isActiveSubscriptionInPlace &&
          data.promotionEngine?.enabled
        ) {
          onPublishPromotion();
        } else {
          setOverviewModal(
            PromotionOverviewModalsEnum.FINISH_CONFIGURATION_MODAL
          );
        }
      });
    }
  }, [
    engineAndPlanCheckData,
    promotionOverviewData?.schedule?.startsAt,
    onPublishPromotion,
  ]);

  const scrollToOfferList = useCallback(() => {
    scroller.scrollTo('OfferList', {
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  }, [scroller]);

  const togglePlanChangeModal = useCallback((level?: PlanLevelDtoEnum) => {
    setPlanToChangeModal(level);
  }, []);

  const primaryAction = useMemo(
    () =>
      promotionOverviewData?.possibleOperations?.includes(
        PromotionOperationTypeDtoEnum.PUBLISH
      ) && (
        <Button
          disabled={!offerListData?.items?.length || disablePromotionActions}
          loading={publishPromotionIsLoading || engineAndPlanCheckIsFetching}
          onClick={handleFinishConfigurationModal}
          variant='primary'
        >
          {i18n.translate(
            isDraftScheduled ? 'SchedulePromotion' : 'PublishPromotion'
          )}
        </Button>
      ),
    [
      promotionOverviewData?.possibleOperations,
      offerListData?.items?.length,
      disablePromotionActions,
      publishPromotionIsLoading,
      isDraftScheduled,
      engineAndPlanCheckIsFetching,
    ]
  );

  const handlePreviewButton = useCallback(() => {
    if (promotionOverviewData?.status === PromotionStatusDtoEnum.PUBLISHED) {
      const isSecretDeal =
        promotionOverviewData.settings?.triggerType ===
        TriggerTypeDtoEnum.PRIVATE;
      const viewPromotionUrl = promotionOverviewData.viewPromotionUrl || '';
      const defaultDiscountLink =
        promotionOverviewData.settings?.discountLink?.copy || '';
      window.open(
        isSecretDeal ? defaultDiscountLink : viewPromotionUrl,
        '_blank'
      );
    } else {
      setOverviewModal(PromotionOverviewModalsEnum.PROMOTION_TEST_MODAL);
    }
  }, [promotionOverviewData]);

  const handleDismissComponent = useCallback(() => {
    if (params.promotionId) {
      dismissComponent(
        DismissedEnum.PROMOTION_OVERVIEW_PAGE_DISMISSED_COMPONENTS,
        params.promotionId
      );
      setDismissedComponents((prev) => [...prev, params.promotionId as string]);
    }
  }, []);

  const secondaryActions = useMemo(() => {
    if (isPromotionEditable) {
      return !isSDCType
        ? [
            {
              content: i18n.translate(
                promotionOverviewData?.status ===
                  PromotionStatusDtoEnum.PUBLISHED
                  ? 'View'
                  : 'Preview'
              ),
              onAction: handlePreviewButton,
              disabled: !offerListData?.items?.length,
            },
          ]
        : [];
    } else {
      return [
        {
          content: i18n.translate(
            promotionOverviewData?.status === PromotionStatusDtoEnum.ARCHIVED
              ? 'RestorePromotion'
              : 'MoveToDraft'
          ),
          onAction:
            promotionOverviewData?.status === PromotionStatusDtoEnum.ARCHIVED
              ? onRestorePromotion
              : onDeactivatePromotion,
          disabled: disablePromotionActions,
        },
      ];
    }
  }, [
    isSDCType,
    promotionOverviewData?.status,
    offerListData?.items?.length,
    disablePromotionActions,
    handlePreviewButton,
  ]);

  const moreActionsVariant = useMemo(
    () => [
      {
        content: i18n.translate('PublishChanges'),
        icon: PlayIcon,
        onAction: handleFinishConfigurationModal,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.CONFIRM_UNPUBLISHED_CHANGES,
      },
      {
        content: i18n.translate('RollbackChanges'),
        icon: ReturnIcon,
        disabled: disablePromotionActions,
        onAction: onDiscardPromotion,
        id: PromotionOperationTypeDtoEnum.DISCARD_UNPUBLISHED_CHANGES,
      },
      {
        content: i18n.translate(`UnpublishPromotion`),
        icon: StopCircleIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.UNPUBLISH,
        onAction: () => {
          onDeactivatePromotion();
        },
      },
      {
        content: i18n.translate(`Duplicate`),
        icon: DuplicateIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.DUPLICATE,
        onAction: onDuplicatePromotion,
      },
      {
        content: i18n.translate(`Archive`),
        icon: ArchiveIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.ARCHIVE,
        onAction: onArchivePromotion,
      },
      {
        content: i18n.translate(`Delete`),
        icon: DeleteIcon,
        disabled: disablePromotionActions,
        id: PromotionOperationTypeDtoEnum.DELETE,
        onAction: () => {
          setOverviewModal(PromotionOverviewModalsEnum.DELETE_PROMOTION_MODAL);
        },
        destructive: true,
      },
    ],
    [i18n, disablePromotionActions]
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && checkConfiguration) {
        fetchEngineAndPlanCheck();
        setCheckConfiguration(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [checkConfiguration]);

  useEffect(() => {
    fetchEngineAndPlanCheck();
  }, []);

  return (
    <div className='PromotionOverview'>
      {promotionOverviewData && promotionWidgetsData && offerListData ? (
        <Page
          backAction={{
            onAction: () => navigate(`/${NavPaths.Promotions}`),
          }}
          title={promotionOverviewData.details?.name}
          subtitle={i18n.translate('PromotionSubtitle', {
            promotionType: i18n.translate(
              `${promotionOverviewData.settings?.promotionType}`
            ),
            triggerType: i18n.translate(
              `${promotionOverviewData.settings?.triggerType}`
            ),
          })}
          primaryAction={primaryAction}
          titleMetadata={
            <InlineStack gap='200'>
              <Badge
                tone={getPromotionStatusColor(
                  promotionOverviewData.status as PromotionStatusDtoEnum
                )}
              >
                {i18n.translate(`${promotionOverviewData.status}`)}
              </Badge>
              {isDataLoading ? (
                <Box paddingInline='300'>
                  <Loader size='small' />
                </Box>
              ) : null}
            </InlineStack>
          }
          secondaryActions={secondaryActions}
          actionGroups={[
            {
              title: i18n.translate('MoreActions'),
              actions: moreActionsVariant.filter((action) =>
                promotionOverviewData.possibleOperations?.includes(action.id)
              ),
            },
          ]}
        >
          <BlockStack gap='600'>
            {promotionOverviewData?.plans?.downgradedPlan && (
              <DowngradePlanBanner
                downgradedPlan={promotionOverviewData?.plans?.downgradedPlan}
                currentPlan={promotionOverviewData?.plans?.currentPlan}
                nextBillingCycleDate={
                  promotionOverviewData?.plans?.nextBillingCycleStartsAt
                }
                togglePlanModal={togglePlanChangeModal}
              />
            )}
            {promotionOverviewData?.plans?.suggestedUpgradePlan && (
              <ReachedLimitBanner
                currentPlan={promotionOverviewData?.plans?.currentPlan}
                suggestedPlan={
                  promotionOverviewData?.plans?.suggestedUpgradePlan
                }
                togglePlanModal={togglePlanChangeModal}
              />
            )}
            {promotionOverviewData.status ===
              PromotionStatusDtoEnum.PUBLISHED_EDITED && (
              <UnpublishedBanner
                publishedAtDate={promotionOverviewData.publishedAt}
                disabled={disablePromotionActions}
                onDiscard={onDiscardPromotion}
                onPublish={handleFinishConfigurationModal}
              />
            )}
            {shopifyDiscountCodeMissing && (
              <Banner
                title={i18n.translate('DiscountCodeMissing')}
                tone='warning'
              >
                <BlockStack inlineAlign='start' gap='200'>
                  <Text as='p'>{i18n.translate('ReviewTheOffers')}</Text>
                  <Button onClick={scrollToOfferList}>
                    {i18n.translate('GoToOffers')}
                  </Button>
                </BlockStack>
              </Banner>
            )}

            {(promotionOverviewData.status === PromotionStatusDtoEnum.USED ||
              promotionOverviewData.status ===
                PromotionStatusDtoEnum.EXPIRED) &&
              isUsedBanner && (
                <ExpiredBanner
                  status={promotionOverviewData.status}
                  usedDate={promotionOverviewData.usedDate}
                  disabled={disablePromotionActions}
                  onClose={() => setIsUsedBanner(false)}
                  onUnpublish={onDeactivatePromotion}
                />
              )}

            <Layout>
              <Layout.Section>
                <BlockStack gap='500'>
                  {params.promotionId &&
                    !dismissedComponents.includes(params.promotionId) && (
                      <PromotionSetupGuide
                        hasOfferAdded={!!offerListData?.totalItems}
                        promotionOverviewData={promotionOverviewData}
                        isPromotionEditable={isPromotionEditable}
                        promotionId={params.promotionId}
                        onComponentDismiss={handleDismissComponent}
                        handleFinishConfigurationModal={
                          handleFinishConfigurationModal
                        }
                        setScheduleModalType={setScheduleModalType}
                      />
                    )}
                  <OverviewOfferList
                    promotionType={
                      promotionOverviewData.settings
                        ?.promotionType as PromotionTypeDto
                    }
                    promotionId={params.promotionId || ''}
                    shopifyDiscountCodeMissing={shopifyDiscountCodeMissing}
                    isPromotionEditable={isPromotionEditable}
                    offerList={offerListData || {}}
                    offerListIsFetching={offerListIsFetching}
                    offerPage={offerPage}
                    refetchOfferList={refetchOfferList}
                    setOfferPage={setOfferPage}
                    refetchPromotionOverview={refetchPromotionOverviewData}
                  />
                  <OverviewWidgetList
                    widgets={promotionWidgetsData || {}}
                    offersConfigured={!!offerListData?.items?.length}
                    isPromotionEditable={isPromotionEditable}
                    refetchOverviewData={refetchPromotionWidgets}
                    refetchPromotionOverview={refetchPromotionOverviewData}
                  />
                  <Text as='p' tone='subdued'>
                    {i18n.translate('LastModified', {
                      created: (
                        <MomentAgo
                          prefix=''
                          timestamp={promotionOverviewData.createdAt || 0}
                        />
                      ),
                      modified: (
                        <MomentAgo
                          prefix=''
                          timestamp={promotionOverviewData.modifiedAt || 0}
                        />
                      ),
                      name: promotionOverviewData.modifiedBy,
                    })}
                  </Text>
                </BlockStack>
              </Layout.Section>
              <Layout.Section variant='oneThird'>
                <BlockStack gap='500'>
                  <PromotionStatus
                    promotionOverviewData={promotionOverviewData}
                    scheduleIsLoading={
                      schedulePromotionIsLoading || promotionOverviewIsFetching
                    }
                    isPromotionEditable={isPromotionEditable}
                    promotionId={params.promotionId || ''}
                    hasOffers={!!offerListData.items?.length}
                    setScheduleModalType={setScheduleModalType}
                    onSchedulePromotion={onSchedulePromotion}
                  />
                  <PromotionDetails
                    promotionDetails={
                      promotionOverviewData.details as PromotionOverviewDetailsDto
                    }
                    triggerType={promotionOverviewData.settings?.triggerType}
                    status={promotionOverviewData.status}
                    isPromotionEditable={isPromotionEditable}
                    refetchPromotionOverview={refetchPromotionOverviewData}
                  />
                  <OverviewPromotionSettings
                    settings={
                      promotionOverviewData.settings as PromotionOverviewSettingsDto
                    }
                    isPromotionEditable={isPromotionEditable}
                    shopifyCodeOfferMissing={shopifyCodeOfferMissing}
                    refetchPromotionOverview={refetchPromotionOverviewData}
                  />
                </BlockStack>
              </Layout.Section>
            </Layout>
          </BlockStack>
        </Page>
      ) : (
        <PromotionOverviewSkeleton />
      )}
      {scheduleModalType && (
        <SchedulePromotionModal
          promotionType={
            promotionOverviewData?.settings?.promotionType as PromotionTypeDto
          }
          schedule={promotionOverviewData?.schedule || {}}
          timezone={promotionOverviewData?.timezone as string}
          type={scheduleModalType}
          onClose={() => setScheduleModalType(null)}
          onSchedulePromotion={onSchedulePromotion}
        />
      )}
      <DeletePromotionModal
        isOpen={
          overviewModal === PromotionOverviewModalsEnum.DELETE_PROMOTION_MODAL
        }
        onClose={() => setOverviewModal(null)}
        onDeletePromotion={onDeletePromotion}
      />
      <DeactivatePromotionModal
        isOpen={
          overviewModal ===
          PromotionOverviewModalsEnum.UNPUBLISH_PROMOTION_MODAL
        }
        onClose={() => setOverviewModal(null)}
        onDeactivatePromotion={onDeactivatePromotion}
      />
      <PublishOrScheduleModal
        isOpen={
          overviewModal ===
          PromotionOverviewModalsEnum.PUBLISH_OR_SCHEDULE_MODAL
        }
        timezone={promotionOverviewData?.timezone as string}
        onPublish={onPublishPromotion}
        onSchedule={onSchedulePromotion}
        onClose={() => setOverviewModal(null)}
      />
      <SharePromotionModal
        isOpen={
          overviewModal === PromotionOverviewModalsEnum.SHARE_PROMOTION_MODAL
        }
        onClose={() => setOverviewModal(null)}
        promocode={promotionOverviewData?.settings?.promotionCodes?.singleCode}
        discountLink={promotionOverviewData?.settings?.discountLink}
      />
      {overviewModal === PromotionOverviewModalsEnum.PROMOTION_TEST_MODAL && (
        <PreviewPromotionModal
          isOpen={
            overviewModal === PromotionOverviewModalsEnum.PROMOTION_TEST_MODAL
          }
          isPromotionEngineEnabled={
            !!engineAndPlanCheckData?.promotionEngine?.enabled
          }
          isTestingEnabled={!!promotionOverviewData?.isTestingEnabled}
          onClose={() => setOverviewModal(null)}
        />
      )}
      {overviewModal ===
        PromotionOverviewModalsEnum.FINISH_CONFIGURATION_MODAL && (
        <FinishConfigurationModal
          isOpen={
            overviewModal ===
            PromotionOverviewModalsEnum.FINISH_CONFIGURATION_MODAL
          }
          engineAndPlanCheckData={engineAndPlanCheckData}
          engineAndPlanCheckIsFetching={engineAndPlanCheckIsFetching}
          setCheckConfiguration={setCheckConfiguration}
          onClose={() => setOverviewModal(null)}
          onPublishPromiton={onPublishPromotion}
          refetchEngineAndPlanCheck={fetchEngineAndPlanCheck}
        />
      )}
      {!!planToChangeModal && (
        <ChangePlanModal
          isOpen={!!planToChangeModal}
          targetPlanLevel={planToChangeModal}
          modalType={ChangePlanModalTypeEnum.CHANGE_PLAN}
          isMaxModal={false}
          onClose={() => togglePlanChangeModal()}
        />
      )}
    </div>
  );
};
