import {
  Card,
  BlockStack,
  Text,
  Icon,
  InlineStack,
  List,
  Page,
  Button,
  ButtonGroup,
  Tooltip,
  Banner,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCrossSellSpecificationDto,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
  OfferRuleValueTypeDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import './CrossSellDiscount.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { usePrevious } from 'core/hooks/usePrevious';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import {
  OfferCrossSellRequirementRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  OfferTargetTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  Controller,
  ControllerRenderProps,
  useController,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import { QuestionCircleIcon, DiscountIcon } from '@shopify/polaris-icons';
import {
  GrayBoxResourceType,
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
} from 'core/components/SearchFieldWithGrayBox';
import { SearchFieldWithGrayBox } from 'core/components/SearchFieldWithGrayBoxOfferWizard';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import './CrossSellDiscount.scss';
import { CaretDownIcon, CaretUpIcon } from '@shopify/polaris-icons';
import { setCurrentConfigPage } from 'core/store/offersWizardSlice';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import {
  boldMessageParts,
  generateMessage,
  generateRules,
  SectionTypeEnum,
} from '../CartRules/utils/utils';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';
import classNames from 'classnames';
import { GrayBoxCollectionsExclusions } from '../ProductsNew/components/Exclusions/Exclusions';
import { AdvancedSettings } from 'core/components/GrayBox/components/AdvancedSettings/AdvancedSettings';
import { getCurrencySymbol, parseCurrencyString } from 'core/utils';

export type CrossSellOfferSectionProps = {
  specification?: OfferCrossSellSpecificationDto;
  offerType: OfferTypeDtoEnum;
  onFormValidityChange(formIsValid: boolean): void;
  onSpecificationUpdate: (
    specification: OfferCrossSellSpecificationDto
  ) => void;
  setConfigureComponent: (data: boolean) => void;
  configureComponent: boolean;
};

type FormFields = {
  crossSellOffValue: number;
  yValue: number;
  numberOfProducts: number | null;
  numberOfProductsMax: number | null;
  productsMaxValidation: OfferRuleValueTypeDto;
  numberOfProductsExactly: number | null;
};
export const CrossSellDiscount: React.FC<CrossSellOfferSectionProps> = (
  props
) => {
  const {
    specification,
    onFormValidityChange,
    onSpecificationUpdate,
    offerType,
    setConfigureComponent,
    configureComponent,
  } = props;

  const isDebugOrLocal = useIsDebugOrLocal();

  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();

  const [i18n] = useI18n();

  const dispatch = useAppDispatch();

  const [currentSpecification, setCurrentSpecification] =
    useState<OfferCrossSellSpecificationDto>(specification || {});

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isAppliesToValid, setIsAppliesToValid] = useState<boolean>(true);
  const [isExclusionsValid, setIsExclusionsValid] = useState<boolean>(true);
  const [currentSelections, setCurrentSelections] = useState<string>('');
  const [collectionExclusions, setCollectionExclusions] = useState<string>('');
  const [showBanner, setShowBanner] = useState<boolean>(true);

  const prevSpecification = usePrevious(currentSpecification);

  const form = yup.object({
    productsMaxValidation: yup.string(),
    numberOfProducts: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value !== OfferRuleValueTypeDtoEnum.AT_MOST &&
          value !== OfferRuleValueTypeDtoEnum.EXACTLY,
        then: yup
          .number()
          .typeError(
            i18n.translate('CrossSellOffer.RuleNumberOfProductsRequired')
          )
          .min(1, i18n.translate('CrossSellOffer.RuleNumberOfProductsMin'))
          .integer()
          .moreThan(
            yup.ref('yValue'),
            i18n.translate('CrossSellOffer.RuleNumberOfProductsMoreThan')
          )
          .required(),
      }),
    numberOfProductsMax: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value === OfferRuleValueTypeDtoEnum.BETWEEN,
        then: yup
          .number()
          .typeError(
            i18n.translate('CrossSellOffer.RuleNumberOfProductsMaxRequired')
          )
          .min(1, i18n.translate('CrossSellOffer.RuleNumberOfProductsMaxMin'))
          .integer()
          .moreThan(
            yup.ref('numberOfProducts'),
            i18n.translate('CrossSellOffer.RuleNumberOfProductsMaxMoreThan')
          )
          .required(),
      })
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value === OfferRuleValueTypeDtoEnum.AT_MOST,
        then: yup
          .number()
          .typeError(
            i18n.translate('CrossSellOffer.RuleNumberOfProductsMaxRequired')
          )
          .min(1, i18n.translate('CrossSellOffer.RuleNumberOfProductsMaxMin'))
          .integer()
          .moreThan(
            yup.ref('yValue'),
            i18n.translate(
              'CrossSellOffer.RuleNumberOfProductsMaxMoreThanYvalue'
            )
          )
          .required(),
      }),
    numberOfProductsExactly: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when('productsMaxValidation', {
        is: (value: OfferRuleValueTypeDto) =>
          value === OfferRuleValueTypeDtoEnum.EXACTLY,
        then: yup
          .number()
          .typeError(
            i18n.translate('CrossSellOffer.numberOfProductsExactlyMaxRequired')
          )
          .min(1, i18n.translate('CrossSellOffer.numberOfProductsExactlyMin'))
          .integer()
          .moreThan(
            yup.ref('yValue'),
            i18n.translate(
              'CrossSellOffer.RuleNumberOfProductsExactlyMoreThanYvalue'
            )
          )
          .required(),
      }),
    crossSellOffValue: yup
      .number()
      .transform((value, originalValue) => parseCurrencyString(originalValue))
      .when([], {
        is: () =>
          currentSpecification.rule?.type ===
          OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT,
        then: yup
          .number()
          .typeError(i18n.translate('CrossSellOffer.RuleFixedAmountRequired'))
          .min(0.01, i18n.translate('CrossSellOffer.RuleFixedAmountMin'))
          .required(),
        otherwise: yup
          .number()
          .typeError(i18n.translate('CrossSellOffer.RulePercentageRequired'))
          .min(1, i18n.translate('CrossSellOffer.RulePercentageMin'))
          .max(100, i18n.translate('CrossSellOffer.RulePercentageMax'))
          .integer()
          .required(),
      }),
    yValue: yup
      .number()
      .typeError(i18n.translate('CrossSellOffer.RuleYrequired'))
      .min(1, i18n.translate('CrossSellOffer.RuleYMin'))
      .integer()
      .required(),
  });

  const resourceType = useMemo(() => {
    switch (currentSpecification.rule?.appliesTo?.type) {
      case OfferTargetTypeDtoEnum.COLLECTIONS:
        return ShopifyObjectTypeDtoEnum.COLLECTION;
      case OfferTargetTypeDtoEnum.PRODUCTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT;
      case OfferTargetTypeDtoEnum.VARIANTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT;
      default:
        return null;
    }
  }, [currentSpecification.rule?.appliesTo?.type]);

  const ruleCurrentList = useMemo(() => {
    const appliesTo = currentSpecification.rule?.appliesTo;
    const appliesToType = appliesTo?.type?.toLowerCase() as
      | keyof typeof appliesTo
      | undefined;

    if (
      appliesTo &&
      appliesToType &&
      appliesTo.type !== OfferTargetTypeDtoEnum.ALL
    ) {
      return (appliesTo[appliesToType] || []) as ShopifyObjectDto[];
    }

    return null;
  }, [currentSpecification.rule?.appliesTo]);

  const bullets = useMemo(
    () => generateRules(currentSpecification.rule, collectionExclusions, i18n),
    [currentSpecification.rule, i18n, collectionExclusions, generateRules]
  );

  const initialCrossSellOffValue: number = useMemo(
    () =>
      currentSpecification.rule?.type ===
      OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT
        ? (currentSpecification.rule.fixedAmount as number)
        : (currentSpecification.rule?.percentage as number),
    [currentSpecification.rule?.type, open]
  );

  const numberOfProductsMaxDefault: number | null = useMemo(
    () =>
      currentSpecification.rule?.value?.to
        ? currentSpecification.rule?.value?.to
        : currentSpecification.rule?.value?.from
        ? currentSpecification.rule?.value?.from + 1
        : null,
    [
      currentSpecification.rule?.value?.to,
      currentSpecification.rule?.value?.from,
      open,
    ]
  );

  const { control, formState, watch, trigger, resetField, getValues } =
    useForm<FormFields>({
      defaultValues: {
        crossSellOffValue: initialCrossSellOffValue || undefined,
        yValue: currentSpecification.rule?.count || undefined,
        productsMaxValidation: currentSpecification.rule?.value?.type,
        numberOfProducts: currentSpecification.rule?.value?.from || null,
        numberOfProductsMax: numberOfProductsMaxDefault,
        numberOfProductsExactly: currentSpecification.rule?.value?.exactly || 1,
      },
      mode: 'onChange',
      resolver: yupResolver(form),
    });

  const listNeeded = useMemo(
    () =>
      currentSpecification.rule?.appliesTo?.type ===
        OfferTargetTypeDtoEnum.ALL ||
      currentSpecification.rule?.appliesTo?.type ===
        OfferTargetTypeDtoEnum.COLLECTIONS
        ? 'collections'
        : currentSpecification.rule?.appliesTo?.type ===
          OfferTargetTypeDtoEnum.PRODUCTS
        ? 'products'
        : 'variants',
    [currentSpecification.rule?.appliesTo?.type, open]
  );

  const resourceTypeNeeded: GrayBoxResourceType = useMemo(
    () =>
      currentSpecification.rule?.appliesTo?.type ===
        OfferTargetTypeDtoEnum.ALL ||
      currentSpecification.rule?.appliesTo?.type ===
        OfferTargetTypeDtoEnum.COLLECTIONS
        ? 'Collection'
        : currentSpecification.rule?.appliesTo?.type ===
          OfferTargetTypeDtoEnum.PRODUCTS
        ? 'Product'
        : 'Product',
    [currentSpecification.rule?.appliesTo?.type, open]
  );

  const conditionalInputName1: string = useMemo(
    () =>
      currentSpecification.rule?.type ===
      OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT
        ? 'fixedAmount'
        : 'percentage',
    [currentSpecification.rule?.type, open]
  );

  const isFixedAmountType: boolean = useMemo(
    () =>
      currentSpecification.rule?.type ===
      OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT,
    [currentSpecification.rule?.type, open]
  );

  const conditionalInputName2 = useMemo(
    () =>
      currentSpecification.rule?.value?.type ===
      OfferRuleValueTypeDtoEnum.AT_MOST
        ? 'numberOfProductsMax'
        : currentSpecification.rule?.value?.type ===
          OfferRuleValueTypeDtoEnum.EXACTLY
        ? 'numberOfProductsExactly'
        : 'numberOfProducts',
    [currentSpecification.rule?.value?.type]
  );

  const isOfferSectionValid: boolean = useMemo(
    () => isAppliesToValid && isExclusionsValid && formState.isValid,
    [
      isAppliesToValid,
      isExclusionsValid,
      formState.isValid,
      formState.errors,
      open,
    ]
  );

  const warningTextLabel: string = useMemo(() => {
    switch (resourceTypeNeeded) {
      case GrayBoxResourceTypeEnum.Collection:
        return 'SearchFieldWarningText';
      case GrayBoxResourceTypeEnum.Product:
        if (
          currentSpecification.rule?.appliesTo?.type ===
          OfferTargetTypeDtoEnum.VARIANTS
        ) {
          return 'SearchFieldWarningText3';
        } else {
          return 'SearchFieldWarningText2';
        }
      default:
        return 'SearchFieldWarningText';
    }
  }, [resourceTypeNeeded, currentSpecification.rule?.appliesTo?.type, open]);

  const validationMessages = useMemo(() => {
    const errors = cloneDeep(formState.errors);
    const fieldsNeeded = [
      'crossSellOffValue',
      'yValue',
      'numberOfProductsMax',
      'numberOfProducts',
      'numberOfProductsExactly',
    ];
    Object.keys(errors).forEach((item) => {
      if (fieldsNeeded.indexOf(item) === -1) {
        delete errors[item as keyof typeof errors];
      }
    });

    return Object.values(errors).map((error) => error?.message)?.length ||
      !isAppliesToValid ? (
      <div className='CrossSellOfferErrorBoxWrapper'>
        {Object.values(errors)
          .map((error) => error?.message)
          .map((errorMessage, idx) => (
            <p key={idx} className='CrossSellOfferErrorBox'>
              {errorMessage}
            </p>
          ))}
        {!isAppliesToValid && (
          <p>{i18n.translate(`CrossSellOffer.${warningTextLabel}`)}</p>
        )}
      </div>
    ) : null;
  }, [formState, isAppliesToValid, open]);

  const updateCurrentSpecification = useCallback(
    (
      field: keyof OfferCrossSellSpecificationDto,
      data: OfferCrossSellSpecificationDto[keyof OfferCrossSellSpecificationDto]
    ) => {
      setCurrentSpecification((prevState: OfferCrossSellSpecificationDto) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setCurrentSpecification]
  );

  const changeValue = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      if (field.value !== parseFloat(value)) {
        field.onChange(parseFloat(value));
        if (field.name === 'yValue' && value) {
          currentSpecification.rule?.value?.type ===
          OfferRuleValueTypeDtoEnum.AT_MOST
            ? trigger('numberOfProductsMax')
            : currentSpecification.rule?.value?.type ===
              OfferRuleValueTypeDtoEnum.AT_LEAST
            ? trigger('numberOfProducts')
            : trigger('numberOfProductsExactly');
        }
        if (field.name === 'numberOfProducts' && value) {
          trigger('numberOfProductsMax');
        }
      }
    },
    [currentSpecification.rule?.value?.type, trigger]
  );

  const changeRuleOfferType = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      if (field.value !== value) {
        field.onChange(value);
        trigger('numberOfProductsMax');
        trigger('numberOfProducts');
        trigger('numberOfProductsExactly');
      }
    },
    [trigger]
  );

  const onAdvancedSettingsUpdate = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      setCurrentSpecification((prevState: OfferCrossSellSpecificationDto) => ({
        ...prevState,
        rule: {
          ...prevState.rule,
          advancedSettings: {
            ...prevState.rule?.advancedSettings,
            [field]: value,
          },
        },
      }));
    },
    []
  );

  const handleSelectChange = useCallback(
    (list: ResourceSelectionProps[]) => {
      setCurrentSpecification((prevState: OfferCrossSellSpecificationDto) => ({
        ...prevState,
        rule: {
          ...prevState.rule,
          appliesTo: {
            ...prevState.rule?.appliesTo,
            [listNeeded]: formateList(
              list,
              currentSpecification.rule?.appliesTo?.type
            ),
          },
        },
      }));
    },
    [
      listNeeded,
      currentSpecification.rule?.appliesTo?.type,
      formateList,
      setCurrentSpecification,
    ]
  );

  useEffect(() => {
    resetField('numberOfProducts', {
      defaultValue: currentSpecification?.rule?.value?.from,
    });
    resetField('numberOfProductsMax', {
      defaultValue:
        currentSpecification?.rule?.value?.to || numberOfProductsMaxDefault,
    });
    resetField('numberOfProductsExactly', {
      defaultValue: currentSpecification.rule?.value?.exactly || 1,
    });
    trigger('numberOfProducts');
    trigger('numberOfProductsMax');
    trigger('numberOfProductsExactly');
  }, [currentSpecification?.rule?.value?.type]);

  useEffect(() => {
    resetField('crossSellOffValue', {
      defaultValue:
        (currentSpecification.rule?.[
          conditionalInputName1 as keyof typeof currentSpecification.rule
        ] as number) || 0,
    });
    trigger('crossSellOffValue');
    trigger('yValue');
  }, [conditionalInputName1]);

  useEffect(() => {
    isChanged && onSpecificationUpdate(currentSpecification);
  }, [isChanged, currentSpecification, onSpecificationUpdate]);

  useEffect(() => {
    if (
      !isEmpty(currentSpecification) &&
      !isEmpty(prevSpecification) &&
      !isEqual(prevSpecification, currentSpecification)
    ) {
      setIsChanged(true);
    }
  }, [currentSpecification, prevSpecification, setIsChanged]);

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        updateCurrentSpecification('rule', {
          ...currentSpecification.rule,
          [conditionalInputName1]: parseCurrencyString(value.crossSellOffValue),
          count: value.yValue,
          value: {
            type: value.productsMaxValidation,
            from: value.numberOfProducts,
            to: value.numberOfProductsMax,
            exactly: value.numberOfProductsExactly,
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    updateCurrentSpecification,
    currentSpecification.rule,
    conditionalInputName1,
  ]);

  useEffect(() => {
    onFormValidityChange(isOfferSectionValid);
  }, [isOfferSectionValid]);

  const incrementDecrementValue = useCallback(
    (
      field: ControllerRenderProps<FormFields>,
      type: 'increment' | 'decrement'
    ) => {
      const currentValue =
        typeof getValues(field.name) === 'number' ? getValues(field.name) : 1;
      if (type === 'increment' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue + 1}`);
      } else if (type === 'decrement' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue - 1}`);
      }
    },
    [getValues]
  );

  const { field: conditionalInputName2Field } = useController({
    name: conditionalInputName2,
    control,
  });

  const { field: yValueField } = useController({
    name: 'yValue',
    control,
  });
  const { field: numberOfProductsMaxField } = useController({
    name: 'numberOfProductsMax',
    control,
  });

  const incrementDecrementButtons = useCallback(
    (field: ControllerRenderProps<FormFields>) => {
      return (
        <div className='Polaris-TextField__Spinner' aria-hidden='true'>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'increment');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretUpIcon} />
              </span>
            </div>
          </div>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'decrement');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretDownIcon} />
              </span>
            </div>
          </div>
        </div>
      );
    },
    [incrementDecrementValue]
  );

  const setExcludedResources = useCallback(
    (data: ResourceSelectionProps[]) => {
      setCurrentSpecification((prevState: OfferCrossSellSpecificationDto) => ({
        ...prevState,
        rule: {
          ...prevState.rule,
          exclusions: {
            ...prevState.rule?.exclusions,
            collections: {
              ...prevState.rule?.exclusions?.collections,
              value: formateList(
                data,
                currentSpecification.rule?.appliesTo?.type
              ) as ShopifyObjectDto[],
            },
          },
        },
      }));
    },
    [formateList, currentSpecification.rule?.appliesTo?.type]
  );

  useEffect(() => {
    configureComponent && dispatch(setCurrentConfigPage('specification'));
    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent]);

  useEffect(() => {
    if (resourceType && ruleCurrentList?.length) {
      getLookupShopifyDetailedObjectsDetails({
        type: resourceType,
        objects: ruleCurrentList,
      }).then((res) => {
        setCurrentSelections(
          headerTitle(res as ResourceSelectionProps[], resourceType, i18n)
        );
      });
    }
  }, [resourceType, ruleCurrentList]);

  useEffect(() => {
    if (
      !configureComponent &&
      currentSpecification.rule?.exclusions?.collections?.enabled &&
      currentSpecification.rule?.exclusions?.collections.value?.length
    ) {
      getLookupShopifyDetailedObjectsDetails({
        type: ShopifyObjectTypeDtoEnum.COLLECTION,
        objects: currentSpecification.rule?.exclusions?.collections.value,
      }).then((res) => {
        setCollectionExclusions(
          headerTitle(
            res as ResourceSelectionProps[],
            ShopifyObjectTypeDtoEnum.COLLECTION,
            i18n
          )
        );
      });
    }
  }, [currentSpecification.rule?.exclusions?.collections]);

  return (
    <>
      {!configureComponent ? (
        <Card roundedAbove='sm' padding='400'>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('Discount.discount') || 'Discount'}
              </Text>
              <Text as='span' tone='subdued'>
                {i18n.translate('Discount.discountSubtitle.' + offerType) ||
                  'The discount, expressed as a percentage or fixed amount, applies to the products selected in the next section.'}
              </Text>
            </BlockStack>
            <Card roundedAbove='sm' padding='400'>
              <InlineStack
                align='space-between'
                wrap={false}
                blockAlign='center'
              >
                {currentSpecification.rule?.appliesTo?.type ? (
                  <InlineStack wrap={false} gap='200' blockAlign='center'>
                    <div style={{ width: 20, height: 20 }}>
                      <Icon source={DiscountIcon} tone='base' />
                    </div>
                    {boldMessageParts(
                      generateMessage(
                        SectionTypeEnum.DISCOUNT,
                        currentSpecification.rule,
                        0,
                        currentSelections,
                        i18n
                      )
                    )}
                  </InlineStack>
                ) : (
                  <Text as='p' tone='subdued'>
                    {i18n.translate('Discount.NoDiscountConfigured')}
                  </Text>
                )}
                <BlockStack align='center'>
                  <Button
                    variant='plain'
                    onClick={async () => {
                      !isDebugOrLocal &&
                        (await shopify.saveBar.leaveConfirmation());
                      setConfigureComponent(true);
                    }}
                  >
                    {i18n.translate('Discount.Configure')}
                  </Button>
                </BlockStack>
              </InlineStack>
              {bullets.length ? (
                <div className='bullet'>
                  <List>
                    {bullets.map((bullet, index) => (
                      <List.Item key={index}>{bullet}</List.Item>
                    ))}
                  </List>
                </div>
              ) : null}
            </Card>
          </BlockStack>
        </Card>
      ) : (
        <Page
          backAction={{
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              setConfigureComponent(false);
            },
          }}
          title={i18n.translate('CrossSellOffer.PageTitle')}
        >
          <BlockStack gap='400'>
            <Card>
              <BlockStack gap='400'>
                <BlockStack gap='200'>
                  {validationMessages && (
                    <Banner tone='critical'>{validationMessages}</Banner>
                  )}
                  <InlineStack gap='200' blockAlign='center'>
                    {i18n.translate('CrossSellOffer.Title')}
                    <ButtonGroup variant='segmented'>
                      {Object.keys(
                        OfferCrossSellRequirementRuleTypeDtoEnum
                      ).map((key: string) => (
                        <Button
                          key={key}
                          onClick={() =>
                            setCurrentSpecification(
                              (prevState: OfferCrossSellSpecificationDto) => ({
                                ...prevState,
                                rule: {
                                  ...prevState.rule,
                                  type: key as OfferCrossSellRequirementRuleTypeDtoEnum,
                                },
                              })
                            )
                          }
                          pressed={currentSpecification.rule?.type === key}
                        >
                          {i18n.translate(`CrossSellOffer.${key}`)}
                        </Button>
                      ))}
                    </ButtonGroup>
                    <div
                      className={classNames(
                        'Polaris-TextField Polaris-TextField--hasValue',
                        {
                          'Polaris-TextField--error':
                            formState.errors.crossSellOffValue,
                        }
                      )}
                      style={{ width: '100px' }}
                    >
                      {isFixedAmountType && (
                        <div className='Polaris-TextField__Prefix'>
                          <span className='Polaris-Text--root Polaris-Text--bodyMd'>
                            {getCurrencySymbol()}
                          </span>
                        </div>
                      )}
                      <Controller<FormFields>
                        name='crossSellOffValue'
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            {...field}
                            className='Polaris-TextField__Input'
                            value={field.value}
                            decimalScale={isFixedAmountType ? 2 : 0}
                            decimalSeparator={
                              isFixedAmountType
                                ? i18n.numberSymbols().decimalSymbol
                                : undefined
                            }
                            thousandSeparator={
                              isFixedAmountType
                                ? i18n.numberSymbols().thousandSymbol
                                : undefined
                            }
                            min={0}
                            autoComplete='off'
                            onValueChange={({ floatValue }) =>
                              field.onChange(floatValue ?? undefined)
                            }
                          />
                        )}
                      />
                      {!isFixedAmountType && (
                        <div className='Polaris-TextField__Suffix'>
                          <span className='Polaris-Text--root Polaris-Text--bodyMd'>
                            %
                          </span>
                        </div>
                      )}
                      <div className='Polaris-TextField__Backdrop'></div>
                    </div>
                    {i18n.translate('CrossSellOffer.OffOn')}
                    <div
                      className={classNames(
                        'Polaris-TextField Polaris-TextField--hasValue',
                        {
                          'Polaris-TextField--error': formState.errors.yValue,
                        }
                      )}
                      style={{ width: '100px' }}
                    >
                      <Controller<FormFields>
                        name='yValue'
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            {...field}
                            className='Polaris-TextField__Input'
                            inputMode='numeric'
                            value={field.value as number}
                            decimalScale={0}
                            allowNegative={false}
                            autoComplete='off'
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => changeValue(field, e.target.value)}
                          />
                        )}
                      />
                      {incrementDecrementButtons(yValueField)}
                      <div className='Polaris-TextField__Backdrop'></div>
                    </div>
                    <InlineStack gap='100'>
                      {i18n.translate('CrossSellOffer.Products')}
                      <Tooltip
                        preferredPosition='mostSpace'
                        content={i18n.translate('CrossSellOffer.HoverProducts')}
                      >
                        <Icon source={QuestionCircleIcon} />
                      </Tooltip>
                    </InlineStack>{' '}
                  </InlineStack>
                  <InlineStack gap='200' blockAlign='center'>
                    {i18n.translate('CrossSellOffer.From')}
                    <ButtonGroup variant='segmented'>
                      {Object.keys(OfferTargetTypeDtoEnum).map(
                        (key: string) => (
                          <span key={key}>
                            <Button
                              pressed={
                                currentSpecification.rule?.appliesTo?.type ===
                                key
                              }
                              onClick={() =>
                                setCurrentSpecification(
                                  (
                                    prevState: OfferCrossSellSpecificationDto
                                  ) => ({
                                    ...prevState,
                                    rule: {
                                      ...prevState.rule,
                                      appliesTo: {
                                        ...prevState.rule?.appliesTo,
                                        type: key as OfferTargetTypeDtoEnum,
                                      },
                                    },
                                  })
                                )
                              }
                            >
                              {i18n.translate(`CrossSellOffer.${key}`)}
                            </Button>
                          </span>
                        )
                      )}
                    </ButtonGroup>
                  </InlineStack>
                  <InlineStack gap='200' blockAlign='center'>
                    {i18n.translate('CrossSellOffer.When')}
                    <Controller<FormFields>
                      name='productsMaxValidation'
                      control={control}
                      render={({ field }) => (
                        <ButtonGroup variant='segmented'>
                          {Object.keys(OfferRuleValueTypeDtoEnum).map(
                            (key: string) => (
                              <span key={key}>
                                <Button
                                  pressed={field.value === key}
                                  onClick={() =>
                                    changeRuleOfferType(field, key)
                                  }
                                >
                                  {i18n.translate(`CrossSellOffer.${key}`) ||
                                    key}
                                </Button>
                              </span>
                            )
                          )}
                        </ButtonGroup>
                      )}
                    />
                    <div
                      className={classNames(
                        'Polaris-TextField Polaris-TextField--hasValue',
                        {
                          'Polaris-TextField--error':
                            formState.errors[conditionalInputName2],
                        }
                      )}
                      style={{ width: '100px' }}
                    >
                      <Controller<FormFields>
                        name={conditionalInputName2}
                        control={control}
                        render={({ field }) => (
                          <NumberFormat
                            {...field}
                            inputMode='numeric'
                            value={field.value as number}
                            className='Polaris-TextField__Input'
                            decimalScale={0}
                            allowNegative={false}
                            autoComplete='off'
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              changeValue(field, e.target.value);
                            }}
                          />
                        )}
                      />
                      {incrementDecrementButtons(conditionalInputName2Field)}
                      <div className='Polaris-TextField__Backdrop'></div>
                    </div>
                    {currentSpecification.rule?.value?.type ===
                      OfferRuleValueTypeDtoEnum.BETWEEN && (
                      <>
                        {i18n.translate('CrossSellOffer.TextAnd')}
                        <div
                          className={classNames(
                            'Polaris-TextField Polaris-TextField--hasValue',
                            {
                              'Polaris-TextField--error':
                                formState.errors.numberOfProductsMax,
                            }
                          )}
                          style={{ width: '100px' }}
                        >
                          <Controller<FormFields>
                            name='numberOfProductsMax'
                            control={control}
                            render={({ field }) => (
                              <NumberFormat
                                {...field}
                                inputMode='numeric'
                                value={field.value as number}
                                className='Polaris-TextField__Input'
                                decimalScale={0}
                                allowNegative={false}
                                autoComplete='off'
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => changeValue(field, e.target.value)}
                              />
                            )}
                          />
                          {incrementDecrementButtons(numberOfProductsMaxField)}
                          <div className='Polaris-TextField__Backdrop'></div>
                        </div>
                      </>
                    )}
                    <InlineStack gap='100'>
                      {i18n.translate('CrossSellOffer.ToTheCart')}
                      <Tooltip
                        preferredPosition='mostSpace'
                        content='no content'
                      >
                        <Icon source={QuestionCircleIcon} />
                      </Tooltip>
                    </InlineStack>{' '}
                  </InlineStack>
                </BlockStack>
                {currentSpecification.rule?.appliesTo?.type !==
                  OfferTargetTypeDtoEnum.ALL && (
                  <>
                    <SearchFieldWithGrayBox
                      selectList={
                        currentSpecification.rule?.appliesTo?.[
                          listNeeded
                        ] as ResourceSelectionProps[]
                      }
                      resourceType={resourceTypeNeeded}
                      onSelectedChange={handleSelectChange}
                      onFormValidityChange={(formIsInvalid) =>
                        setIsAppliesToValid(!formIsInvalid)
                      }
                      showVariants={
                        currentSpecification.rule?.appliesTo?.type ===
                        OfferTargetTypeDtoEnum.VARIANTS
                      }
                      isCrossSell
                      ignoreValidation={true}
                    />
                    {showBanner && (
                      <Banner onDismiss={() => setShowBanner(false)}>
                        {i18n.translate('BannerContent')}
                      </Banner>
                    )}
                  </>
                )}
              </BlockStack>
            </Card>
            {currentSpecification.rule?.appliesTo?.type ===
              OfferTargetTypeDtoEnum.ALL ||
              (currentSpecification.rule?.appliesTo?.type ===
                OfferTargetTypeDtoEnum.COLLECTIONS && (
                <GrayBoxCollectionsExclusions
                  selectList={
                    (currentSpecification.rule.exclusions?.collections
                      ?.value as ShopifyObjectDto[]) || []
                  }
                  selectedProductsApplication={
                    currentSpecification.rule?.appliesTo?.type
                  }
                  enable={
                    !!currentSpecification.rule.exclusions?.collections?.enabled
                  }
                  onCheckboxValueChange={(value) =>
                    setCurrentSpecification(
                      (prevState: OfferCrossSellSpecificationDto) => ({
                        ...prevState,
                        rule: {
                          ...prevState.rule,
                          exclusions: {
                            ...prevState.rule?.exclusions,
                            collections: {
                              ...prevState.rule?.exclusions?.collections,
                              enabled: value,
                            },
                          },
                        },
                      })
                    )
                  }
                  onExcludedResourceSelection={setExcludedResources}
                  onFormValidityChange={(formIsInvalid) =>
                    setIsExclusionsValid(!formIsInvalid)
                  }
                />
              ))}
            <AdvancedSettings
              type={
                (currentSpecification.rule?.appliesTo?.type ||
                  OfferTargetTypeDtoEnum.ALL) as OfferTargetTypeDtoEnum
              }
              data={currentSpecification.rule?.advancedSettings}
              handleUpdateAdvancedSettings={onAdvancedSettingsUpdate}
            />
          </BlockStack>
        </Page>
      )}
    </>
  );
};
