import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, InlineStack, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  CustomShippingRateDto,
  CustomShippingRateSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { PlusIcon } from '@shopify/polaris-icons';
import { ConfigureShippingRateModal } from '../ConfigureShippingRateModal/ConfigureShippingRateModal';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import { ActionListInPopover } from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';

type CustomShippingRateListProps = {
  shippingRatesData: CustomShippingRateSettingsDto;
  shippingRatesIsFetching: boolean;
};
export const CustomShippingRateList: React.FC<CustomShippingRateListProps> = ({
  shippingRatesData,
  shippingRatesIsFetching,
}) => {
  const [i18n] = useI18n();
  const {
    createShippingRate,
    createShippingRateIsLoading,
    updateShippingRate,
    updateShippingRateIsLoading,
    deleteShippingRate,
    deleteShippingRateIsLoading,
  } = useConfigureSettings();

  const [rateListState, setRateListState] = useState<CustomShippingRateDto[]>(
    []
  );
  const [configureRateModal, setConfigureRateModal] =
    useState<CustomShippingRateDto | null>(null);

  const disableButtons = useMemo(
    () =>
      deleteShippingRateIsLoading ||
      updateShippingRateIsLoading ||
      shippingRatesIsFetching,
    [
      deleteShippingRateIsLoading,
      updateShippingRateIsLoading,
      shippingRatesIsFetching,
    ]
  );

  const handleUpdateShippingRate = useCallback(
    (data: CustomShippingRateDto) => {
      const onSuccess = (newRate?: CustomShippingRateDto) => {
        newRate
          ? setRateListState((prev) => [...prev, newRate])
          : setRateListState((prev) =>
              prev.map((rate) =>
                rate.id === data.id ? { ...rate, label: data.label } : rate
              )
            );

        setConfigureRateModal(null);
      };
      data.id
        ? updateShippingRate(data, onSuccess)
        : createShippingRate(data, onSuccess);
    },
    [setRateListState]
  );

  const handleDeteleShippingRate = useCallback(
    (id: string) => {
      const onSuccess = () => {
        setRateListState((prev) => prev.filter((rate) => rate.id !== id));
      };
      deleteShippingRate(id, onSuccess);
    },
    [setRateListState]
  );

  useEffect(() => {
    setRateListState(shippingRatesData.customShippingRates || []);
  }, [shippingRatesData]);

  return (
    <Box
      borderColor='border'
      borderWidth='025'
      borderRadius='200'
      paddingInline='300'
    >
      {rateListState?.map((rate) => (
        <Box
          key={rate.id}
          paddingBlock='300'
          borderBlockEndWidth='025'
          borderColor='border'
        >
          <InlineStack align='space-between'>
            <Text as='p'>{rate.label}</Text>
            <ActionListInPopover
              btnContent=''
              actionList={[
                {
                  content: i18n.translate('Edit'),
                  onAction: () => setConfigureRateModal(rate),
                  disabled: disableButtons,
                  icon: EditIcon,
                },
                {
                  content: i18n.translate('Remove'),
                  onAction: () => handleDeteleShippingRate(rate.id || ''),
                  destructive: true,
                  disabled: disableButtons,
                  icon: DeleteIcon,
                },
              ]}
            />
          </InlineStack>
        </Box>
      ))}
      <Box paddingBlock='300'>
        <Button
          variant='monochromePlain'
          icon={PlusIcon}
          onClick={() => setConfigureRateModal({})}
        >
          {i18n.translate('AddRate')}
        </Button>
      </Box>
      {!!configureRateModal && (
        <ConfigureShippingRateModal
          targetRate={configureRateModal}
          isLoading={createShippingRateIsLoading || updateShippingRateIsLoading}
          onUpdateRate={handleUpdateShippingRate}
          onClose={() => setConfigureRateModal(null)}
        />
      )}
    </Box>
  );
};
