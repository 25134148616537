import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferCrossSellDto,
  OfferCrossSellSpecificationDto,
  OfferCombinationsDto,
  OptionDtoOfferMinimumSpendDto,
  OfferDiscountLimitDto,
  OfferCartRulesDto,
  OfferPrerequisiteEntitledAppliesToDto,
  ShopifyObjectDto,
  OfferProductLimitDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OptionDtoString,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { CrossSellOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { isEmpty, isEqual } from 'lodash';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { OfferRuleValueTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import { Combinations } from '../../Combinations/Combinations';
import { CrossSellDiscount } from '../../CrossSellDiscount/CrossSellDiscount';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { BlockStack, Page } from '@shopify/polaris';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { useNavigate } from 'react-router-dom';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';
import { Products } from '../../ProductsNew/Products';

export const CrossSellOffer: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, handleOfferDataUpdate, savedData, offerType } = props;

  const [i18n] = useI18n();

  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const { currentCombination } = useAppSelector((state) => state.offersWizard);

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [configureDiscount, setConfigureDiscount] = useState<boolean>(false);
  const [configureProducts, setConfigureProducts] = useState<boolean>(false);
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);

  const { validityChangeHandler } = useOfferFormValidity();

  const [offerData, setOfferData] = useState<OfferCrossSellDto | null>(
    offerTemplate?.template as OfferCrossSellDto
  );

  const productAmount: string = useMemo(() => {
    const currentSpecificationRuleValue = !isEmpty(offerData?.specification)
      ? offerData?.specification?.rule?.value
      : (offerTemplate?.template as OfferCrossSellDto)?.specification?.rule
          ?.value;

    switch (currentSpecificationRuleValue?.type) {
      case OfferRuleValueTypeDtoEnum.AT_LEAST:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.AT_LEAST}`
        )} ${currentSpecificationRuleValue.from || 0}`;
      case OfferRuleValueTypeDtoEnum.EXACTLY:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.EXACTLY}`
        )} ${currentSpecificationRuleValue.exactly || 0}`;
      case OfferRuleValueTypeDtoEnum.AT_MOST:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.AT_MOST}`
        )} ${currentSpecificationRuleValue.to || 0}`;
      case OfferRuleValueTypeDtoEnum.BETWEEN:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.BETWEEN}`
        )} ${currentSpecificationRuleValue.from || 0} ${i18n.translate(
          'CrossSell.AND'
        )} ${currentSpecificationRuleValue.to || 0}`;
      default:
        return `${i18n.translate(
          `CrossSell.${OfferRuleValueTypeDtoEnum.AT_LEAST}`
        )} 1`;
    }
  }, [i18n, offerData?.specification, offerTemplate?.template]);

  const handleSpecificationDataUpdate = useCallback(
    (specification: OfferCrossSellSpecificationDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        specification: specification,
      }));
    },
    [setOfferData]
  );

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const appliesToDataUpdate = useCallback(
    (data: OfferPrerequisiteEntitledAppliesToDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          appliesTo: data,
        },
      }));
    },
    [setOfferData]
  );

  const exclusionsUpdate = useCallback(
    (data: boolean) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              enabled: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdateData = useCallback(
    (data: OfferDiscountLimitDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        discountLimit: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const excludedResourcesUpdate = useCallback(
    (data: ShopifyObjectDto[]) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              value: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdate = useCallback(
    (data: OfferProductLimitDto) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          limit: data,
        },
      }));
    },
    [setOfferData]
  );

  const nameFiltersUpdate = useCallback(
    (
      filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
      field: keyof OptionDtoString,
      value: string | boolean | null
    ) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          nameFilters: {
            ...prevState?.products?.nameFilters,
            [filterName]: {
              ...prevState?.products?.nameFilters?.[filterName],
              [field]: value,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const advancedSettingsUpdate = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      setOfferData((prevState: OfferCrossSellDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          advancedSettings: {
            ...prevState?.products?.advancedSettings,
            [field]: value,
          },
        },
      }));
    },
    [setOfferData]
  );

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  useEffect(() => {
    setOfferData((prevState: OfferCrossSellDto | null) => ({
      ...prevState,
      combinationType: currentCombination.currentCombinationType,
    }));
  }, [currentCombination.currentCombinationType]);

  return (
    <>
      {configureDiscount && (
        <CrossSellDiscount
          specification={
            (offerTemplate?.template as OfferCrossSellDto)?.specification
          }
          onFormValidityChange={validityChangeHandler(
            CrossSellOfferTabsEnum.Offer
          )}
          offerType={offerType}
          onSpecificationUpdate={handleSpecificationDataUpdate}
          setConfigureComponent={setConfigureDiscount}
          configureComponent={configureDiscount}
        />
      )}
      {configureProducts && (
        <Products
          products={(offerTemplate?.template as OfferCrossSellDto)?.products}
          onAppliesToUpdate={appliesToDataUpdate}
          onExclusionsUpdate={exclusionsUpdate}
          onExcludedResourcesUpdate={excludedResourcesUpdate}
          onLimitUpdate={limitUpdate}
          onFormValidityChange={validityChangeHandler(
            CrossSellOfferTabsEnum.Products
          )}
          onNameFiltersUpdate={nameFiltersUpdate}
          onAdvancedSettingsUpdate={advancedSettingsUpdate}
          savedData={(savedData?.template as OfferCrossSellDto)?.products}
          setConfigureComponent={setConfigureProducts}
          configureComponent={configureProducts}
        />
      )}
      {configureCartRules && (
        <CartRules
          productAmount={productAmount}
          savedData={(savedData?.template as OfferCrossSellDto)?.cartRules}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          onFormValidityChange={validityChangeHandler(
            CrossSellOfferTabsEnum.CartRules
          )}
          configureComponent={configureCartRules}
          specialOfferType={OfferTypeSpecialCases.CrossSell}
          groupIndex={groupIndex}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {!configureDiscount && !configureProducts && !configureCartRules && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={
                (offerTemplate?.template as OfferCrossSellDto).combinationType
              }
            />
            <CrossSellDiscount
              specification={
                (offerTemplate?.template as OfferCrossSellDto)?.specification
              }
              onFormValidityChange={validityChangeHandler(
                CrossSellOfferTabsEnum.Offer
              )}
              offerType={offerType}
              onSpecificationUpdate={handleSpecificationDataUpdate}
              setConfigureComponent={setConfigureDiscount}
              configureComponent={configureDiscount}
            />
            {(offerTemplate?.template as OfferCrossSellDto)?.products !==
              undefined && (
              <Products
                products={
                  (offerTemplate?.template as OfferCrossSellDto)?.products
                }
                onAppliesToUpdate={appliesToDataUpdate}
                onExclusionsUpdate={exclusionsUpdate}
                onExcludedResourcesUpdate={excludedResourcesUpdate}
                onLimitUpdate={limitUpdate}
                onFormValidityChange={validityChangeHandler(
                  CrossSellOfferTabsEnum.Products
                )}
                onNameFiltersUpdate={nameFiltersUpdate}
                onAdvancedSettingsUpdate={advancedSettingsUpdate}
                savedData={(savedData?.template as OfferCrossSellDto)?.products}
                setConfigureComponent={setConfigureProducts}
                configureComponent={configureProducts}
              />
            )}
            <CartRules
              productAmount={productAmount}
              savedData={(savedData?.template as OfferCrossSellDto)?.cartRules}
              onFormValidityChange={validityChangeHandler(
                CrossSellOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              specialOfferType={OfferTypeSpecialCases.CrossSell}
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <DiscountLimits
              type='Discount'
              isSetBasedDiscount
              discountLimit={
                (offerTemplate?.template as OfferCrossSellDto)?.discountLimit
              }
              maxQuantity={offerData?.specification?.rule?.count || 0}
              discountLimitUpdate={limitUpdateData}
              onFormValidityChange={validityChangeHandler(
                CrossSellOfferTabsEnum.Limit
              )}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferCrossSellDto)
                    ?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
          </BlockStack>
          {showHowModal && (
            <LearnMoreModal
              onModalClose={toggleShowHowModal}
              // url='https://youtu.be/9htdypfdlBM'
              title={i18n.translate('Tutorial')}
            />
          )}
        </Page>
      )}
    </>
  );
};
