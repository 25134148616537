import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionSettings.scss';
import {
  PromotionFiltersSettingsDto,
  PromotionSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import {
  setOverviewFilters,
  setPromotionSettingsValidityState,
  setSettingsAnchor,
} from 'core/store/promotionsSlice';
import { isEqualWith } from 'lodash';
import { BlockStack, Page, PageActions } from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { useSettingsFormValidity } from 'features/promotions/hooks/useSettingsFormValidity';
import { CardsSkeleton } from 'core/components';
import {
  SettingAudience,
  SettingBudget,
  SettingCountdownClock,
  SettingExclusivity,
  SettingGeolocationFilter,
  SettingReferrerFilter,
  SettingTrigger,
  SettingsFilters,
  SettingsVisitorBehavior,
} from './components';
import { SaveBar } from '@shopify/app-bridge-react';
import {
  PromotionTypeDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { SettingMarketFilter } from './components/SettingMarketFilter/SettingMarketFilter';
import { SettingPurchaseHistoryFilter } from './components/SettingPurchaseHistoryFilter/SettingPurchaseHistoryFilter';
import { scroller } from 'react-scroll';
type PromotionSettingsProps = {
  onlyFilters?: boolean;
};
export const PromotionSettings: React.FC<PromotionSettingsProps> = ({
  onlyFilters = false,
}) => {
  const {
    promotionSettingsData,
    promotionSettingsIsFetching,
    updatePromotionSettingsIsLoading,
    updatePromotionSettings,
  } = useConfigurePromotions(PromotionFetchTypeEnum.PROMOTION_SETTINGS);

  const { validityChangeHandler, formValidityControlArray } =
    useSettingsFormValidity();

  const [i18n] = useI18n();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isDebugOrLocal = useIsDebugOrLocal();
  const params = useParams();
  const { settingsAnchor, overviewFilters } = useAppSelector(
    (store) => store.promotions
  );
  const [savedData, setSavedData] = useState<PromotionSettingsDto | null>(null);
  const [settingsState, setSettingsState] =
    useState<PromotionSettingsDto | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [targetElementRendered, setTargetElementRendered] =
    useState<boolean>(false);

  const isSettingsHaveChanges = useMemo(
    () => !isEqualWith(savedData, settingsState),
    [savedData, settingsState]
  );

  const hasErrors = useMemo(
    () =>
      formValidityControlArray.current.some((form) => form.isValid === false),
    [formValidityControlArray.current]
  );

  const isShopifyDiscountCode = useMemo(
    () =>
      settingsState?.promotionType ===
      PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE,
    [settingsState?.promotionType]
  );

  const updateSettingsState = useCallback(
    (
      field: keyof PromotionSettingsDto,
      data: PromotionSettingsDto[keyof PromotionSettingsDto]
    ) => {
      setSettingsState((prevState: PromotionSettingsDto | null) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setSettingsState]
  );

  const handleUpdateFilters = useCallback(
    (
      field: keyof PromotionFiltersSettingsDto,
      data: PromotionFiltersSettingsDto[keyof PromotionFiltersSettingsDto]
    ) =>
      setSettingsState((prevState: PromotionSettingsDto | null) => ({
        ...prevState,
        filters: {
          ...prevState?.filters,
          [field]: data,
        },
      })),
    [setSettingsState]
  );
  const handleSaveSettings = useCallback(() => {
    if (!params.promotionId || !settingsState) return;
    updatePromotionSettings(
      params.promotionId,
      settingsState,
      setIsUpdating,
      () => {
        setSavedData(settingsState);
        setIsUpdating(false);
        dispatch(
          setToastMessages({
            error: false,
            message: 'PromotionSettingsUpdated',
          })
        );
      }
    );
  }, [settingsState, setSavedData, dispatch, setIsUpdating, params]);

  const navigateThrough = useCallback(
    async (path?: string) => {
      !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
      if (!path) {
        navigate(overviewFilters ? '../..' : '..', { relative: 'path' });
      } else {
        navigate(path);
      }
    },
    [navigate, isDebugOrLocal, overviewFilters]
  );

  useEffect(() => {
    const checkIfRendered = () => {
      const element = document.querySelector(`.${settingsAnchor}`);
      setTargetElementRendered(!!element);
    };
    // Initial check
    settingsAnchor && checkIfRendered();

    // Optional: Add a mutation observer to detect changes in the DOM
    const observer = new MutationObserver(checkIfRendered);
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, [settingsAnchor]);

  useEffect(() => {
    if (targetElementRendered && settingsAnchor) {
      scroller.scrollTo(settingsAnchor, {
        duration: 500,
        delay: 100,
        smooth: true,
      });
      dispatch(setSettingsAnchor(null));
    }
  }, [targetElementRendered]);

  useEffect(() => {
    if (promotionSettingsData && !promotionSettingsIsFetching) {
      setSavedData(promotionSettingsData);
      setSettingsState(promotionSettingsData);
    }
  }, [promotionSettingsData, promotionSettingsIsFetching]);

  useEffect(() => {
    return () => {
      dispatch(setOverviewFilters(false));
      dispatch(setPromotionSettingsValidityState([]));
    };
  }, []);

  return (
    <>
      {!isDebugOrLocal && (
        <SaveBar id='promotion-settings-bar' open={isSettingsHaveChanges}>
          <button
            // eslint-disable-next-line react/no-unknown-property
            variant='primary'
            onClick={handleSaveSettings}
            disabled={
              hasErrors || updatePromotionSettingsIsLoading || isUpdating
            }
          />
          <button
            onClick={() => setSettingsState(savedData)}
            disabled={updatePromotionSettingsIsLoading || isUpdating}
          />
        </SaveBar>
      )}
      <div className='PromotionSettings'>
        <Page
          title={i18n.translate(onlyFilters ? 'Filters' : 'PromotionSettings')}
          backAction={{
            onAction: () => navigateThrough(),
            id: 'PromotionSettingsBackNav',
          }}
        >
          {settingsState && !promotionSettingsIsFetching ? (
            <BlockStack gap='600'>
              {!onlyFilters && (
                <>
                  <SettingTrigger
                    trigger={settingsState.trigger}
                    updateSettingsState={(data) =>
                      updateSettingsState('trigger', data)
                    }
                    navigateThrough={navigateThrough}
                  />
                  <SettingsFilters
                    filters={settingsState.filters}
                    triggerType={settingsState.trigger?.triggerType}
                    navigateThrough={navigateThrough}
                  />
                  <SettingBudget
                    budget={settingsState.maximumUses}
                    updateSettingsState={(data) =>
                      updateSettingsState('maximumUses', data)
                    }
                  />
                  <SettingExclusivity
                    exclusivity={settingsState?.exclusivity}
                    updateSettingsState={(data) =>
                      updateSettingsState('exclusivity', data)
                    }
                  />
                  <SettingCountdownClock
                    countdownClock={settingsState.countdownClock}
                    updateSettingsState={(data) =>
                      updateSettingsState('countdownClock', data)
                    }
                  />
                </>
              )}
              {onlyFilters && (
                <>
                  {settingsState.trigger?.triggerType ===
                    TriggerTypeDtoEnum.PUBLIC && (
                    <SettingsVisitorBehavior
                      visitorBehavior={settingsState.filters?.visitorBehavior}
                      onFormValidityChange={validityChangeHandler(
                        'visitorBehavior'
                      )}
                      updateSettingsState={(data) =>
                        handleUpdateFilters('visitorBehavior', data)
                      }
                    />
                  )}

                  <SettingAudience
                    intendedAudience={settingsState?.filters?.intendedAudience}
                    isShopifyDiscountCode={isShopifyDiscountCode}
                    updateSettingsState={(data) =>
                      handleUpdateFilters('intendedAudience', data)
                    }
                    onFormValidityChange={validityChangeHandler(
                      'intendedAudience'
                    )}
                  />
                  <SettingPurchaseHistoryFilter
                    purchaseHistory={
                      settingsState.filters?.purchaseHistoryFilter
                    }
                    updateSettingsState={(data) =>
                      handleUpdateFilters('purchaseHistoryFilter', data)
                    }
                  />
                  <SettingMarketFilter
                    marketFilter={settingsState.filters?.marketFilter}
                    updateSettingsState={(data) =>
                      handleUpdateFilters('marketFilter', data)
                    }
                    onFormValidityChange={validityChangeHandler('marketFilter')}
                  />
                  <SettingGeolocationFilter
                    geolocationFilter={
                      settingsState?.filters?.geolocationFilter
                    }
                    updateSettingsState={(data) =>
                      handleUpdateFilters('geolocationFilter', data)
                    }
                    onFormValidityChange={validityChangeHandler(
                      'geolocationFilter'
                    )}
                  />
                  <SettingReferrerFilter
                    updateSettingsState={(data) =>
                      handleUpdateFilters('referrerFilter', data)
                    }
                    onFormValidityChange={validityChangeHandler(
                      'referrerFilter'
                    )}
                    referrerFilter={settingsState?.filters?.referrerFilter}
                  />
                </>
              )}
            </BlockStack>
          ) : (
            <CardsSkeleton cardsCount={4} />
          )}
          <PageActions
            primaryAction={{
              id: 'PromotionSettingsFooterSaveButton',
              content: i18n.translate('Save'),
              disabled:
                hasErrors ||
                updatePromotionSettingsIsLoading ||
                isUpdating ||
                !isSettingsHaveChanges,
              onAction: handleSaveSettings,
            }}
          />
        </Page>
      </div>
    </>
  );
};
