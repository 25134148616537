import React, { useCallback, useEffect, useState } from 'react';
import {
  OfferCombinationsDto,
  OfferPrerequisiteEntitledExclusionsDto,
  OfferProductLimitDto,
  OfferPrerequisiteEntitledAppliesToDto,
  OfferTierDto,
  OfferVolumeDiscountDto,
  OptionDtoOfferMinimumSpendDto,
  ShopifyObjectDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OptionDtoString,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { OfferTieredDiscountTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { cloneDeep, isEqual } from 'lodash';
import { Discount } from '../../DiscountNew/Discount';
import { VolumeOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { Tiers } from '../../TiersNew/Tiers';
import { Products } from '../../ProductsNew/Products';
import { Combinations } from '../../Combinations/Combinations';
import { usePrevious } from 'core/hooks/usePrevious';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { BlockStack, Page } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { OfferTierUpdateDto } from '../types/OfferTierUpdateDto';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';

export const VolumeOffer: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, offerType, handleOfferDataUpdate, savedData } = props;
  const [i18n] = useI18n();

  const { currentCombination } = useAppSelector((state) => state.offersWizard);

  const [showHowModal, setShowHowModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const [configureTiers, setConfigureTiers] = useState<boolean>(false);
  const [configureProducts, setConfigureProducts] = useState<boolean>(false);

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);
  const [offerData, setOfferData] = useState<OfferVolumeDiscountDto | null>(
    offerTemplate?.template as OfferVolumeDiscountDto
  );
  const [currentTiers, setCurrentTiers] = useState<OfferTierDto[]>(
    (offerTemplate?.template as OfferVolumeDiscountDto)?.tiers || []
  );
  const [isTiersUpdated, setIsTiersUpdated] = useState<boolean>(false);

  const { validityChangeHandler } = useOfferFormValidity();

  const prevTiers = usePrevious(currentTiers);

  const discountTypeDataUpdate = useCallback(
    (type: OfferTieredDiscountTypeDtoEnum) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        type: type,
      }));
    },
    [setOfferData]
  );

  const appliesToDataUpdate = useCallback(
    (data: OfferPrerequisiteEntitledAppliesToDto) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          appliesTo: data,
        },
      }));
    },
    [setOfferData]
  );

  const exclusionsUpdate = useCallback(
    (data: boolean) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              enabled: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const excludedResourcesUpdate = useCallback(
    (data: ShopifyObjectDto[]) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              value: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdate = useCallback(
    (data: OfferProductLimitDto) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          limit: data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    if (
      (offerTemplate?.template &&
        offerData &&
        !isEqual(offerData, offerTemplate?.template)) ||
      isTiersUpdated
    ) {
      handleOfferDataUpdate(offerData);
      setIsTiersUpdated(false);
    }
  }, [offerData, offerTemplate, isTiersUpdated]);

  useEffect(() => {
    const tempTiers = cloneDeep(currentTiers);
    if (
      offerData?.type === OfferTieredDiscountTypeDtoEnum.FIXED_AMOUNT &&
      currentTiers[0].entitledPreciseValue === null
    ) {
      tempTiers.map((tier) => {
        tier.entitledPreciseValue = tier.entitledWholeValue;
        tier.entitledWholeValue = null;
        return tier;
      });
      setCurrentTiers(tempTiers);
    } else if (
      offerData?.type === OfferTieredDiscountTypeDtoEnum.PERCENTAGE &&
      currentTiers[0].entitledWholeValue === null
    ) {
      tempTiers.map((tier) => {
        tier.entitledWholeValue = tier.entitledPreciseValue;
        tier.entitledPreciseValue = null;
        return tier;
      });
      setCurrentTiers(tempTiers);
    }
  }, [prevTiers, currentTiers, offerData?.type]);

  useEffect(() => {
    if (prevTiers && currentTiers && !isEqual(currentTiers, prevTiers)) {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        tiers: currentTiers,
      }));
      setIsTiersUpdated(true);
    }
  }, [prevTiers, currentTiers]);

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const advancedSettingsUpdate = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          advancedSettings: {
            ...prevState?.products?.advancedSettings,
            [field]: value,
          },
        },
      }));
    },
    [setOfferData]
  );

  const nameFiltersUpdate = useCallback(
    (
      filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
      field: keyof OptionDtoString,
      value: string | boolean | null
    ) => {
      setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          nameFilters: {
            ...prevState?.products?.nameFilters,
            [filterName]: {
              ...prevState?.products?.nameFilters?.[filterName],
              [field]: value,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const tierUpdate = useCallback(
    (data: OfferTierUpdateDto) => {
      const { tierIndex, tier } = data;
      const tiersCopy = currentTiers ? cloneDeep(currentTiers) : [];
      tiersCopy[tierIndex] = {
        ...tiersCopy[tierIndex],
        ...tier,
      };
      setCurrentTiers(tiersCopy);
    },
    [currentTiers]
  );

  const tierDelete = useCallback(
    (index: number) => {
      const tiersCopy = cloneDeep(currentTiers)?.filter(
        (tier, idx) => idx !== index
      );
      tiersCopy && setCurrentTiers(tiersCopy);
    },
    [currentTiers]
  );

  useEffect(() => {
    setOfferData((prevState: OfferVolumeDiscountDto | null) => ({
      ...prevState,
      combinationType: currentCombination.currentCombinationType,
    }));
  }, [currentCombination.currentCombinationType]);

  return (
    <>
      {configureTiers && (
        <Tiers
          key={currentTiers.toString()}
          tiers={currentTiers}
          discountType={
            offerData?.type ||
            (offerTemplate?.template as OfferVolumeDiscountDto)?.type
          }
          offerType={offerType}
          onFormValidityChange={validityChangeHandler(
            VolumeOfferTabsEnum.Tiers
          )}
          setCurrentTiers={setCurrentTiers}
          configureComponent={configureTiers}
          setConfigureComponent={setConfigureTiers}
          onTierUpdate={tierUpdate}
          onTierDelete={tierDelete}
        />
      )}
      {configureProducts && (
        <Products
          products={
            (offerTemplate?.template as OfferVolumeDiscountDto)?.products
          }
          onAppliesToUpdate={appliesToDataUpdate}
          onExclusionsUpdate={exclusionsUpdate}
          onExcludedResourcesUpdate={excludedResourcesUpdate}
          onLimitUpdate={limitUpdate}
          onFormValidityChange={validityChangeHandler(
            VolumeOfferTabsEnum.Products
          )}
          onNameFiltersUpdate={nameFiltersUpdate}
          onAdvancedSettingsUpdate={advancedSettingsUpdate}
          savedData={(savedData?.template as OfferVolumeDiscountDto)?.products}
          configureComponent={configureProducts}
          setConfigureComponent={setConfigureProducts}
        />
      )}
      {!configureTiers && !configureProducts && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={
                (offerTemplate?.template as OfferVolumeDiscountDto)
                  .combinationType
              }
            />
            <Discount
              offerType={offerType}
              type={(offerTemplate?.template as OfferVolumeDiscountDto).type}
              onTypeUpdate={discountTypeDataUpdate}
            />
            <Tiers
              tiers={currentTiers}
              discountType={
                offerData?.type ||
                (offerTemplate?.template as OfferVolumeDiscountDto)?.type
              }
              offerType={offerType}
              onFormValidityChange={validityChangeHandler(
                VolumeOfferTabsEnum.Tiers
              )}
              setCurrentTiers={setCurrentTiers}
              configureComponent={configureTiers}
              setConfigureComponent={setConfigureTiers}
              onTierUpdate={tierUpdate}
              onTierDelete={tierDelete}
            />
            {(offerTemplate?.template as OfferVolumeDiscountDto)?.products !==
              undefined && (
              <Products
                products={
                  (offerTemplate?.template as OfferVolumeDiscountDto)?.products
                }
                onAppliesToUpdate={appliesToDataUpdate}
                onExclusionsUpdate={exclusionsUpdate}
                onExcludedResourcesUpdate={excludedResourcesUpdate}
                onLimitUpdate={limitUpdate}
                onNameFiltersUpdate={nameFiltersUpdate}
                onAdvancedSettingsUpdate={advancedSettingsUpdate}
                onFormValidityChange={validityChangeHandler(
                  VolumeOfferTabsEnum.Products
                )}
                savedData={
                  (savedData?.template as OfferVolumeDiscountDto)?.products
                }
                configureComponent={configureProducts}
                setConfigureComponent={setConfigureProducts}
              />
            )}
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferVolumeDiscountDto)
                    ?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
