import React, { useCallback, useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Text,
  RadioButton,
  Icon,
  Card,
  BlockStack,
  Link,
  InlineStack,
  Banner,
} from '@shopify/polaris';

import { AlertCircleIcon } from '@shopify/polaris-icons';
import { OfferShippingRateTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferShippingRateTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import classNames from 'classnames';
import { useShippingRates } from 'core/hooks/useShippingRates';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import { getCurrencySymbol } from 'core/utils';
type LocaleNumberFormattingSettingsModel = {
  thousandSymbol: string;
  decimalSymbol: string;
};
type ShippingAppliesToProps = {
  thresholdTextFieldValue: NumberFormatValues;
  selectedShippingRates: string[];
  setThresholdTextFieldValue: (data: NumberFormatValues) => void;
  deliveryMethodsOption: OfferShippingRateTypeDto;
  setDeliveryMethodsOption: (
    deliveryMethodsOption: OfferShippingRateTypeDto
  ) => void;
  updateValidity(index: number, isValid: boolean): void;
  setSelectedShippingRates: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ShippingAppliesTo: React.FC<ShippingAppliesToProps> = (props) => {
  const {
    deliveryMethodsOption,
    selectedShippingRates,
    thresholdTextFieldValue,
    setDeliveryMethodsOption,
    setThresholdTextFieldValue,
    setSelectedShippingRates,
    updateValidity,
  } = props;
  const { shippingRatesListData } = useShippingRates(deliveryMethodsOption);

  const [i18n] = useI18n();
  const inputValueNotValid: boolean = useMemo(
    () => thresholdTextFieldValue.value === '',
    [thresholdTextFieldValue.value]
  );

  useEffect(() => {
    if (deliveryMethodsOption === OfferShippingRateTypeDtoEnum.ALL_THRESHOLD) {
      updateValidity(0, !inputValueNotValid);
    } else if (
      [
        OfferShippingRateTypeDtoEnum.SELECTED,
        OfferShippingRateTypeDtoEnum.SELECTED_OTHER_OFFER,
      ].includes(deliveryMethodsOption as OfferShippingRateTypeDtoEnum) &&
      !selectedShippingRates.length
    ) {
      updateValidity(0, false);
    } else {
      updateValidity(0, true);
    }
  }, [inputValueNotValid, deliveryMethodsOption, selectedShippingRates]);

  const localeNumberFormattingSettings: LocaleNumberFormattingSettingsModel =
    i18n.numberSymbols();
  const maxAllowedValueRule = useCallback((data: NumberFormatValues) => {
    if (!data.floatValue) {
      return true;
    }
    return data.floatValue <= 2147483647;
  }, []);

  const onRadioButtonValueChange = useCallback(
    (data: OfferShippingRateTypeDtoEnum) =>
      (_newValue: boolean, _id: string) => {
        setDeliveryMethodsOption(data);
      },
    [setDeliveryMethodsOption]
  );

  return (
    <>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('ShippingRates')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('ShippingRatesSubtitle', {
                link: <Link url='/'>{i18n.translate('FollowingArticle')}</Link>,
              })}
            </Text>
          </BlockStack>
          <BlockStack gap='025'>
            {Object.entries(OfferShippingRateTypeDtoEnum).map(
              ([key, value]: [string, OfferShippingRateTypeDtoEnum]) => (
                <RadioButton
                  key={key}
                  label={i18n.translate(`${key}`) || value}
                  name={i18n.translate(`${key}`) || value}
                  checked={deliveryMethodsOption === value}
                  onChange={onRadioButtonValueChange(value)}
                />
              )
            )}
          </BlockStack>
          {[
            OfferShippingRateTypeDtoEnum.SELECTED,
            OfferShippingRateTypeDtoEnum.SELECTED_OTHER_OFFER,
          ].includes(deliveryMethodsOption as OfferShippingRateTypeDtoEnum) && (
            <MultiselectTagCombobox
              id='SelectShippingRates'
              tagsInside
              label={i18n.translate('SelectShippingRates')}
              suggestions={
                shippingRatesListData?.map((rate) => rate.title) as string[]
              }
              placeholder={i18n.translate('SelectShippingRatesPlaceHolder')}
              requiredIndicator
              labelHidden={false}
              selectedTags={selectedShippingRates.map(
                (selectedId) =>
                  shippingRatesListData?.find((rate) => rate.id === selectedId)
                    ?.title || ''
              )}
              withAddFunc={false}
              setSelectedTags={(tags: string[]) => {
                const selectedIds = tags
                  .map(
                    (tag) =>
                      shippingRatesListData?.find((rate) => rate.title === tag)
                        ?.id || ''
                  )
                  .filter(Boolean);
                setSelectedShippingRates(selectedIds);
              }}
            />
          )}
          {deliveryMethodsOption ===
            OfferShippingRateTypeDtoEnum.ALL_THRESHOLD && (
            <>
              <BlockStack gap='100'>
                <Text as='p'>
                  {' '}
                  {i18n.translate('Threshold') || 'Threshold'}
                </Text>
                <div
                  className={classNames(
                    'Polaris-TextField Polaris-TextField--hasValue',
                    {
                      'Polaris-TextField--error': inputValueNotValid,
                    }
                  )}
                  style={{ maxWidth: '100px' }}
                >
                  <div className='Polaris-TextField__Prefix'>
                    <span className='Polaris-Text--root Polaris-Text--bodyMd'>
                      {getCurrencySymbol()}
                    </span>
                  </div>
                  <NumberFormat
                    className='Polaris-TextField__Input'
                    value={thresholdTextFieldValue.value}
                    decimalSeparator={
                      localeNumberFormattingSettings.decimalSymbol
                    }
                    thousandSeparator={
                      localeNumberFormattingSettings.thousandSymbol
                    }
                    allowNegative={false}
                    allowEmptyFormatting
                    isNumericString
                    decimalScale={2}
                    onValueChange={setThresholdTextFieldValue}
                    isAllowed={maxAllowedValueRule}
                  />
                  <div className='Polaris-TextField__Backdrop'></div>
                </div>
                {inputValueNotValid && (
                  <Text as='p' tone='critical'>
                    <InlineStack gap='100'>
                      <span>
                        {' '}
                        <Icon source={AlertCircleIcon} />
                      </span>

                      {i18n.translate('ValidationMsg') || 'Value is required'}
                    </InlineStack>
                  </Text>
                )}
              </BlockStack>
              <Banner>{i18n.translate('HelpText')}</Banner>
            </>
          )}
        </BlockStack>
      </Card>
    </>
  );
};
