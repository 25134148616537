export enum FilterTypeDtoEnum {
    INCLUDED = "INCLUDED",
    EXCLUDED = "EXCLUDED"
}
export enum IntendedAudienceTagTypeDtoEnum {
    ALL = "ALL",
    INCLUDE = "INCLUDE",
    EXCLUDE = "EXCLUDE"
}
export enum OfferBogoCustomRuleTypeDtoEnum {
    PERCENTAGE = "PERCENTAGE",
    FIXED_AMOUNT = "FIXED_AMOUNT"
}
export enum OfferBogoTypeDtoEnum {
    ONE_PLUS_ONE = "ONE_PLUS_ONE",
    ONE_PLUS_HALF = "ONE_PLUS_HALF",
    THREE_FOR_TWO = "THREE_FOR_TWO",
    CUSTOM = "CUSTOM"
}
export enum OfferCartContentRequirementProductMatchTypeDtoEnum {
    SAME_PRODUCT = "SAME_PRODUCT",
    SAME_VARIANT = "SAME_VARIANT"
}
export enum OfferCartRuleGroupConnectorDtoEnum {
    AND = "AND",
    BUT_NOT = "BUT_NOT"
}
export enum OfferCombinationListItemTypeDtoEnum {
    DISCOUNT_NINJA_OFFER = "DISCOUNT_NINJA_OFFER",
    SHOPIFY_DISCOUNT_CODE = "SHOPIFY_DISCOUNT_CODE"
}
export enum OfferCombinationStackingBehaviorDtoEnum {
    LARGEST_ONLY = "LARGEST_ONLY",
    STACK = "STACK"
}
export enum OfferCombinationTypeDtoEnum {
    PRODUCT = "PRODUCT",
    ORDER = "ORDER",
    SHIPPING = "SHIPPING"
}
export enum OfferCrossSellRequirementRuleTypeDtoEnum {
    PERCENTAGE = "PERCENTAGE",
    FIXED_AMOUNT = "FIXED_AMOUNT"
}
export enum OfferDefaultGiftRuleDtoEnum {
    MANUAL_BY_CUSTOMER = "MANUAL_BY_CUSTOMER",
    AUTOMATIC_FIRST_VARIANT = "AUTOMATIC_FIRST_VARIANT",
    AUTOMATIC_SELECTED_VARIANT = "AUTOMATIC_SELECTED_VARIANT"
}
export enum OfferFreeShippingRateTypeDtoEnum {
    FREE_SHIPPING = "FREE_SHIPPING",
    FLAT_FEE_SHIPPING_RATE = "FLAT_FEE_SHIPPING_RATE"
}
export enum OfferGiftTypeDtoEnum {
    COLLECTIONS = "COLLECTIONS",
    PRODUCT = "PRODUCT",
    PRODUCT_VARIANT = "PRODUCT_VARIANT"
}
export enum OfferLevelDtoEnum {
    LINE = "LINE",
    ORDER = "ORDER"
}
export enum OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum {
    VARIANTS_WITHOUT_COMPARE_AT_PRICE = "VARIANTS_WITHOUT_COMPARE_AT_PRICE",
    VARIANTS_WITH_COMPARE_AT_PRICE = "VARIANTS_WITH_COMPARE_AT_PRICE",
    ALL_VARIANTS = "ALL_VARIANTS"
}
export enum OfferPrerequisiteEntitledPurchaseTypeDtoEnum {
    ONE_TIME_PURCHASE = "ONE_TIME_PURCHASE",
    SUBSCRIPTION = "SUBSCRIPTION",
    ONE_TIME_PURCHASE_AND_SUBSCRIPTION = "ONE_TIME_PURCHASE_AND_SUBSCRIPTION"
}
export enum OfferProposedItemActionTypeDtoEnum {
    PROPOSE_IN_BUILDING_BLOCKS = "PROPOSE_IN_BUILDING_BLOCKS",
    AUTO_ADD = "AUTO_ADD"
}
export enum OfferRelationalCombinationTypeDtoEnum {
    PRODUCT = "PRODUCT",
    OTHER_PRODUCT = "OTHER_PRODUCT",
    ORDER = "ORDER",
    OTHER_ORDER = "OTHER_ORDER",
    SHIPPING = "SHIPPING"
}
export enum OfferRuleConditionGroupConnectorDtoEnum {
    AND = "AND",
    BUT_NOT = "BUT_NOT"
}
export enum OfferRuleTypeDtoEnum {
    MINIMUM_ITEMS_QUANTITY = "MINIMUM_ITEMS_QUANTITY",
    MINIMUM_PURCHASE_AMOUNT = "MINIMUM_PURCHASE_AMOUNT"
}
export enum OfferRuleValueTypeDtoEnum {
    AT_LEAST = "AT_LEAST",
    AT_MOST = "AT_MOST",
    BETWEEN = "BETWEEN",
    EXACTLY = "EXACTLY"
}
export enum OfferSalesDiscountTypeDtoEnum {
    PERCENTAGE = "PERCENTAGE",
    FIXED_AMOUNT = "FIXED_AMOUNT",
    FIXED_PRICE_PER_UNIT = "FIXED_PRICE_PER_UNIT"
}
export enum OfferShippingRateTypeDtoEnum {
    ALL = "ALL",
    ALL_THRESHOLD = "ALL_THRESHOLD",
    SELECTED = "SELECTED",
    SELECTED_OTHER_OFFER = "SELECTED_OTHER_OFFER"
}
export enum OfferSuggestedProductsDisplayOrderDtoEnum {
    CUSTOM = "CUSTOM",
    PRICE = "PRICE",
    INVENTORY = "INVENTORY"
}
export enum OfferSuggestedRuleTypeDtoEnum {
    USER_DEFINED = "USER_DEFINED",
    AI_SUGGESTED = "AI_SUGGESTED"
}
export enum OfferTargetTypeDtoEnum {
    ALL = "ALL",
    COLLECTIONS = "COLLECTIONS",
    PRODUCTS = "PRODUCTS",
    VARIANTS = "VARIANTS"
}
export enum OfferTieredDiscountTypeDtoEnum {
    PERCENTAGE = "PERCENTAGE",
    FIXED_AMOUNT = "FIXED_AMOUNT"
}
export enum OfferTypeDtoEnum {
    SALES_PERCENTAGE = "SALES_PERCENTAGE",
    SALES_FIXED_AMOUNT = "SALES_FIXED_AMOUNT",
    SALES_FIXED_PRICE = "SALES_FIXED_PRICE",
    GIFT_AUTOMATIC = "GIFT_AUTOMATIC",
    GIFT_MANUAL = "GIFT_MANUAL",
    CROSS_SELL = "CROSS_SELL",
    TIERED_SPEND_X_GET_Y = "TIERED_SPEND_X_GET_Y",
    TIERED_BOGO = "TIERED_BOGO",
    BOGO_REGULAR = "BOGO_REGULAR",
    FREE_SHIPPING = "FREE_SHIPPING",
    SHIPPING_PERCENTAGE = "SHIPPING_PERCENTAGE",
    SHIPPING_FIXED_AMOUNT = "SHIPPING_FIXED_AMOUNT",
    SHOPIFY_DISCOUNT_CODE_REGULAR = "SHOPIFY_DISCOUNT_CODE_REGULAR",
    SHOPIFY_DISCOUNT_CODE_GROUP = "SHOPIFY_DISCOUNT_CODE_GROUP",
    SHOPIFY_DISCOUNT_CODE_APP = "SHOPIFY_DISCOUNT_CODE_APP",
    VOLUME_DISCOUNT = "VOLUME_DISCOUNT",
    FREQUENTLY_BOUGHT_TOGETHER = "FREQUENTLY_BOUGHT_TOGETHER",
    ORDER_BUMP = "ORDER_BUMP",
    BOGO_STRICT_MATCH = "BOGO_STRICT_MATCH",
    BUNDLE_ORDER_DISCOUNT = "BUNDLE_ORDER_DISCOUNT"
}
export enum PlanLevelDtoEnum {
    DEVELOPMENT = "DEVELOPMENT",
    STARTER_1 = "STARTER_1",
    STARTER_2 = "STARTER_2",
    STARTER_3 = "STARTER_3",
    STARTER_4 = "STARTER_4",
    PRO_1 = "PRO_1",
    PRO_2 = "PRO_2",
    PRO_3 = "PRO_3",
    PRO_4 = "PRO_4",
    PLUS_1 = "PLUS_1",
    PLUS_2 = "PLUS_2",
    PLUS_3 = "PLUS_3",
    PLUS_4 = "PLUS_4",
    ENTERPRISE = "ENTERPRISE"
}
export enum PromotionCodeComplexityDtoEnum {
    SIMPLE = "SIMPLE",
    MEDIUM = "MEDIUM",
    ADVANCED = "ADVANCED"
}
export enum PromotionCodeForPromotionListSortFieldDtoEnum {
    STATUS = "STATUS",
    CODE = "CODE",
    USAGE = "USAGE"
}
export enum PromotionCodeMultipleModeEnum {
    SINGLE = "SINGLE",
    MULTIPLE = "MULTIPLE"
}
export enum PromotionCodeStatusDtoEnum {
    ACTIVE = "ACTIVE",
    USED = "USED"
}
export enum PromotionCodesBatchOperationTypeDtoEnum {
    REMOVE = "REMOVE"
}
export enum PromotionListSortFieldDtoEnum {
    NAME = "NAME",
    TOKEN = "TOKEN",
    STATUS = "STATUS",
    SCHEDULE = "SCHEDULE",
    TRIGGER_TYPE = "TRIGGER_TYPE",
    PROMOTION_TYPE = "PROMOTION_TYPE",
    OFFERS = "OFFERS",
    MODIFICATION_DATE = "MODIFICATION_DATE"
}
export enum PromotionOperationTypeDtoEnum {
    PUBLISH = "PUBLISH",
    UNPUBLISH = "UNPUBLISH",
    CONFIRM_UNPUBLISHED_CHANGES = "CONFIRM_UNPUBLISHED_CHANGES",
    DISCARD_UNPUBLISHED_CHANGES = "DISCARD_UNPUBLISHED_CHANGES",
    ARCHIVE = "ARCHIVE",
    DUPLICATE = "DUPLICATE",
    RESTORE = "RESTORE",
    DELETE = "DELETE",
    MOVE_TO_DRAFT = "MOVE_TO_DRAFT"
}
export enum PromotionSalesChannelDtoEnum {
    ALL = "ALL",
    ONLINE_STORE = "ONLINE_STORE"
}
export enum PromotionStatusDtoEnum {
    PUBLISHED = "PUBLISHED",
    SCHEDULED = "SCHEDULED",
    EXPIRED = "EXPIRED",
    DRAFT = "DRAFT",
    IN_PROGRESS_PUBLISHING = "IN_PROGRESS_PUBLISHING",
    IN_PROGRESS_UNPUBLISHING = "IN_PROGRESS_UNPUBLISHING",
    IN_PROGRESS_ROLLING_BACK = "IN_PROGRESS_ROLLING_BACK",
    USED = "USED",
    ARCHIVED = "ARCHIVED",
    PUBLISHED_EDITED = "PUBLISHED_EDITED"
}
export enum PromotionTypeDtoEnum {
    MARKDOWN = "MARKDOWN",
    SPECIAL_OFFER = "SPECIAL_OFFER",
    CROSS_SELL_UP_SELL = "CROSS_SELL_UP_SELL",
    SHOPIFY_DISCOUNT_CODE = "SHOPIFY_DISCOUNT_CODE"
}
export enum PromotionsValidationErrorCodeEnum {
    TAG_INVALID = "TAG_INVALID",
    PROMOTION_TOKEN_ALREADY_TAKEN = "PROMOTION_TOKEN_ALREADY_TAKEN",
    TRIGGER_INTENDED_AUDIENCE_TAGS_ARE_MISSING = "TRIGGER_INTENDED_AUDIENCE_TAGS_ARE_MISSING",
    TRIGGER_MAXIMUM_USES_LIMITED_USE_BY_ALL_HAS_INVALID_VALUE = "TRIGGER_MAXIMUM_USES_LIMITED_USE_BY_ALL_HAS_INVALID_VALUE",
    TRIGGER_MAXIMUM_USES_LIMITED_USE_BY_ALL_IS_MISSING = "TRIGGER_MAXIMUM_USES_LIMITED_USE_BY_ALL_IS_MISSING",
    TRIGGER_EXCLUSIVITY_PRIORITY_IS_MISSING = "TRIGGER_EXCLUSIVITY_PRIORITY_IS_MISSING",
    TRIGGER_EXCLUSIVITY_PRIORITY_IS_INVALID = "TRIGGER_EXCLUSIVITY_PRIORITY_IS_INVALID",
    TRIGGER_GEOLOCATION_FILTER_COUNTRIES_ARE_MISSING = "TRIGGER_GEOLOCATION_FILTER_COUNTRIES_ARE_MISSING",
    TRIGGER_REFERRER_FILTER_REFERRERS_ARE_MISSING = "TRIGGER_REFERRER_FILTER_REFERRERS_ARE_MISSING",
    TRIGGER_REFERRER_FILTER_DOMAIN_NAMES_ARE_MISSING = "TRIGGER_REFERRER_FILTER_DOMAIN_NAMES_ARE_MISSING",
    TRIGGER_VISITOR_BEHAVIOR_TIME_SPENT_ON_PAGE_HAS_INVALID_VALUE = "TRIGGER_VISITOR_BEHAVIOR_TIME_SPENT_ON_PAGE_HAS_INVALID_VALUE",
    TRIGGER_VISITOR_BEHAVIOR_NUMBER_OF_PAGES_VISITED_HAS_INVALID_VALUE = "TRIGGER_VISITOR_BEHAVIOR_NUMBER_OF_PAGES_VISITED_HAS_INVALID_VALUE",
    TRIGGER_VISITOR_BEHAVIOR_PERCENTAGE_OF_PAGE_VIEWED_HAS_INVALID_VALUE = "TRIGGER_VISITOR_BEHAVIOR_PERCENTAGE_OF_PAGE_VIEWED_HAS_INVALID_VALUE",
    TRIGGER_VISITOR_BEHAVIOR_AT_LEAST_ONE_IS_MISSING = "TRIGGER_VISITOR_BEHAVIOR_AT_LEAST_ONE_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_BLOG_POST_IS_MISSING = "TRIGGER_DISCOUNT_LINK_BLOG_POST_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_BLOG_POST_BLOG_IS_MISSING = "TRIGGER_DISCOUNT_LINK_BLOG_POST_BLOG_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_BLOG_POST_BLOG_IS_INVALID = "TRIGGER_DISCOUNT_LINK_BLOG_POST_BLOG_IS_INVALID",
    TRIGGER_DISCOUNT_LINK_BLOG_POST_POST_IS_MISSING = "TRIGGER_DISCOUNT_LINK_BLOG_POST_POST_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_BLOG_POST_POST_IS_INVALID = "TRIGGER_DISCOUNT_LINK_BLOG_POST_POST_IS_INVALID",
    TRIGGER_DISCOUNT_LINK_PRODUCT_IS_MISSING = "TRIGGER_DISCOUNT_LINK_PRODUCT_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_COLLECTION_IS_MISSING = "TRIGGER_DISCOUNT_LINK_COLLECTION_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_CUSTOM_PAGE_IS_MISSING = "TRIGGER_DISCOUNT_LINK_CUSTOM_PAGE_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_CUSTOM_PAGE_IS_INVALID = "TRIGGER_DISCOUNT_LINK_CUSTOM_PAGE_IS_INVALID",
    TRIGGER_DISCOUNT_LINK_UTM_PARAMETERS_OPTION_IS_MISSING = "TRIGGER_DISCOUNT_LINK_UTM_PARAMETERS_OPTION_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_VANITY_LINK_OPTION_IS_MISSING = "TRIGGER_DISCOUNT_LINK_VANITY_LINK_OPTION_IS_MISSING",
    TRIGGER_DISCOUNT_LINK_MISSING_VANITY_LINK_PATH = "TRIGGER_DISCOUNT_LINK_MISSING_VANITY_LINK_PATH",
    TRIGGER_DISCOUNT_LINK_INVALID_VANITY_LINK_PATH = "TRIGGER_DISCOUNT_LINK_INVALID_VANITY_LINK_PATH",
    OFFER_TYPE_NOT_SUPPORTED = "OFFER_TYPE_NOT_SUPPORTED",
    OFFER_SALES_TYPE_NOT_CONSISTENT_WITH_OFFER_TYPE = "OFFER_SALES_TYPE_NOT_CONSISTENT_WITH_OFFER_TYPE",
    OFFER_SALES_PERCENTAGE_INVALID_VALUE = "OFFER_SALES_PERCENTAGE_INVALID_VALUE",
    OFFER_SALES_FIXED_AMOUNT_INVALID_VALUE = "OFFER_SALES_FIXED_AMOUNT_INVALID_VALUE",
    OFFER_SALES_FIXED_PRICE_INVALID_VALUE = "OFFER_SALES_FIXED_PRICE_INVALID_VALUE",
    OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_PRODUCTS_PROVIDED = "OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_PRODUCTS_PROVIDED",
    OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_VARIANTS_PROVIDED = "OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_VARIANTS_PROVIDED",
    OFFER_PRODUCTS_LIMIT_PER_ORDER_INVALID_VALUE = "OFFER_PRODUCTS_LIMIT_PER_ORDER_INVALID_VALUE",
    OFFER_PRODUCTS_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_PRODUCTS_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_CART_CONTENT_RULE_AT_LEAST_INVALID_VALUE = "OFFER_CART_CONTENT_RULE_AT_LEAST_INVALID_VALUE",
    OFFER_CART_CONTENT_RULE_AT_MOST_INVALID_VALUE = "OFFER_CART_CONTENT_RULE_AT_MOST_INVALID_VALUE",
    OFFER_CART_CONTENT_RULE_BETWEEN_INVALID_VALUE = "OFFER_CART_CONTENT_RULE_BETWEEN_INVALID_VALUE",
    OFFER_CART_CONTENT_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_CART_CONTENT_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_CART_CONTENT_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED = "OFFER_CART_CONTENT_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED",
    OFFER_CART_CONTENT_RULE_NOT_ENOUGH_VARIANTS_PROVIDED = "OFFER_CART_CONTENT_RULE_NOT_ENOUGH_VARIANTS_PROVIDED",
    OFFER_CART_CONTENT_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_CART_CONTENT_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_PURCHASE_REQUIREMENTS_CART_VALUE_INVALID_AMOUNT_OF_RULES = "OFFER_PURCHASE_REQUIREMENTS_CART_VALUE_INVALID_AMOUNT_OF_RULES",
    OFFER_CART_VALUE_RULE_AT_LEAST_INVALID_VALUE = "OFFER_CART_VALUE_RULE_AT_LEAST_INVALID_VALUE",
    OFFER_CART_VALUE_RULE_BETWEEN_INVALID_VALUE = "OFFER_CART_VALUE_RULE_BETWEEN_INVALID_VALUE",
    OFFER_CART_VALUE_RULE_AT_MOST_INVALID_VALUE = "OFFER_CART_VALUE_RULE_AT_MOST_INVALID_VALUE",
    OFFER_CART_VALUE_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_CART_VALUE_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_CART_VALUE_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED = "OFFER_CART_VALUE_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED",
    OFFER_CART_VALUE_RULE_NOT_ENOUGH_VARIANTS_PROVIDED = "OFFER_CART_VALUE_RULE_NOT_ENOUGH_VARIANTS_PROVIDED",
    OFFER_CART_VALUE_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_CART_VALUE_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_EXCEPTIONS_CART_CONTAINS_BEHAVIOR_VALUE_IS_MISSING = "OFFER_EXCEPTIONS_CART_CONTAINS_BEHAVIOR_VALUE_IS_MISSING",
    OFFER_CART_RULES_INVALID_AMOUNT_OF_RULES = "OFFER_CART_RULES_INVALID_AMOUNT_OF_RULES",
    OFFER_CART_RULES_DUPLICATES_ARE_NOT_ACCEPTED = "OFFER_CART_RULES_DUPLICATES_ARE_NOT_ACCEPTED",
    OFFER_SHOPIFY_DISCOUNT_CODE_CODE_NOT_FOUND = "OFFER_SHOPIFY_DISCOUNT_CODE_CODE_NOT_FOUND",
    OFFER_SHOPIFY_DISCOUNT_CODE_GROUP_NOT_FOUND = "OFFER_SHOPIFY_DISCOUNT_CODE_GROUP_NOT_FOUND",
    OFFER_SHOPIFY_DISCOUNT_CODE_APP_NOT_FOUND = "OFFER_SHOPIFY_DISCOUNT_CODE_APP_NOT_FOUND",
    OFFER_COMBINATIONS_NOT_SUPPORTED = "OFFER_COMBINATIONS_NOT_SUPPORTED",
    LOOKUP_SHOPIFY_OBJECT_TYPE_NOT_SUPPORTED = "LOOKUP_SHOPIFY_OBJECT_TYPE_NOT_SUPPORTED",
    LOOKUP_SHOPIFY_OBJECT_AT_LEAST_ONE_REQUIRED = "LOOKUP_SHOPIFY_OBJECT_AT_LEAST_ONE_REQUIRED",
    ACCEPT_LANGUAGE_INVALID = "ACCEPT_LANGUAGE_INVALID",
    OFFER_TIERS_INVALID_AMOUNT = "OFFER_TIERS_INVALID_AMOUNT",
    OFFER_TIERS_INVALID_REQUIREMENT_VALUE = "OFFER_TIERS_INVALID_REQUIREMENT_VALUE",
    OFFER_TIERS_INVALID_ENTITLED_VALUE = "OFFER_TIERS_INVALID_ENTITLED_VALUE",
    OFFER_TIERS_INVALID_AFFECTED_ITEMS_VALUE = "OFFER_TIERS_INVALID_AFFECTED_ITEMS_VALUE",
    OFFER_TIERS_HIGHER_DOES_NOT_HAVE_GREATER_REQUIREMENT_VALUE = "OFFER_TIERS_HIGHER_DOES_NOT_HAVE_GREATER_REQUIREMENT_VALUE",
    OFFER_GIFT_AMOUNT_INVALID_VALUE = "OFFER_GIFT_AMOUNT_INVALID_VALUE",
    OFFER_GIFT_LIMIT_INVALID_VALUE = "OFFER_GIFT_LIMIT_INVALID_VALUE",
    OFFER_GIFT_LIMIT_IS_NOT_GREATER_OR_EQUAL_AMOUNT_VALUE = "OFFER_GIFT_LIMIT_IS_NOT_GREATER_OR_EQUAL_AMOUNT_VALUE",
    OFFER_FREE_SHIPPING_FLAT_FEE_SHIPPING_RATE_INVALID = "OFFER_FREE_SHIPPING_FLAT_FEE_SHIPPING_RATE_INVALID",
    OFFER_FREE_SHIPPING_SHIPPING_COUNTRIES_ARE_MISSING = "OFFER_FREE_SHIPPING_SHIPPING_COUNTRIES_ARE_MISSING",
    OFFER_FREE_SHIPPING_SHIPPING_RATE_THRESHOLD_INVALID = "OFFER_FREE_SHIPPING_SHIPPING_RATE_THRESHOLD_INVALID",
    OFFER_BOGO_CUSTOM_RULE_INVALID_REQUIRED_AMOUNT = "OFFER_BOGO_CUSTOM_RULE_INVALID_REQUIRED_AMOUNT",
    OFFER_BOGO_CUSTOM_RULE_INVALID_AMOUNT_OF_PRODUCTS = "OFFER_BOGO_CUSTOM_RULE_INVALID_AMOUNT_OF_PRODUCTS",
    OFFER_BOGO_CUSTOM_RULE_INVALID_FIXED_AMOUNT = "OFFER_BOGO_CUSTOM_RULE_INVALID_FIXED_AMOUNT",
    OFFER_BOGO_CUSTOM_RULE_INVALID_PERCENTAGE = "OFFER_BOGO_CUSTOM_RULE_INVALID_PERCENTAGE",
    OFFER_BOGO_INVALID_LIMIT = "OFFER_BOGO_INVALID_LIMIT",
    OFFER_CROSS_SELL_RULE_INVALID_COUNT = "OFFER_CROSS_SELL_RULE_INVALID_COUNT",
    OFFER_CROSS_SELL_RULE_INVALID_PERCENTAGE = "OFFER_CROSS_SELL_RULE_INVALID_PERCENTAGE",
    OFFER_CROSS_SELL_RULE_INVALID_FIXED_AMOUNT = "OFFER_CROSS_SELL_RULE_INVALID_FIXED_AMOUNT",
    OFFER_CROSS_SELL_RULE_AT_LEAST_INVALID_VALUE = "OFFER_CROSS_SELL_RULE_AT_LEAST_INVALID_VALUE",
    OFFER_CROSS_SELL_RULE_AT_MOST_INVALID_VALUE = "OFFER_CROSS_SELL_RULE_AT_MOST_INVALID_VALUE",
    OFFER_CROSS_SELL_RULE_BETWEEN_INVALID_VALUE = "OFFER_CROSS_SELL_RULE_BETWEEN_INVALID_VALUE",
    OFFER_CROSS_SELL_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_CROSS_SELL_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_CROSS_SELL_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED = "OFFER_CROSS_SELL_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED",
    OFFER_CROSS_SELL_RULE_NOT_ENOUGH_VARIANTS_PROVIDED = "OFFER_CROSS_SELL_RULE_NOT_ENOUGH_VARIANTS_PROVIDED",
    OFFER_CROSS_SELL_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED = "OFFER_CROSS_SELL_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED",
    OFFER_SUMMARY_NOT_SUPPORTED = "OFFER_SUMMARY_NOT_SUPPORTED",
    OFFER_TYPE_LIMIT_EXCEEDED = "OFFER_TYPE_LIMIT_EXCEEDED",
    OFFER_FREE_SHIPPING_SHIPPING_RATE_NAME_INVALID = "OFFER_FREE_SHIPPING_SHIPPING_RATE_NAME_INVALID",
    TRIGGER_DISCOUNT_LINK_VANITY_LINK_PATH_ALREADY_USED = "TRIGGER_DISCOUNT_LINK_VANITY_LINK_PATH_ALREADY_USED",
    TRIGGER_DISCOUNT_LINK_UTM_PARAMETERS_EXCEEDS_MAX_LENGTH = "TRIGGER_DISCOUNT_LINK_UTM_PARAMETERS_EXCEEDS_MAX_LENGTH",
    TRIGGER_DISCOUNT_LINK_VANITY_LINK_EXCEEDS_MAX_LENGTH = "TRIGGER_DISCOUNT_LINK_VANITY_LINK_EXCEEDS_MAX_LENGTH",
    OFFER_SUGGESTED_RULES_MISSING_SUGGESTED_PRODUCTS = "OFFER_SUGGESTED_RULES_MISSING_SUGGESTED_PRODUCTS",
    OFFER_SUGGESTED_RULES_SUGGESTED_ITEMS_LIMIT_EXCEEDED = "OFFER_SUGGESTED_RULES_SUGGESTED_ITEMS_LIMIT_EXCEEDED",
    OFFER_SUGGESTED_RULES_PRIORITIES_INVALID_RULES = "OFFER_SUGGESTED_RULES_PRIORITIES_INVALID_RULES",
    OFFER_SUGGESTED_RULES_LIMIT_EXCEEDED = "OFFER_SUGGESTED_RULES_LIMIT_EXCEEDED",
    OFFER_SUGGESTED_RULES_ONLY_CART_CONTENT_RULES_ARE_ALLOWED = "OFFER_SUGGESTED_RULES_ONLY_CART_CONTENT_RULES_ARE_ALLOWED",
    OFFER_SUGGESTED_RULES_PRIORITIES_DUPLICATED_PRIORITY_VALUE = "OFFER_SUGGESTED_RULES_PRIORITIES_DUPLICATED_PRIORITY_VALUE",
    OFFER_SUGGESTED_RULES_INVALID_AMOUNT_OF_CONDITIONS = "OFFER_SUGGESTED_RULES_INVALID_AMOUNT_OF_CONDITIONS",
    OFFER_SUGGESTED_RULES_INVALID_AMOUNT_OF_GROUPS = "OFFER_SUGGESTED_RULES_INVALID_AMOUNT_OF_GROUPS",
    OFFER_SUGGESTED_RULES_AT_LEAST_ONE_RULE_NEEDS_TO_BE_ENABLED = "OFFER_SUGGESTED_RULES_AT_LEAST_ONE_RULE_NEEDS_TO_BE_ENABLED",
    PROMOTION_NAME_CANNOT_BE_EMPTY = "PROMOTION_NAME_CANNOT_BE_EMPTY",
    PROMOTION_TOKEN_INVALID = "PROMOTION_TOKEN_INVALID",
    OFFER_NOT_SUPPORTED_IN_GIVEN_PROMOTION_TYPE = "OFFER_NOT_SUPPORTED_IN_GIVEN_PROMOTION_TYPE",
    TRIGGER_VISITOR_BEHAVIOR_VALUE_MISSING = "TRIGGER_VISITOR_BEHAVIOR_VALUE_MISSING",
    TRIGGER_MAXIMUM_USES_VALUE_MISSING = "TRIGGER_MAXIMUM_USES_VALUE_MISSING",
    TRIGGER_EXCLUSIVITY_VALUE_MISSING = "TRIGGER_EXCLUSIVITY_VALUE_MISSING",
    TRIGGER_GEOLOCATION_FILTER_VALUE_MISSING = "TRIGGER_GEOLOCATION_FILTER_VALUE_MISSING",
    TRIGGER_REFERRER_FILTER_VALUE_MISSING = "TRIGGER_REFERRER_FILTER_VALUE_MISSING",
    PROMOTION_SETTINGS_CANNOT_BE_EDITED = "PROMOTION_SETTINGS_CANNOT_BE_EDITED",
    PROMOTION_TRIGGER_TYPE_NOT_SUPPORTED = "PROMOTION_TRIGGER_TYPE_NOT_SUPPORTED",
    PROMOTION_BATCH_OPERATION_MISSING_PROMOTIONS = "PROMOTION_BATCH_OPERATION_MISSING_PROMOTIONS",
    PROMOTION_GET_OPERATION_PROGRESS_MISSING_PROMOTIONS = "PROMOTION_GET_OPERATION_PROGRESS_MISSING_PROMOTIONS",
    PROMOTION_SCHEDULE_START_DATE_CANNOT_BE_REMOVED = "PROMOTION_SCHEDULE_START_DATE_CANNOT_BE_REMOVED",
    PROMOTION_SCHEDULE_INVALID_START_DATE = "PROMOTION_SCHEDULE_INVALID_START_DATE",
    PROMOTION_SCHEDULE_INVALID_END_DATE = "PROMOTION_SCHEDULE_INVALID_END_DATE",
    OFFER_CANNOT_BE_DUPLICATED_DUE_TO_TYPE_LIMIT = "OFFER_CANNOT_BE_DUPLICATED_DUE_TO_TYPE_LIMIT",
    OFFER_COMBINATIONS_MISSING_SETUP = "OFFER_COMBINATIONS_MISSING_SETUP",
    OFFER_SUGGESTED_RULES_NOT_SUPPORTED = "OFFER_SUGGESTED_RULES_NOT_SUPPORTED",
    OFFER_SUGGESTED_RULES_MISSING_AI_RULE_ENABLED_STATE = "OFFER_SUGGESTED_RULES_MISSING_AI_RULE_ENABLED_STATE",
    OFFER_SUGGESTED_RULES_PRIORITIES_MISSING = "OFFER_SUGGESTED_RULES_PRIORITIES_MISSING",
    TRIGGER_MAXIMUM_USES_ORDER_CAP_HAS_INVALID_VALUE = "TRIGGER_MAXIMUM_USES_ORDER_CAP_HAS_INVALID_VALUE",
    TRIGGER_COUNTDOWN_CLOCK_FIXED_CLOCK_TIME_MISSING = "TRIGGER_COUNTDOWN_CLOCK_FIXED_CLOCK_TIME_MISSING",
    TIMESPAN_INVALID = "TIMESPAN_INVALID",
    TRIGGER_COUNTDOWN_CLOCK_VALUE_MISSING = "TRIGGER_COUNTDOWN_CLOCK_VALUE_MISSING",
    TRIGGER_PROMOTION_CODES_GENERATION_SETUP_MISSING = "TRIGGER_PROMOTION_CODES_GENERATION_SETUP_MISSING",
    TRIGGER_PROMOTION_CODES_INVALID_MAXIMUM_USES = "TRIGGER_PROMOTION_CODES_INVALID_MAXIMUM_USES",
    TRIGGER_PROMOTION_CODES_INVALID_MANUAL_CODES_AMOUNT = "TRIGGER_PROMOTION_CODES_INVALID_MANUAL_CODES_AMOUNT",
    TRIGGER_PROMOTION_CODES_LIMIT_REACHED = "TRIGGER_PROMOTION_CODES_LIMIT_REACHED",
    TRIGGER_PROMOTION_CODES_CODE_ALREADY_EXISTS = "TRIGGER_PROMOTION_CODES_CODE_ALREADY_EXISTS",
    TRIGGER_PROMOTION_CODES_INVALID_AUTOMATIC_CODES_AMOUNT = "TRIGGER_PROMOTION_CODES_INVALID_AUTOMATIC_CODES_AMOUNT",
    TRIGGER_PROMOTION_CODES_FILE_MISSING = "TRIGGER_PROMOTION_CODES_FILE_MISSING",
    TRIGGER_PROMOTION_CODES_FILE_INVALID_TYPE = "TRIGGER_PROMOTION_CODES_FILE_INVALID_TYPE",
    TRIGGER_PROMOTION_CODES_FILE_BROKEN = "TRIGGER_PROMOTION_CODES_FILE_BROKEN",
    TRIGGER_PROMOTION_CODES_INVALID = "TRIGGER_PROMOTION_CODES_INVALID",
    OFFER_MINIMUM_SPEND_VALUE_MISSING = "OFFER_MINIMUM_SPEND_VALUE_MISSING",
    OFFER_MINIMUM_SPEND_VALUE_INVALID = "OFFER_MINIMUM_SPEND_VALUE_INVALID",
    OFFER_DISCOUNT_LIMIT_LIMIT_VALUE_INVALID = "OFFER_DISCOUNT_LIMIT_LIMIT_VALUE_INVALID",
    OFFER_GIFT_MISSING_COLLECTIONS = "OFFER_GIFT_MISSING_COLLECTIONS",
    OFFER_GIFT_MISSING_PRODUCTS = "OFFER_GIFT_MISSING_PRODUCTS",
    OFFER_GIFT_MISSING_PRODUCT_VARIANTS = "OFFER_GIFT_MISSING_PRODUCT_VARIANTS",
    OFFER_GIFT_RULE_MISSING = "OFFER_GIFT_RULE_MISSING",
    TRIGGER_PROMOTION_CODES_INVALID_PREFIX_OR_SUFFIX = "TRIGGER_PROMOTION_CODES_INVALID_PREFIX_OR_SUFFIX",
    OFFER_ORDER_DISCOUNT_INVALID_VALUE = "OFFER_ORDER_DISCOUNT_INVALID_VALUE",
    OFFER_CART_CONTENT_RULE_EXACTLY_INVALID_VALUE = "OFFER_CART_CONTENT_RULE_EXACTLY_INVALID_VALUE",
    OFFER_CROSS_SELL_RULE_EXACTLY_INVALID_VALUE = "OFFER_CROSS_SELL_RULE_EXACTLY_INVALID_VALUE",
    OFFER_CART_VALUE_RULE_EXACTLY_INVALID_VALUE = "OFFER_CART_VALUE_RULE_EXACTLY_INVALID_VALUE",
    OFFER_CART_RULES_INVALID_AMOUNT_OF_CONNECTORS = "OFFER_CART_RULES_INVALID_AMOUNT_OF_CONNECTORS",
    OFFER_CART_CONTENT_RULE_INVALID_NAME_FILTER_LENGTH = "OFFER_CART_CONTENT_RULE_INVALID_NAME_FILTER_LENGTH",
    OFFER_CART_VALUE_RULE_MISSING_COMPARE_AT_PRICE_SETTING = "OFFER_CART_VALUE_RULE_MISSING_COMPARE_AT_PRICE_SETTING",
    OFFER_CART_VALUE_RULE_INVALID_NAME_FILTER_LENGTH = "OFFER_CART_VALUE_RULE_INVALID_NAME_FILTER_LENGTH",
    OFFER_PRODUCTS_INVALID_NAME_FILTER_LENGTH = "OFFER_PRODUCTS_INVALID_NAME_FILTER_LENGTH",
    OFFER_PRODUCTS_MISSING_COMPARE_AT_PRICE_SETTING = "OFFER_PRODUCTS_MISSING_COMPARE_AT_PRICE_SETTING",
    PROMOTION_CANNOT_BE_EDITED = "PROMOTION_CANNOT_BE_EDITED",
    PROMOTION_CANNOT_BE_PUBLISHED_MISSING_OFFERS = "PROMOTION_CANNOT_BE_PUBLISHED_MISSING_OFFERS",
    OFFER_FREE_SHIPPING_SHIPPING_RATES_MISSING = "OFFER_FREE_SHIPPING_SHIPPING_RATES_MISSING",
    PROMOTION_CANNOT_BE_PUBLISHED_THRESHOLD_REACHED = "PROMOTION_CANNOT_BE_PUBLISHED_THRESHOLD_REACHED",
    TRIGGER_MARKET_FILTER_VALUE_MISSING = "TRIGGER_MARKET_FILTER_VALUE_MISSING",
    TRIGGER_MARKET_FILTER_INVALID_AMOUNT = "TRIGGER_MARKET_FILTER_INVALID_AMOUNT",
    TRIGGER_PURCHASE_HISTORY_FILTER_VALUE_MISSING = "TRIGGER_PURCHASE_HISTORY_FILTER_VALUE_MISSING",
    TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_MISSING_FROM = "TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_MISSING_FROM",
    TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_FROM = "TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_FROM",
    TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_MISSING_TO = "TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_MISSING_TO",
    TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_TO = "TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_TO",
    TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_BETWEEN = "TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_BETWEEN",
    PROMOTIONS_IMPORT_FILE_BROKEN = "PROMOTIONS_IMPORT_FILE_BROKEN",
    OFFER_DISCOUNT_LIMIT_CAP_VALUE_INVALID = "OFFER_DISCOUNT_LIMIT_CAP_VALUE_INVALID",
    PROMOTION_SCHEDULE_CANNOT_BE_SET_FOR_SHOPIFY_DISCOUNT_CODE_PROMOTION = "PROMOTION_SCHEDULE_CANNOT_BE_SET_FOR_SHOPIFY_DISCOUNT_CODE_PROMOTION",
    OFFER_GIFT_INVALID_DEFAULT_GIFT_RULE = "OFFER_GIFT_INVALID_DEFAULT_GIFT_RULE"
}
export enum ShopifyDiscountCodeTargetTypeDtoEnum {
    LINE_ITEM = "LINE_ITEM",
    SHIPPING_LINE = "SHIPPING_LINE"
}
export enum ShopifyDiscountCodeValueTypeDtoEnum {
    FIXED_AMOUNT = "FIXED_AMOUNT",
    PERCENTAGE = "PERCENTAGE"
}
export enum ShopifyInventoryPolicyEnum {
    CONTINUE = "CONTINUE",
    DENY = "DENY"
}
export enum ShopifyObjectTypeDtoEnum {
    COLLECTION = "COLLECTION",
    PRODUCT = "PRODUCT",
    PRODUCT_VARIANT = "PRODUCT_VARIANT"
}
export enum ShopifyProductStatusEnum {
    ACTIVE = "ACTIVE",
    ARCHIVED = "ARCHIVED",
    DRAFT = "DRAFT"
}
export enum ShopifyWeightUnitEnum {
    GRAMS = "GRAMS",
    KILOGRAMS = "KILOGRAMS",
    OUNCES = "OUNCES",
    POUNDS = "POUNDS"
}
export enum SmartTagGroupEnum {
    DISCOUNT = "DISCOUNT",
    PRICE = "PRICE",
    GOALS = "GOALS",
    TIME = "TIME",
    LINK = "LINK",
    LIMITS = "LIMITS",
    INSTALLMENTS = "INSTALLMENTS",
    DISCOUNT_CODES = "DISCOUNT_CODES",
    OTHER = "OTHER"
}
export enum SmartTagTypeEnum {
    DISCOUNT_CODE = "DISCOUNT_CODE",
    DISCOUNT = "DISCOUNT",
    DISCOUNT_PERCENTAGE = "DISCOUNT_PERCENTAGE",
    DISCOUNT_PERCENTAGE_TIER1 = "DISCOUNT_PERCENTAGE_TIER1",
    DISCOUNT_PERCENTAGE_TIER2 = "DISCOUNT_PERCENTAGE_TIER2",
    DISCOUNT_PERCENTAGE_TIER3 = "DISCOUNT_PERCENTAGE_TIER3",
    DISCOUNT_PERCENTAGE_TIER4 = "DISCOUNT_PERCENTAGE_TIER4",
    DISCOUNT_PERCENTAGE_TIER5 = "DISCOUNT_PERCENTAGE_TIER5",
    DISCOUNT_PERCENTAGE_HIGHEST_TIER = "DISCOUNT_PERCENTAGE_HIGHEST_TIER",
    DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT",
    DISCOUNT_AMOUNT_TIER1 = "DISCOUNT_AMOUNT_TIER1",
    DISCOUNT_AMOUNT_TIER2 = "DISCOUNT_AMOUNT_TIER2",
    DISCOUNT_AMOUNT_TIER3 = "DISCOUNT_AMOUNT_TIER3",
    DISCOUNT_AMOUNT_TIER4 = "DISCOUNT_AMOUNT_TIER4",
    DISCOUNT_AMOUNT_TIER5 = "DISCOUNT_AMOUNT_TIER5",
    DISCOUNT_AMOUNT_HIGHEST_TIER = "DISCOUNT_AMOUNT_HIGHEST_TIER",
    MAXIMUM_DISCOUNT = "MAXIMUM_DISCOUNT",
    TOTAL_DISCOUNT_PERCENTAGE = "TOTAL_DISCOUNT_PERCENTAGE",
    TOTAL_DISCOUNT_AMOUNT = "TOTAL_DISCOUNT_AMOUNT",
    OFFER_TOTAL_DISCOUNT_PERCENTAGE = "OFFER_TOTAL_DISCOUNT_PERCENTAGE",
    OFFER_TOTAL_DISCOUNT_AMOUNT = "OFFER_TOTAL_DISCOUNT_AMOUNT",
    DISCOUNTED_PRICE = "DISCOUNTED_PRICE",
    DISCOUNTED_PRICE_TIER1 = "DISCOUNTED_PRICE_TIER1",
    DISCOUNTED_PRICE_TIER2 = "DISCOUNTED_PRICE_TIER2",
    DISCOUNTED_PRICE_TIER3 = "DISCOUNTED_PRICE_TIER3",
    DISCOUNTED_PRICE_TIER4 = "DISCOUNTED_PRICE_TIER4",
    DISCOUNTED_PRICE_TIER5 = "DISCOUNTED_PRICE_TIER5",
    DISCOUNTED_PRICE_HIGHEST_TIER = "DISCOUNTED_PRICE_HIGHEST_TIER",
    ORIGINAL_PRICE = "ORIGINAL_PRICE",
    ORIGINAL_COMPAREAT_PRICE = "ORIGINAL_COMPAREAT_PRICE",
    FIXED_PRICE = "FIXED_PRICE",
    FIXED_PRICE_UNITS = "FIXED_PRICE_UNITS",
    MINIMUM_CART_VALUE = "MINIMUM_CART_VALUE",
    MAXIMUM_CART_VALUE = "MAXIMUM_CART_VALUE",
    MINIMUM_CART_PRODUCTS = "MINIMUM_CART_PRODUCTS",
    MINIMUM_CART_PRODUCTS_INCLUDING_ENTITLED = "MINIMUM_CART_PRODUCTS_INCLUDING_ENTITLED",
    MAXIMUM_CART_PRODUCTS = "MAXIMUM_CART_PRODUCTS",
    AMOUNT_LEFT_BEFORE_DISCOUNT = "AMOUNT_LEFT_BEFORE_DISCOUNT",
    PRODUCTS_LEFT_BEFORE_DISCOUNT = "PRODUCTS_LEFT_BEFORE_DISCOUNT",
    PRODUCTS_LEFT_BEFORE_DISCOUNT_INCLUDING_ENTITLED = "PRODUCTS_LEFT_BEFORE_DISCOUNT_INCLUDING_ENTITLED",
    NEXT_GOAL_DISCOUNT = "NEXT_GOAL_DISCOUNT",
    NEXT_GOAL_DISCOUNT_PERCENTAGE = "NEXT_GOAL_DISCOUNT_PERCENTAGE",
    NEXT_GOAL_DISCOUNT_AMOUNT = "NEXT_GOAL_DISCOUNT_AMOUNT",
    STARTS_AT_DATE = "STARTS_AT_DATE",
    START_AT_TIME = "START_AT_TIME",
    EXPIRES_AT_DATE = "EXPIRES_AT_DATE",
    EXPIRES_AT_TIME = "EXPIRES_AT_TIME",
    ENDS_AT_DATE = "ENDS_AT_DATE",
    ENDS_AT_TIME = "ENDS_AT_TIME",
    TIMER = "TIMER",
    COUNTRY = "COUNTRY",
    MAXIMUM_DISCOUNTED_ITEMS = "MAXIMUM_DISCOUNTED_ITEMS",
    MAXIMUM_GIFTS = "MAXIMUM_GIFTS",
    MINIMUM_PREREQUISITE_PRODUCTS = "MINIMUM_PREREQUISITE_PRODUCTS",
    MINIMUM_PREREQUISITE_PRODUCTS_TIER1 = "MINIMUM_PREREQUISITE_PRODUCTS_TIER1",
    MINIMUM_PREREQUISITE_PRODUCTS_TIER2 = "MINIMUM_PREREQUISITE_PRODUCTS_TIER2",
    MINIMUM_PREREQUISITE_PRODUCTS_TIER3 = "MINIMUM_PREREQUISITE_PRODUCTS_TIER3",
    MINIMUM_PREREQUISITE_PRODUCTS_TIER4 = "MINIMUM_PREREQUISITE_PRODUCTS_TIER4",
    MINIMUM_PREREQUISITE_PRODUCTS_TIER5 = "MINIMUM_PREREQUISITE_PRODUCTS_TIER5",
    MAXIMUM_PREREQUISITE_PRODUCTS = "MAXIMUM_PREREQUISITE_PRODUCTS",
    MAXIMUM_PREREQUISITE_PRODUCTS_TIER1 = "MAXIMUM_PREREQUISITE_PRODUCTS_TIER1",
    MAXIMUM_PREREQUISITE_PRODUCTS_TIER2 = "MAXIMUM_PREREQUISITE_PRODUCTS_TIER2",
    MAXIMUM_PREREQUISITE_PRODUCTS_TIER3 = "MAXIMUM_PREREQUISITE_PRODUCTS_TIER3",
    MAXIMUM_PREREQUISITE_PRODUCTS_TIER4 = "MAXIMUM_PREREQUISITE_PRODUCTS_TIER4",
    MAXIMUM_PREREQUISITE_PRODUCTS_TIER5 = "MAXIMUM_PREREQUISITE_PRODUCTS_TIER5",
    DISCOUNTED_INSTALLMENTS_4PERIODS = "DISCOUNTED_INSTALLMENTS_4PERIODS",
    DISCOUNTED_INSTALLMENTS_3PERIODS = "DISCOUNTED_INSTALLMENTS_3PERIODS",
    DISCOUNTED_INSTALLMENTS_4PERIODS_TOTAL = "DISCOUNTED_INSTALLMENTS_4PERIODS_TOTAL",
    DISCOUNTED_INSTALLMENTS_3PERIODS_TOTAL = "DISCOUNTED_INSTALLMENTS_3PERIODS_TOTAL",
    REMAINING_DISCOUNT_CODES = "REMAINING_DISCOUNT_CODES",
    TOTAL_DISCOUNT_CODES = "TOTAL_DISCOUNT_CODES",
    USED_DISCOUNT_CODES = "USED_DISCOUNT_CODES"
}
export enum SmartTagValueTypeEnum {
    MONEY = "MONEY",
    DATE = "DATE",
    TEXT = "TEXT",
    PERCENTAGE = "PERCENTAGE",
    NUMBER = "NUMBER",
    URL = "URL"
}
export enum SortDirectionDtoEnum {
    ASC = "ASC",
    DESC = "DESC"
}
export enum TagCategoryDtoEnum {
    PROMOTION = "PROMOTION",
    CUSTOMER = "CUSTOMER"
}
export enum TestPromotionTypeDtoEnum {
    FREE_GIFT = "FREE_GIFT",
    FREE_SHIPPING = "FREE_SHIPPING",
    EARLY_BIRD = "EARLY_BIRD"
}
export enum TriggerCountdownClockEndModeDtoEnum {
    PROMOTION_END = "PROMOTION_END",
    FIXED = "FIXED"
}
export enum TriggerDiscountLinkLandingPageTypeDtoEnum {
    HOME_PAGE = "HOME_PAGE",
    COLLECTION = "COLLECTION",
    PRODUCT = "PRODUCT",
    CUSTOM_PAGE = "CUSTOM_PAGE",
    BLOG_POST = "BLOG_POST"
}
export enum TriggerIntendedAudienceTypeDtoEnum {
    GUESTS_ONLY = "GUESTS_ONLY",
    CUSTOMERS_ONLY = "CUSTOMERS_ONLY",
    GUESTS_AND_SELECTED_CUSTOMERS = "GUESTS_AND_SELECTED_CUSTOMERS",
    B2B_CUSTOMERS_ONLY = "B2B_CUSTOMERS_ONLY"
}
export enum TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum {
    ANY = "ANY",
    AT_LEAST = "AT_LEAST",
    AT_MOST = "AT_MOST",
    BETWEEN = "BETWEEN"
}
export enum TriggerTypeDtoEnum {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE"
}
export enum TriggerVisitorBehaviorCriteriaTypeDtoEnum {
    ALL = "ALL",
    AT_LEAST_ONE = "AT_LEAST_ONE"
}
