import React, { useCallback, useMemo, useState } from 'react';
import {
  Text,
  BlockStack,
  Button,
  Card,
  Badge,
  InlineStack,
  Page,
  MediaCard,
  VideoThumbnail,
  Banner,
  Link,
  Box,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  ThemeTypeDto,
  WidgetInstallationModeDto,
  WidgetStatusDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { Element } from 'react-scroll';
import {
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import ToggleWidgetModal from './components/ToggleWidgetModal/ToggleWidgetModal';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import WidgetRefreshModal from '../../../WidgetRefreshModal/WidgetRefreshModal';
import {
  CardsNavigation,
  CardsNavigationProps,
} from '../CardsNavigation/CardsNavigation';
import { SettingsSkeleton } from 'features/settings/components/GeneralSettings/components/SettingsSkeleton/SettingsSkeleton';
import { SettingsConfirmationModal } from 'features/settings/components/SettingsConfirmationModal/SettingsConfirmationModal';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { SettingsModalSubPathsEnums } from 'pages/enums/PagesEnums';

export enum WidgetTypeSettingsWrapperEnum {
  ANNOUNCEMENT_BAR = 'ANNOUNCEMENT_BAR',
  NOTIFICATIONS = 'NOTIFICATIONS',
  COUNTDOWN_CLOCK = 'COUNTDOWN_CLOCK',
  PROMOTIONAL_BADGE = 'PROMOTIONAL_BADGE',
  POP_UP = 'POP_UP',
  PRODUCT_BANNER = 'PRODUCT_BANNER',
  PROMOTION_SUMMARY = 'PROMOTION_SUMMARY',
  PROMOTION_CODE_FIELD = 'PROMOTION_CODE_FIELD',
  VOLUME_DISCOUNT = 'VOLUME_DISCOUNT',
  OFFER_RULE_POPUP = 'OFFER_RULE_POPUP',
  DISCOUNT_NINJA_PROMOTION_CODES = 'DISCOUNT_NINJA_PROMOTION_CODES',
  SHOPIFY_DISCOUNT_CODES = 'SHOPIFY_DISCOUNT_CODES',
  NINJA_CART = 'NINJA_CART',
  FREQUENTLY_BOUGHT_TOGETHER = 'FREQUENTLY_BOUGHT_TOGETHER',
  PRODUCT_STRIKETHROUGH_PRICING = 'PRODUCT_STRIKETHROUGH_PRICING',
}
export type WidgetShopSettingsDto = {
  status?: WidgetStatusDto | null;
  widgetType: WidgetTypeSettingsWrapperEnum;
  installationDeepLink?: string | null;
  theme?: ThemeTypeDto | null;
  themeName?: string | null;
  installationMode?: WidgetInstallationModeDto | null;
  isPremium?: boolean | null;
};
type WidgetSettingsWrapperProps = {
  children: React.ReactNode;
  widgetState: WidgetShopSettingsDto;
  isLoading: boolean;
  refetchWidget?: () => void;
  setEnabled?: (value: WidgetStatusDtoEnum) => void;
  cartDrawer?: boolean;
  cartDrawerData?: CardsNavigationProps;
};

export const WidgetSettingsWrapper: React.FC<WidgetSettingsWrapperProps> = ({
  children,
  widgetState,
  isLoading,
  refetchWidget,
  setEnabled,
  cartDrawer,
  cartDrawerData,
}) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const location = useLocation();
  const { turnWidgetOnManuallyIsLoading, turnWidgetOnManually } =
    useConfigureSettings();
  const { isRefreshModalOpen, isUnsavedChanges } = useAppSelector(
    (store) => store.settings
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [isToggleWidgetModalOpen, setIsToggleWidgetModalOpen] =
    useState<boolean>(false);
  const [modalActive, setModalActive] = useState<boolean>(false);

  const {
    widgetType,
    installationDeepLink,
    installationMode,
    isPremium,
    status,
    theme,
    themeName,
  } = widgetState;

  const currentWidgetType =
    widgetType !==
      WidgetTypeSettingsWrapperEnum.DISCOUNT_NINJA_PROMOTION_CODES &&
    widgetType !== WidgetTypeSettingsWrapperEnum.SHOPIFY_DISCOUNT_CODES &&
    WidgetTypeDtoEnum[widgetType];

  const showMediaCart = useMemo(
    () =>
      widgetType !==
        WidgetTypeSettingsWrapperEnum.DISCOUNT_NINJA_PROMOTION_CODES &&
      widgetType !== WidgetTypeSettingsWrapperEnum.PROMOTION_CODE_FIELD &&
      widgetType !==
        WidgetTypeSettingsWrapperEnum.PRODUCT_STRIKETHROUGH_PRICING,

    [widgetType]
  );

  const showCurrentTheme = useMemo(
    () =>
      widgetType !==
        WidgetTypeSettingsWrapperEnum.DISCOUNT_NINJA_PROMOTION_CODES &&
      widgetType !== WidgetTypeSettingsWrapperEnum.SHOPIFY_DISCOUNT_CODES &&
      widgetType !==
        WidgetTypeSettingsWrapperEnum.PRODUCT_STRIKETHROUGH_PRICING,
    [widgetType]
  );

  const isWidgetEnabled = useMemo(
    () =>
      status === WidgetStatusDtoEnum.ENABLED ||
      status === WidgetStatusDtoEnum.ENABLED_VIA_CODE ||
      status === WidgetStatusDtoEnum.ENABLED_VIA_CSS,
    [status]
  );

  const isWidgetLocked = useMemo(
    () => status === WidgetStatusDtoEnum.LOCKED,
    [status]
  );

  const toggleWidget = useCallback(() => {
    const reversedStatus = isWidgetEnabled
      ? WidgetStatusDtoEnum.DISABLED
      : WidgetStatusDtoEnum.ENABLED;
    if (
      (installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS ||
        installationMode === WidgetInstallationModeDtoEnum.CUSTOM) &&
      !isWidgetEnabled
    ) {
      setIsToggleWidgetModalOpen(!isToggleWidgetModalOpen);
    } else if (
      installationMode === WidgetInstallationModeDtoEnum.APP_EMBED ||
      (isWidgetEnabled && installationMode)
    ) {
      turnWidgetOnManually({
        type: currentWidgetType as WidgetTypeDtoEnum,
        status: reversedStatus,
      }).then(() => refetchWidget?.());
    } else if (!installationMode) {
      setEnabled?.(reversedStatus);
    }
  }, [
    isToggleWidgetModalOpen,
    installationMode,
    currentWidgetType,
    isWidgetEnabled,
    setEnabled,
    turnWidgetOnManually,
  ]);

  const handleRedirectBack = useCallback(() => {
    setModalActive(false);
    if (
      widgetType ===
        WidgetTypeSettingsWrapperEnum.DISCOUNT_NINJA_PROMOTION_CODES ||
      widgetType === WidgetTypeSettingsWrapperEnum.SHOPIFY_DISCOUNT_CODES
    ) {
      searchParams.set(
        'subPath',
        SettingsModalSubPathsEnums.PromotionCodeField
      );
      setSearchParams(searchParams);
    } else if (searchParams.get('tab')) {
      searchParams.delete('tab');
      setSearchParams(searchParams);
    } else {
      searchParams.delete('subPath');
      setSearchParams(searchParams);
    }
  }, [setSearchParams, setModalActive]);

  const handleModalClose = useCallback(() => {
    setModalActive(false);
  }, [setModalActive]);

  const onBackActionClick = useCallback(async () => {
    if (!isDebugOrLocal && isUnsavedChanges) {
      if (location.pathname.includes(`/${NavPaths.Settings}`)) {
        await shopify.saveBar.leaveConfirmation();
        handleRedirectBack();
      } else {
        setModalActive(true);
      }
    } else {
      handleRedirectBack();
    }
  }, [
    isDebugOrLocal,
    isUnsavedChanges,
    location,
    handleRedirectBack,
    setModalActive,
  ]);

  return (
    <>
      <div className='WidgetSettingsWrapper'>
        {!isLoading ? (
          <Page
            backAction={{ content: 'Widgets', onAction: onBackActionClick }}
            title={`${i18n.translate(widgetType)}${i18n.translate(
              searchParams.get('tab') || ' '
            )}`}
            titleMetadata={
              <InlineStack gap='100'>
                {isWidgetLocked && <Badge>{i18n.translate('Off')}</Badge>}
                {isPremium && (
                  <Badge tone='attention'>{i18n.translate('Premium')}</Badge>
                )}
              </InlineStack>
            }
            primaryAction={
              isWidgetLocked
                ? { content: i18n.translate('GetTheFeature') }
                : null
            }
          >
            {themeName && showCurrentTheme && (
              <div style={{ marginTop: '-24px' }}>
                <Box paddingInlineStart='800' paddingBlockEnd='600'>
                  <InlineStack gap='100'>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('SettingsFor') +
                        i18n.translate(`${theme}_THEME`) +
                        themeName}
                    </Text>
                    <div style={{ zIndex: 999999999999999 }}>
                      <Link
                        url='http://www.google.com'
                        target='_blank'
                        monochrome
                      >
                        {i18n.translate('ManageThemes')}
                      </Link>
                    </div>
                  </InlineStack>
                </Box>
              </div>
            )}
            <BlockStack gap='600'>
              <Element name={widgetType}>
                {!cartDrawer || searchParams.get('tab') ? (
                  <Card roundedAbove='sm'>
                    <BlockStack gap='200'>
                      <InlineStack align='space-between' blockAlign='center'>
                        <InlineStack gap={'100'}>
                          <Text as='h2' variant='headingSm'>
                            {i18n.translate(widgetType)}
                          </Text>
                          <Badge tone={isWidgetEnabled ? 'success' : undefined}>
                            {i18n.translate(isWidgetEnabled ? 'On' : 'Off')}
                          </Badge>
                        </InlineStack>
                        <Button
                          loading={turnWidgetOnManuallyIsLoading}
                          disabled={isWidgetLocked}
                          onClick={toggleWidget}
                        >
                          {i18n.translate(
                            isWidgetEnabled ? 'TurnOff' : 'TurnOn'
                          )}
                        </Button>
                      </InlineStack>
                      <Text as='p' tone='subdued'>
                        {i18n.translate(`${widgetType}_DESCRIPTION`)}
                      </Text>
                      {isWidgetLocked && (
                        <Banner tone='info'>
                          {i18n.translate('UpgradePlan')}
                        </Banner>
                      )}
                    </BlockStack>
                  </Card>
                ) : (
                  <CardsNavigation data={cartDrawerData} />
                )}
              </Element>
              {showMediaCart && (
                <Element name='LearnMoreAbout'>
                  <MediaCard
                    title={
                      i18n.translate('LearnMoreAbout') +
                      i18n.translate(widgetType)
                    }
                    primaryAction={{
                      content: i18n.translate('LearnMore'),
                      onAction: () => null,
                    }}
                    description={i18n.translate(`${widgetType}_LEARNMORE`)}
                    size='small'
                  >
                    <VideoThumbnail
                      videoLength={80}
                      thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
                      onClick={() =>
                        window.open(
                          'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley',
                          '_blank'
                        )
                      }
                    />
                  </MediaCard>
                </Element>
              )}
              {!isWidgetLocked && children}
            </BlockStack>
          </Page>
        ) : (
          <SettingsSkeleton />
        )}
        {isToggleWidgetModalOpen && (
          <ToggleWidgetModal
            isOpen={isToggleWidgetModalOpen}
            widgetType={currentWidgetType as WidgetTypeDtoEnum}
            onClose={() => setIsToggleWidgetModalOpen(false)}
            installationMode={installationMode as WidgetInstallationModeDtoEnum}
            installationLink={installationDeepLink || ''}
            refetchWidget={refetchWidget!}
          />
        )}
        {isRefreshModalOpen && (
          <WidgetRefreshModal
            isOpen={isRefreshModalOpen}
            onRefresh={refetchWidget!}
          />
        )}
      </div>
      <SettingsConfirmationModal
        modalActive={modalActive}
        handleModalClose={handleModalClose}
        handleLeavePage={handleRedirectBack}
      />
    </>
  );
};
