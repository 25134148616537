import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoTriggerPurchaseHistoryFilterDto,
  TriggerPurchaseHistoryFilterDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  Link,
  Text,
  TextField,
} from '@shopify/polaris';
import { TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type SettingPurchaseHistoryFilterProps = {
  updateSettingsState: (data: OptionDtoTriggerPurchaseHistoryFilterDto) => void;
  purchaseHistory?: OptionDtoTriggerPurchaseHistoryFilterDto;
};

export const SettingPurchaseHistoryFilter: React.FC<
  SettingPurchaseHistoryFilterProps
> = ({ updateSettingsState, purchaseHistory }) => {
  const [i18n] = useI18n();

  const purchaseType = purchaseHistory?.value?.type;

  const showMinOrdersField =
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN ||
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.AT_LEAST;

  const showMaxOrdersField =
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN ||
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.AT_MOST;

  const orderPlacedOptions = useMemo(
    () =>
      Object.values(TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum).map(
        (value) => ({
          label: i18n.translate(value),
          value: value,
        })
      ),
    [i18n]
  );

  const handleUpdatePurchase = useCallback(
    (
      field: keyof TriggerPurchaseHistoryFilterDto,
      value: TriggerPurchaseHistoryFilterDto[keyof TriggerPurchaseHistoryFilterDto]
    ) =>
      updateSettingsState({
        ...purchaseHistory,
        value: {
          ...purchaseHistory?.value,
          [field]: value,
        },
      }),
    [purchaseHistory]
  );

  const validateOnBlur = useCallback(
    (field: 'to' | 'from') =>
      handleUpdatePurchase(
        field,
        (purchaseHistory?.value?.[field] as number) < 1
          ? 1
          : purchaseHistory?.value?.[field]
      ),
    [purchaseHistory?.value]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack blockAlign='start' wrap={false} align='space-between'>
          <Box width='65%'>
            <BlockStack gap='100'>
              <InlineStack align='start' gap='200'>
                <Text fontWeight='semibold' as='h2'>
                  {i18n.translate('PurchaseHistoryTitle')}
                </Text>
                <Badge tone={purchaseHistory?.enabled ? 'success' : 'enabled'}>
                  {i18n.translate(purchaseHistory?.enabled ? 'On' : 'Off')}
                </Badge>
              </InlineStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('PurchaseHistorySubtitle', {
                  followingArticle: (
                    <Link>{i18n.translate('followingArticle')}</Link>
                  ),
                })}
              </Text>
            </BlockStack>
          </Box>
          <Button
            onClick={() =>
              updateSettingsState({
                ...purchaseHistory,
                enabled: !purchaseHistory?.enabled,
              })
            }
          >
            {i18n.translate(purchaseHistory?.enabled ? 'TurnOff' : 'TurnOn')}
          </Button>
        </InlineStack>
        <Collapsible id='collapsible' open={!!purchaseHistory?.enabled}>
          <BlockStack gap='400'>
            <ChoiceList
              title={i18n.translate('OrdersPlaced')}
              selected={[purchaseHistory?.value?.type as string]}
              choices={orderPlacedOptions}
              onChange={(
                value: TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum[]
              ) => handleUpdatePurchase('type', value[0])}
            />

            {purchaseType !==
              TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.ANY && (
              <Box width='320px'>
                <InlineStack wrap={false} gap='500'>
                  {showMinOrdersField && (
                    <TextField
                      label={i18n.translate(
                        purchaseType ===
                          TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN
                          ? 'MinimumOrders'
                          : 'OrdersPlaced'
                      )}
                      type='integer'
                      autoComplete=''
                      value={(purchaseHistory?.value?.from || 1).toString()}
                      onChange={(value) => handleUpdatePurchase('from', +value)}
                      onBlur={() => validateOnBlur('from')}
                    />
                  )}
                  {showMaxOrdersField && (
                    <TextField
                      label={i18n.translate(
                        purchaseType ===
                          TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN
                          ? 'MaximumOrders'
                          : 'OrdersPlaced'
                      )}
                      type='integer'
                      autoComplete=''
                      value={(purchaseHistory?.value?.to || 1).toString()}
                      onChange={(value) => handleUpdatePurchase('to', +value)}
                      onBlur={() => validateOnBlur('to')}
                    />
                  )}
                </InlineStack>
              </Box>
            )}
          </BlockStack>
        </Collapsible>
      </BlockStack>
    </Card>
  );
};
