import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tier } from '../Tier/Tier';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferLevelDto,
  OfferTierDto,
  OfferTieredDiscountTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Banner,
  BlockStack,
  Button,
  Card,
  InlineStack,
  Text,
} from '@shopify/polaris';
import './ConfigGoalsAndDiscounts.scss';
import {
  FieldsProps,
  OfferTierProps,
  OfferTierUpdateDto,
} from '../../../Offers/types/OfferTierUpdateDto';
import {
  OfferTieredDiscountTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { usePrevious } from 'core/hooks/usePrevious';
import { cloneDeep, debounce } from 'lodash';

type OfferTierAdditionalDto = OfferTierDto & OfferTierProps;

export type ConfigGoalsAndDiscountsProps = {
  tiers?: OfferTierAdditionalDto[] | null;
  level?: OfferLevelDto;
  onTierUpdate(data: OfferTierUpdateDto): void;
  onTierDelete(index: number): void;
  offerType: OfferTypeDtoEnum;
  discountType?: OfferTieredDiscountTypeDto;
  onFormValidityChange(formIsValid: boolean): void;
};

type TierValidity = {
  id: number;
  isValid: boolean;
};

const defaultTier: OfferTierProps = {
  affectedItems: null,
  entitledPreciseValue: null,
  entitledWholeValue: null,
  requirementPreciseValue: null,
  requirementWholeValue: null,
};

const defaultFeildsNeeded: FieldsProps = {
  spendBoughtFieldName: '',
  offerDiscountFieldName: '',
  leastExpensiveFieldName: '',
};

export const ConfigGoalsAndDiscounts: React.FC<ConfigGoalsAndDiscountsProps> = (
  props
) => {
  const {
    tiers,
    level,
    onTierUpdate,
    onTierDelete,
    offerType,
    discountType,
    onFormValidityChange,
  } = props;

  const tiersAmount = useMemo(() => tiers?.length || 0, [tiers?.length]);

  const [currentTiersAmount, setCurrentTiersAmount] = useState<number>(0);
  const [currentTiersValidities, setCurrentTiersValidities] = useState<
    TierValidity[]
  >([]);
  const [tierAction, setTierAction] = useState<string>('Loaded');

  const prevTiersAmount = usePrevious(currentTiersAmount);
  const prevDiscountType = usePrevious(discountType);
  const prevOfferType = usePrevious(offerType);

  const isAllTiersValid = useMemo(
    () =>
      currentTiersValidities.every(
        (tierValidity: TierValidity) => tierValidity.isValid
      ),
    [currentTiersValidities]
  );

  const tierFieldsNeeded: FieldsProps = useMemo(() => {
    switch (offerType) {
      case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y:
        if (discountType === OfferTieredDiscountTypeDtoEnum.PERCENTAGE) {
          return {
            spendBoughtFieldName: 'requirementPreciseValue',
            offerDiscountFieldName: 'entitledWholeValue',
            leastExpensiveFieldName: '',
          };
        } else {
          return {
            spendBoughtFieldName: 'requirementPreciseValue',
            offerDiscountFieldName: 'entitledPreciseValue',
            leastExpensiveFieldName: '',
          };
        }
      case OfferTypeDtoEnum.TIERED_BOGO:
        if (discountType === OfferTieredDiscountTypeDtoEnum.PERCENTAGE) {
          return {
            spendBoughtFieldName: 'requirementWholeValue',
            offerDiscountFieldName: 'entitledWholeValue',
            leastExpensiveFieldName: 'affectedItems',
          };
        } else {
          return {
            spendBoughtFieldName: 'requirementWholeValue',
            offerDiscountFieldName: 'entitledPreciseValue',
            leastExpensiveFieldName: 'affectedItems',
          };
        }
      case OfferTypeDtoEnum.VOLUME_DISCOUNT:
        if (discountType === OfferTieredDiscountTypeDtoEnum.PERCENTAGE) {
          return {
            spendBoughtFieldName: 'requirementWholeValue',
            offerDiscountFieldName: 'entitledWholeValue',
            leastExpensiveFieldName: '',
          };
        } else {
          return {
            spendBoughtFieldName: 'requirementWholeValue',
            offerDiscountFieldName: 'entitledPreciseValue',
            leastExpensiveFieldName: '',
          };
        }

      default:
        return defaultFeildsNeeded;
    }
  }, [offerType, discountType, defaultFeildsNeeded]);

  const getDefaultPrefillTier = useCallback(
    (
      offerType: OfferTypeDtoEnum,
      prevTierValue: number,
      discountType?: OfferTieredDiscountTypeDto
    ) => {
      switch (offerType) {
        case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y:
          if (discountType === OfferTieredDiscountTypeDtoEnum.PERCENTAGE) {
            return {
              affectedItems: null,
              entitledPreciseValue: null,
              entitledWholeValue: 1,
              requirementPreciseValue:
                Math.round((prevTierValue + 1.01) * 100) / 100,
              requirementWholeValue: null,
            };
          } else {
            return {
              affectedItems: null,
              entitledPreciseValue: 0.01,
              entitledWholeValue: null,
              requirementPreciseValue:
                Math.round((prevTierValue + 1.01) * 100) / 100,
              requirementWholeValue: null,
            };
          }
        case OfferTypeDtoEnum.TIERED_BOGO:
          if (discountType === OfferTieredDiscountTypeDtoEnum.PERCENTAGE) {
            return {
              affectedItems: 1,
              entitledPreciseValue: null,
              entitledWholeValue: 1,
              requirementPreciseValue: null,
              requirementWholeValue: prevTierValue + 1,
            };
          } else {
            return {
              affectedItems: 1,
              entitledPreciseValue: 0.01,
              entitledWholeValue: null,
              requirementPreciseValue: null,
              requirementWholeValue: prevTierValue + 1,
            };
          }
        case OfferTypeDtoEnum.VOLUME_DISCOUNT:
          if (discountType === OfferTieredDiscountTypeDtoEnum.PERCENTAGE) {
            return {
              affectedItems: null,
              entitledPreciseValue: null,
              entitledWholeValue: 1,
              requirementPreciseValue: null,
              requirementWholeValue: prevTierValue + 1,
            };
          } else {
            return {
              affectedItems: null,
              entitledPreciseValue: 0.01,
              entitledWholeValue: null,
              requirementPreciseValue: null,
              requirementWholeValue: prevTierValue + 1,
            };
          }
        default:
          return defaultTier;
      }
    },
    [tiers, discountType]
  );

  const [i18n] = useI18n();

  const onAddTierClick = useCallback(() => {
    const prevTierValue =
      offerType === OfferTypeDtoEnum.TIERED_BOGO ||
      offerType === OfferTypeDtoEnum.VOLUME_DISCOUNT
        ? tiers?.[tiers.length - 1].requirementWholeValue
        : tiers?.[tiers.length - 1].requirementPreciseValue;

    if (tiers?.length) {
      onTierUpdate({
        tierIndex: tiers?.length,
        tier: getDefaultPrefillTier(
          offerType,
          prevTierValue as number,
          discountType
        ),
      });
    }
  }, [onTierUpdate, getDefaultPrefillTier, offerType, tiers, discountType]);

  const disableBtn = useMemo(
    () => !!(tiers?.length && tiers?.length > 0 && tiers?.length >= 5),
    [tiers?.length]
  );

  const updateValidity = useCallback(
    debounce((index: number, isValid: boolean) => {
      const tiersValiditiesCopy = currentTiersValidities?.length
        ? cloneDeep(currentTiersValidities)
        : cloneDeep(
            [...Array(currentTiersAmount)].map((e, idx) => {
              return {
                id: idx,
                isValid: true,
              };
            })
          );

      const currentSectionIndex = tiersValiditiesCopy?.findIndex(
        (el: TierValidity) => el.id === index
      );
      if (currentSectionIndex !== -1) {
        tiersValiditiesCopy[currentSectionIndex].isValid = isValid;
      } else {
        tiersValiditiesCopy.push({
          id: index,
          isValid: isValid,
        });
      }
      setCurrentTiersValidities(tiersValiditiesCopy);
    }, 200),
    [currentTiersAmount, currentTiersValidities, setCurrentTiersValidities]
  );

  const removeValidity = useCallback(
    (index: number) => {
      const tiersValiditiesCopy = currentTiersValidities?.length
        ? cloneDeep(currentTiersValidities)
        : cloneDeep(
            [...Array(currentTiersAmount)].map((e, idx) => {
              return {
                id: idx,
                isValid: true,
              };
            })
          );
      const newTiersValiditiesCopy = tiersValiditiesCopy
        ?.filter((tierValidity) => tierValidity?.id !== index)
        .map((tierValidity, idx) => {
          return {
            ...tierValidity,
            id: idx,
          };
        });
      newTiersValiditiesCopy &&
        setCurrentTiersValidities(newTiersValiditiesCopy);
    },
    [currentTiersValidities, currentTiersAmount]
  );

  useEffect(() => {
    if (
      tiersAmount &&
      (!currentTiersAmount || tiersAmount !== currentTiersAmount)
    ) {
      setCurrentTiersAmount(tiersAmount);
    }
  }, [tiersAmount, currentTiersAmount]);

  useEffect(() => {
    if (
      currentTiersAmount &&
      prevTiersAmount &&
      currentTiersAmount > prevTiersAmount
    ) {
      setTierAction('Add');
    } else if (
      currentTiersAmount &&
      prevTiersAmount &&
      currentTiersAmount < prevTiersAmount
    ) {
      setTierAction('Delete');
    }
  }, [currentTiersAmount, prevTiersAmount]);

  useEffect(() => {
    if (
      (prevDiscountType && discountType && discountType !== prevDiscountType) ||
      (prevOfferType && offerType && offerType !== prevOfferType)
    ) {
      setTierAction('Rebuild');
    }
  }, [prevDiscountType, discountType, offerType, prevOfferType]);

  useEffect(() => {
    onFormValidityChange(isAllTiersValid);
  }, [isAllTiersValid]);

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <Text as='h2' variant='headingSm'>
          {i18n.translate('ConfigGoalsAndDiscounts.Title')}
        </Text>
        <BlockStack>
          {tiers && tiers?.length >= 5 && (
            <Banner tone='warning'>
              {i18n.translate('ConfigGoalsAndDiscounts.WarningBanner')}
            </Banner>
          )}
          <Banner>
            {i18n.translate('ConfigGoalsAndDiscounts.InfoBanner')}
          </Banner>
        </BlockStack>
        {tiers?.map((tier, idx, array) => {
          const prevTierValue =
            idx > 0 &&
            tierFieldsNeeded.spendBoughtFieldName &&
            array[idx - 1][
              tierFieldsNeeded.spendBoughtFieldName as keyof typeof tier
            ];
          const nextTierValue =
            idx !== array.length - 1 &&
            tierFieldsNeeded.spendBoughtFieldName &&
            array[idx + 1][
              tierFieldsNeeded.spendBoughtFieldName as keyof typeof tier
            ];

          return (
            <React.Fragment key={idx}>
              <Tier
                tier={tier}
                tierIndex={idx}
                level={level}
                onTierUpdate={onTierUpdate}
                onTierDelete={onTierDelete}
                fields={tierFieldsNeeded}
                prevTierValue={prevTierValue || 0}
                nextTierValue={nextTierValue || 0}
                updateValidity={updateValidity}
                removeValidity={removeValidity}
                tierAction={tierAction}
                setTierAction={setTierAction}
                isLastTier={idx === array.length - 1}
                offerType={offerType}
              />
            </React.Fragment>
          );
        })}

        <InlineStack align='start'>
          <Button onClick={onAddTierClick} disabled={disableBtn}>
            {i18n.translate('ConfigGoalsAndDiscounts.AddTierButton')}
          </Button>
        </InlineStack>
      </BlockStack>
    </Card>
  );
};
