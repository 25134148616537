import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PromotionFiltersSettingsDto,
  PromotionOverviewDto,
} from 'core/api/adminPromotions/adminPromotionsApi';

export interface SettingsFormValidity {
  sectionName: keyof PromotionFiltersSettingsDto;
  isValid: boolean;
}

export type PromotionsSliceType = {
  settingsAnchor: string | null;
  overviewFilters: boolean;
  fetchPromotionOverview: boolean;
  promotionOverview: PromotionOverviewDto;
  formValidity: SettingsFormValidity[];
  markdownActionModalShow: boolean;
};

const initialState: PromotionsSliceType = {
  settingsAnchor: null,
  overviewFilters: false,
  fetchPromotionOverview: false,
  promotionOverview: {},
  formValidity: [],
  markdownActionModalShow: true,
};

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: initialState,
  reducers: {
    setPromotionSettingsValidityState: (
      state,
      action: PayloadAction<PromotionsSliceType['formValidity']>
    ) => {
      state.formValidity = action.payload;
    },
    setFetchPromotionOverview: (
      state,
      action: PayloadAction<PromotionsSliceType['fetchPromotionOverview']>
    ) => {
      state.fetchPromotionOverview = action.payload;
    },
    setPromotionOverview: (
      state,
      action: PayloadAction<PromotionsSliceType['promotionOverview']>
    ) => {
      state.promotionOverview = action.payload;
    },
    setMarkdownActionModalShow: (
      state,
      action: PayloadAction<PromotionsSliceType['markdownActionModalShow']>
    ) => {
      state.markdownActionModalShow = action.payload;
    },
    setSettingsAnchor: (
      state,
      action: PayloadAction<PromotionsSliceType['settingsAnchor']>
    ) => {
      state.settingsAnchor = action.payload;
    },
    setOverviewFilters: (
      state,
      action: PayloadAction<PromotionsSliceType['overviewFilters']>
    ) => {
      state.overviewFilters = action.payload;
    },
  },
});

export const {
  setFetchPromotionOverview,
  setPromotionOverview,
  setPromotionSettingsValidityState,
  setMarkdownActionModalShow,
  setSettingsAnchor,
  setOverviewFilters,
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
