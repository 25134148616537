import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  Icon,
  IconSource,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { ShopifyPlusIcon } from 'features/promotions/assets/icons';
import { CollectionIcon, OrderIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  handleOfferTemplate,
  handleOfferTemplateDto,
} from './utils/handleOfferTemplate';
import './OfferTemplate.scss';
import { useIsDebugOrLocal } from 'core/hooks';
import {
  SelectOfferModalTypeEnum,
  SelectOfferPage,
} from 'pages/SelectOfferPage';
import { OfferCombinationTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';

type OfferTemplate = {
  type: OfferTypeDtoEnum | undefined;
  discountType?: OfferCombinationTypeDto;
  toggleShowHowModal: () => void;
};

export const OfferTemplate: React.FC<OfferTemplate> = ({
  type,
  discountType,
  toggleShowHowModal,
}) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();

  const [offerInfo, setOfferInfo] = useState<handleOfferTemplateDto>({
    icon: CollectionIcon as IconSource,
  });
  const [isChangeOfferTemplateModalOpen, setIsChangeOfferTemplateModalOpen] =
    useState<boolean>(false);

  const discountIcon = useMemo(
    () =>
      type === OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT
        ? OrderIcon
        : CollectionIcon,
    [type]
  );

  const toggleChangeOfferTemplateModalOpen = useCallback(() => {
    setIsChangeOfferTemplateModalOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    setOfferInfo(handleOfferTemplate(type));
  }, [type]);

  return (
    <>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <InlineStack align='space-between'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('OfferTemplate')}
            </Text>
            <Button
              variant='plain'
              onClick={async () => {
                !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
                toggleChangeOfferTemplateModalOpen();
              }}
            >
              {i18n.translate('ChangeTemplate')}
            </Button>
          </InlineStack>
          <Card roundedAbove='sm' padding='400'>
            <div style={{ width: 'auto' }}>
              <InlineStack align='space-between' wrap={false}>
                <BlockStack gap='100'>
                  <InlineStack gap='100' align='start'>
                    <InlineStack gap='100'>
                      <Icon source={offerInfo.icon} />
                      <Text as='h2' variant='headingSm'>
                        {i18n.translate(`${type}_TITLE`)}
                      </Text>
                    </InlineStack>
                    {discountType && (
                      <div className='OfferTemplateBadge'>
                        <Icon source={discountIcon} />
                        {i18n.translate(discountType)}
                      </div>
                    )}
                    {offerInfo.isNew && (
                      <Badge tone='success'>{i18n.translate('New')}</Badge>
                    )}
                  </InlineStack>
                  <div
                    style={{ paddingLeft: '24px', display: 'flex', gap: '4px' }}
                  >
                    <Text as='span' tone='subdued'>
                      <span style={{ whiteSpace: 'pre' }}>
                        {i18n.translate(`${type}_SUBTITLE`, {
                          link: (
                            <Link onClick={toggleShowHowModal}>
                              {i18n.translate('WatchTutorial')}{' '}
                            </Link>
                          ),
                        })}
                      </span>
                    </Text>
                  </div>
                </BlockStack>
                {offerInfo.isShopifyPlus && (
                  <BlockStack align='end'>
                    <ShopifyPlusIcon />
                  </BlockStack>
                )}
              </InlineStack>
            </div>
          </Card>
        </BlockStack>
      </Card>
      {isChangeOfferTemplateModalOpen && (
        <SelectOfferPage
          isOpen={isChangeOfferTemplateModalOpen}
          onClose={toggleChangeOfferTemplateModalOpen}
          modalType={SelectOfferModalTypeEnum.CHANGE_TEMPLATE}
        />
      )}
    </>
  );
};
