import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { CheckboxComponent } from './components/CheckboxComponent/CheckboxComponent';
import { GiftsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { RemovingFreeGifts } from './components/RemovingFreeGifts/RemovingFreeGifts';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { useSearchParams } from 'react-router-dom';
import {
  FreeGiftNotificationLocationDtoEnum,
  GiftPriceHideOnPagesDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { useIsDebugOrLocal } from 'core/hooks';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
import { SettingsSavebarRef } from 'features/settings/Settings';

export const Gifts = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [searchParams, setSearchParams] = useSearchParams();

  const { giftsData, giftsDataIsFetching, giftsDataChange } =
    useConfigureSettings(SettingsFetchTypeEnum.GIFTS);

  const [giftsState, setGiftsState] = useState<GiftsSettingsDto>({});
  const [savedData, setSavedData] = useState<GiftsSettingsDto>({});

  const giftPriceOptions = useMemo(
    () =>
      Object.values(GiftPriceHideOnPagesDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const freeGiftNotificationOptions = useMemo(
    () =>
      Object.values(FreeGiftNotificationLocationDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const navigateToTranslations = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    searchParams.set('path', SettingsModalPathsEnums.Translations);
    setSearchParams(searchParams);
  }, [searchParams]);

  const handleGiftsLocaleState = useCallback(
    (section: string, value: string | boolean, field?: string) => {
      if (field) {
        setGiftsState(
          (
            prevState: Omit<GiftsSettingsDto, 'allowCustomersToRemoveGifts'>
          ) => ({
            ...prevState,
            [section]: {
              ...(prevState[section as keyof typeof prevState] || {}),
              [field]: value,
            },
          })
        );
      } else {
        setGiftsState((prevState) => ({
          ...prevState,
          [section]: value,
        }));
      }
    },
    [giftsState]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(giftsState);
    return giftsDataChange(giftsState, onSuccess);
  }, [giftsState]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setGiftsState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, giftsState)));
  }, [giftsState, savedData]);

  useEffect(() => {
    if (!isEmpty(giftsData)) {
      setGiftsState(giftsData);
      setSavedData(giftsData);
    }
  }, [giftsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='Style'>
      {!giftsDataIsFetching && giftsState ? (
        <Page title={i18n.translate(`Gifts`)}>
          <BlockStack gap='400'>
            <RemovingFreeGifts
              data={giftsState.allowCustomersToRemoveGifts}
              section='allowCustomersToRemoveGifts'
              handleGiftsLocaleState={handleGiftsLocaleState}
            />
            <CheckboxComponent
              data={giftsState.giftPrice}
              fieldCheckbox='hideGiftOriginalPrice'
              fieldOptions='hideOnPages'
              options={giftPriceOptions}
              section='giftPrice'
              handleGiftsLocaleState={handleGiftsLocaleState}
              navigateToTranslations={navigateToTranslations}
            />
            <CheckboxComponent
              data={giftsState.freeGiftNotification}
              fieldCheckbox='showFreeGiftNotification'
              fieldOptions='freeGiftNotificationLocation'
              options={freeGiftNotificationOptions}
              section='freeGiftNotification'
              handleGiftsLocaleState={handleGiftsLocaleState}
              navigateToTranslations={navigateToTranslations}
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
});
Gifts.displayName = 'Gifts';
