import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BlockStack,
  Button,
  Card,
  Icon,
  InlineStack,
  List,
  Page,
  Text,
} from '@shopify/polaris';
import { ProductIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import './Products.scss';
import { AppliesTo } from './components/AppliesTo/AppliesTo';
import { GrayBoxCollectionsExclusions } from './components/Exclusions/Exclusions';
import {
  OfferProductLimitDto,
  OfferPrerequisiteEntitledAppliesToDto,
  OfferProductsDto,
  OfferRuleAppliesToDto,
  OfferTargetTypeDto,
  OfferTypeDto,
  ShopifyObjectDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OptionDtoString,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTargetTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppDispatch, useIsDebugOrLocal, useLazyEffect } from 'core/hooks';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { ResourceSelectionProps } from 'core/components/SearchFieldWithGrayBox';
import { setCurrentConfigPage } from 'core/store/offersWizardSlice';
import { NameFilters } from 'core/components/GrayBox/components/NameFilters/NameFilters';
import { AdvancedSettings } from 'core/components/GrayBox/components/AdvancedSettings/AdvancedSettings';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';
import {
  boldMessageParts,
  generateMessage,
  generateRules,
  SectionTypeEnum,
} from '../CartRules/utils/utils';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';

type ProductsProps = {
  products?: OfferProductsDto;
  offerType?: OfferTypeDto;
  specialOfferType?: OfferTypeSpecialCases;
  onAppliesToUpdate(data: OfferPrerequisiteEntitledAppliesToDto): void;
  onExclusionsUpdate(data: boolean): void;
  onExcludedResourcesUpdate(data: ShopifyObjectDto[]): void;
  onLimitUpdate(data: OfferProductLimitDto): void;
  onFormValidityChange(formIsValid: boolean): void;
  onNameFiltersUpdate(
    filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
    field: keyof OptionDtoString,
    value: string | boolean | null
  ): void;
  onAdvancedSettingsUpdate(
    field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
    value: string
  ): void;
  savedData?: OfferProductsDto;
  setConfigureComponent: (data: boolean) => void;
  configureComponent: boolean;
};

export const Products: React.FC<ProductsProps> = (props) => {
  const {
    products,
    specialOfferType,
    onAppliesToUpdate,
    onExclusionsUpdate,
    onExcludedResourcesUpdate,
    onFormValidityChange,
    onNameFiltersUpdate,
    setConfigureComponent,
    onAdvancedSettingsUpdate,
    savedData,
    configureComponent,
  } = props;
  const [i18n] = useI18n();

  const dispatch = useAppDispatch();

  const isDebugOrLocal = useIsDebugOrLocal();

  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();

  const [selectedProductsApplication, setSelectedProductsApplication] =
    useState<OfferTargetTypeDto>(
      products?.appliesTo?.type || OfferTargetTypeDtoEnum.ALL
    );
  const [currentSelections, setCurrentSelections] = useState<string>('');
  const [collectionExclusions, setCollectionExclusions] = useState<string>('');
  const [isNameFilterValid, setIsNameFilterValid] = useState<boolean>(true);
  const [isAppliesToValid, setIsAppliesToValid] = useState<boolean>(true);
  const [isExclusionsValid, setIsExclusionsValid] = useState<boolean>(true);

  const bullets = useMemo(
    () => generateRules(products, collectionExclusions, i18n),
    [products, i18n, collectionExclusions, generateRules]
  );

  const resourceType = useMemo(() => {
    switch (products?.appliesTo?.type) {
      case OfferTargetTypeDtoEnum.COLLECTIONS:
        return ShopifyObjectTypeDtoEnum.COLLECTION;
      case OfferTargetTypeDtoEnum.PRODUCTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT;
      case OfferTargetTypeDtoEnum.VARIANTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT;
      default:
        return null;
    }
  }, [products?.appliesTo?.type]);

  const productCurrentList = useMemo(
    () =>
      products?.appliesTo?.type !== OfferTargetTypeDtoEnum.ALL
        ? ((products?.appliesTo?.[
            products?.appliesTo?.type?.toLowerCase() as keyof typeof products.appliesTo
          ] || []) as ShopifyObjectDto[])
        : null,
    [products?.appliesTo]
  );

  const appliesToList = useMemo(
    () =>
      selectedProductsApplication === savedData?.appliesTo?.type
        ? savedData?.appliesTo?.[
            selectedProductsApplication?.toLocaleLowerCase() as keyof typeof selectedProductsApplication
          ]
        : products?.appliesTo?.[
            selectedProductsApplication?.toLocaleLowerCase() as keyof typeof selectedProductsApplication
          ],
    [selectedProductsApplication, products?.appliesTo, savedData?.appliesTo]
  );

  useLazyEffect(() => {
    const data: OfferPrerequisiteEntitledAppliesToDto =
      selectedProductsApplication === savedData?.appliesTo?.type
        ? { ...savedData?.appliesTo }
        : {
            ...products?.appliesTo,
            type: selectedProductsApplication,
          };

    onAppliesToUpdate(data);
  }, [selectedProductsApplication]);

  const setSelectedResources = useCallback(
    (data: ResourceSelectionProps[]) => {
      const requestData: OfferRuleAppliesToDto = {
        ...products?.appliesTo,
        type: selectedProductsApplication,
        [selectedProductsApplication?.toLowerCase() as keyof typeof selectedProductsApplication]:
          formateList(data, selectedProductsApplication),
      };
      onAppliesToUpdate(requestData);
    },
    [
      selectedProductsApplication,
      onAppliesToUpdate,
      formateList,
      products?.appliesTo,
    ]
  );

  const setExcludedResources = useCallback(
    (data: ResourceSelectionProps[]) => {
      onExcludedResourcesUpdate(
        formateList(data, selectedProductsApplication) as ShopifyObjectDto[]
      );
    },
    [onExcludedResourcesUpdate, formateList, selectedProductsApplication]
  );

  useEffect(() => {
    onFormValidityChange(
      isAppliesToValid && isNameFilterValid && isExclusionsValid
    );
  }, [isAppliesToValid, isNameFilterValid, isExclusionsValid]);

  useEffect(() => {
    configureComponent && dispatch(setCurrentConfigPage('products'));
    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent]);

  useEffect(() => {
    if (!configureComponent && resourceType && productCurrentList?.length) {
      getLookupShopifyDetailedObjectsDetails({
        type: resourceType,
        objects: productCurrentList,
      }).then((res) => {
        setCurrentSelections(
          headerTitle(res as ResourceSelectionProps[], resourceType, i18n)
        );
      });
    }
  }, [resourceType, productCurrentList]);

  useEffect(() => {
    if (
      !configureComponent &&
      products?.exclusions?.collections?.enabled &&
      products?.exclusions?.collections.value?.length
    ) {
      getLookupShopifyDetailedObjectsDetails({
        type: ShopifyObjectTypeDtoEnum.COLLECTION,
        objects: products?.exclusions?.collections.value,
      }).then((res) => {
        setCollectionExclusions(
          headerTitle(
            res as ResourceSelectionProps[],
            ShopifyObjectTypeDtoEnum.COLLECTION,
            i18n
          )
        );
      });
    }
  }, [products?.exclusions?.collections]);

  return (
    <>
      {configureComponent ? (
        <Page
          backAction={{
            id: 'OfferProductsPageBackNav',
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              setConfigureComponent(false);
            },
          }}
          title={i18n.translate('Products.ConfigureProducts')}
        >
          <BlockStack gap='600'>
            <AppliesTo
              selectList={appliesToList || []}
              selectedProductsApplication={selectedProductsApplication}
              setSelectedProductsApplication={setSelectedProductsApplication}
              onResourceSelection={setSelectedResources}
              onFormValidityChange={(formIsInvalid) =>
                setIsAppliesToValid(!formIsInvalid)
              }
              bogoVariant={specialOfferType}
              bogoData={specialOfferType && products}
              onAppliesToUpdate={onAppliesToUpdate}
            />
            <NameFilters
              type={
                (selectedProductsApplication ||
                  OfferTargetTypeDtoEnum.ALL) as OfferTargetTypeDtoEnum
              }
              data={products?.nameFilters}
              handleUpdateNameFilters={onNameFiltersUpdate}
              setIsNameFilterValid={setIsNameFilterValid}
            />
            {selectedProductsApplication === OfferTargetTypeDtoEnum.ALL ||
              (selectedProductsApplication ===
                OfferTargetTypeDtoEnum.COLLECTIONS && (
                <GrayBoxCollectionsExclusions
                  selectList={
                    (products?.exclusions?.collections
                      ?.value as ShopifyObjectDto[]) || []
                  }
                  selectedProductsApplication={selectedProductsApplication}
                  enable={!!products?.exclusions?.collections?.enabled}
                  onCheckboxValueChange={onExclusionsUpdate}
                  onExcludedResourceSelection={setExcludedResources}
                  onFormValidityChange={(formIsInvalid) =>
                    setIsExclusionsValid(!formIsInvalid)
                  }
                />
              ))}
            <AdvancedSettings
              type={
                (selectedProductsApplication ||
                  OfferTargetTypeDtoEnum.ALL) as OfferTargetTypeDtoEnum
              }
              data={products?.advancedSettings}
              handleUpdateAdvancedSettings={onAdvancedSettingsUpdate}
            />
          </BlockStack>
        </Page>
      ) : (
        <div className='Products'>
          <Card roundedAbove='sm' padding='400'>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('Products.Title') || 'Products'}
                </Text>
                <Text as='span' tone='subdued'>
                  {i18n.translate('Products.Subtitle')}
                </Text>
              </BlockStack>
              <Card roundedAbove='sm' padding='400'>
                <InlineStack
                  align='space-between'
                  wrap={false}
                  blockAlign='center'
                >
                  {products?.appliesTo?.type ? (
                    <InlineStack wrap={false} gap='200' blockAlign='center'>
                      <div style={{ width: 20, height: 20 }}>
                        <Icon source={ProductIcon} tone='base' />
                      </div>
                      {boldMessageParts(
                        generateMessage(
                          SectionTypeEnum.PRODUCTS,
                          products,
                          0,
                          currentSelections,
                          i18n
                        )
                      )}
                    </InlineStack>
                  ) : (
                    <Text as='p' tone='subdued'>
                      {i18n.translate('Products.NoProductsConfigured')}
                    </Text>
                  )}
                  <BlockStack align='center'>
                    <Button
                      variant='plain'
                      id='OfferProductsConfigureButton'
                      onClick={async () => {
                        !isDebugOrLocal &&
                          (await shopify.saveBar.leaveConfirmation());
                        setConfigureComponent(true);
                      }}
                    >
                      {i18n.translate('Products.Configure')}
                    </Button>
                  </BlockStack>
                </InlineStack>
                {bullets.length ? (
                  <div className='bullet'>
                    <List>
                      {bullets.map((bullet, index) => (
                        <List.Item key={index}>{bullet}</List.Item>
                      ))}
                    </List>
                  </div>
                ) : null}
              </Card>
            </BlockStack>
          </Card>
        </div>
      )}
    </>
  );
};
