import React, { useCallback, useState } from 'react';
import {
  OfferTemplateListDto,
  OfferTypeDto,
  PlanLevelDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTemplateDto } from 'features/promotions/components/CreatePromotionModal/consts/OfferTemplatesFromTypeData';
import './OfferTemplate.scss';
import {
  Badge,
  Button,
  Link,
  BlockStack,
  InlineStack,
  Icon,
  Text,
  Box,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { LearnMoreModal } from 'features/promotions/components/SelectedOfferTypeSettings/components/LearnMoreModal/LearnMoreModal';
import { AlertCircleIcon, LockIcon } from '@shopify/polaris-icons';
import { ShopifyPlusIcon } from 'features/promotions/assets/icons';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from 'features/settings/components/GeneralSettings/Plan/components/ChangePlanModal/ChangePlanModal';
import { useIsDebugOrLocal } from 'core/hooks';

type OfferTemplateProps = {
  offerTemplate: OfferTemplateDto;
  fullTemplateListData: OfferTemplateListDto[];
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  onCreate?: (value: OfferTypeDto) => void;
};

const OfferTemplate: React.FC<OfferTemplateProps> = ({
  offerTemplate,
  fullTemplateListData,
  disabled,
  loading,
  selected,
  onCreate,
}) => {
  const [i18n] = useI18n();
  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<boolean>(false);
  const [changePlanModal, setChangePlanModal] = useState<boolean>(false);
  const isDebugOrLocal = useIsDebugOrLocal();

  const templateDataInfo = fullTemplateListData.find(
    (item) => item.type === offerTemplate.types[0]
  );

  const renderWarningBox = useCallback(
    (text: string) => (
      <InlineStack gap='100'>
        <div>
          <Icon tone='caution' source={AlertCircleIcon} />
        </div>
        <Text tone='caution' as='p'>
          {text}
        </Text>
      </InlineStack>
    ),
    []
  );

  const handleChangePlanModal = useCallback(
    () => setChangePlanModal((prev) => !prev),
    []
  );

  const toggleShowHowModal = useCallback(
    (event?: any) => {
      if (event) {
        event.stopPropagation();
      }
      setShowHowModal((prevState: boolean) => !prevState);
    },
    [setShowHowModal]
  );

  return (
    <>
      <div
        className='OfferTemplate'
        onMouseEnter={() => setActiveButton(true)}
        onMouseLeave={() => setActiveButton(false)}
        id={`${templateDataInfo?.type}_TemplateBox`}
      >
        <InlineStack gap='500' align='space-between' blockAlign='start'>
          <Box width='75%'>
            <InlineStack wrap={false} align='start' gap='200'>
              <div>
                <Icon
                  source={
                    templateDataInfo?.available ? offerTemplate.icon : LockIcon
                  }
                />
              </div>
              <BlockStack gap='400'>
                <BlockStack gap='100'>
                  <InlineStack gap='100'>
                    <Text fontWeight='semibold' as='h2'>
                      {i18n.translate(offerTemplate.title)}
                    </Text>
                    {templateDataInfo?.isNew && (
                      <Badge tone='success'>{i18n.translate(`New`)}</Badge>
                    )}
                    {templateDataInfo?.planSupportingType && (
                      <Badge tone='warning'>
                        {templateDataInfo?.planSupportingType.name}
                      </Badge>
                    )}
                    {selected && (
                      <Badge>{i18n.translate(`CurrentlySelected`)}</Badge>
                    )}
                  </InlineStack>
                  <Text as='p' tone='subdued'>
                    {i18n.translate(offerTemplate.subtitle)}{' '}
                    <span onClick={(e) => toggleShowHowModal(e)}>
                      <Link removeUnderline url=''>
                        {i18n.translate(`WatchTutorial`)}
                      </Link>
                    </span>
                  </Text>
                </BlockStack>
                {!templateDataInfo?.isSupportedByCurrentPlan &&
                  renderWarningBox(i18n.translate('PlanUnavailable'))}
                {!templateDataInfo?.available &&
                  renderWarningBox(i18n.translate('OfferUnavailable'))}
              </BlockStack>
            </InlineStack>
          </Box>

          <BlockStack gap='300' align='space-between' inlineAlign='end'>
            {!templateDataInfo?.isSupportedByCurrentPlan ? (
              <Button onClick={handleChangePlanModal} variant='primary'>
                {i18n.translate(`GetTheFeature`)}
              </Button>
            ) : (
              <Button
                disabled={disabled || !templateDataInfo?.available}
                loading={loading}
                variant={activeButton ? 'primary' : 'secondary'}
                onClick={() =>
                  onCreate?.(templateDataInfo?.type as OfferTypeDto)
                }
                id='SelectOfferTemplateButton'
              >
                {i18n.translate(`Select`)}
              </Button>
            )}
            {templateDataInfo?.isShopifyPlus && <ShopifyPlusIcon />}
          </BlockStack>
        </InlineStack>
      </div>
      {showHowModal && (
        <LearnMoreModal
          onModalClose={() => setShowHowModal(false)}
          url={templateDataInfo?.videoUrl}
          title={i18n.translate('OfferExplanation')}
        />
      )}

      {changePlanModal && (
        <ChangePlanModal
          targetPlanLevel={
            templateDataInfo?.planSupportingType?.level as PlanLevelDto
          }
          modalType={ChangePlanModalTypeEnum.GET_FEATURE}
          isOpen={changePlanModal}
          onClose={handleChangePlanModal}
          isMaxModal={!isDebugOrLocal}
        />
      )}
    </>
  );
};

export default OfferTemplate;
